import { useMemo } from "react";
import { useBulkProducts } from "../api/useBulkProducts";
import { useBulkProductsContext } from "../context/BulkProductsContext";

export const useSelectedProducts = () => {
  const { selectedProductIds } = useBulkProductsContext();
  const { data: bulkProducts } = useBulkProducts();

  const selectedProducts = useMemo(() => {
    return bulkProducts?.filter((bulkProduct) => selectedProductIds.includes(bulkProduct.productId)) || [];
  }, [bulkProducts, selectedProductIds]);

  return { selectedProducts };
};
