import { Switch } from "components/Switch";
import { useState } from "react";
import { LocationDto } from "types/contracts/contracts";
import { useDisallowProductCreate } from "../api/useDisallowCreateProduct";
import SettingsCard from "./SettingsCard";

type Props = { location: LocationDto };

const DisallowCreateProduct = (props: Props) => {
  const { location } = props;
  const [checked, setChecked] = useState(location.disallowCreateProduct);
  const disallowProductCreate = useDisallowProductCreate();

  const handleToggle = () => {
    disallowProductCreate(!checked);
    setChecked((prevState) => !prevState);
  };

  return (
    <SettingsCard title="Disallow product creation for location (available for admin only)">
      <div className="flex flex-col gap-2">
        <p>Should the location be unable to create their own products?</p>
        <p>
          When toggled, the location cannot create their own products. They can create their own menus, sections, use micronetworked sections and
          build their menu from centrally managed products shared with the location.
        </p>
        <Switch checked={checked} onChange={handleToggle} aria-label={"Disallow product creation for location"} />
      </div>
    </SettingsCard>
  );
};

export default DisallowCreateProduct;
