import AddFromMicroNetworkButton from "features/micronetworks/components/AddFromMicronetwork/AddFromMicronetworkButton";
import { CardCompact, CardFooter, CardHeader, CardBodyCompact } from "components/Card";
import NewSectionButton from "./NewSectionButton";
import SectionListItems from "./SectionListItems";
import { useSections } from "features/sections/api/useSections";
import AddExistingSection from "../AddExistingSection/AddExistingSection";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

const SectionList = () => {
  const { data: sections } = useSections();
  const { selectedMenu } = useSelectedMenu();
  const { selectedLocation, canEditMenu } = useSelectedLocation();
  const { isPhone } = useScreenSize();

  if (!selectedLocation) {
    return <></>;
  }

  const classNames = isPhone ? "w-full" : "w-1/2";

  return (
    <CardCompact className={classNames}>
      <CardHeader className="flex justify-between items-center px-4">
        <h4>Sections</h4>
      </CardHeader>

      <CardBodyCompact>{sections && sections.length > 0 && <SectionListItems sections={sections} />}</CardBodyCompact>
      {selectedMenu && canEditMenu && (
        <CardFooter className="flex flex-col min-[900px]:flex-row justify-between gap-2">
          <AddFromMicroNetworkButton />
          <AddExistingSection />
          <NewSectionButton />
        </CardFooter>
      )}
    </CardCompact>
  );
};

export default SectionList;
