import { BulkProductDto } from "types/contracts/contracts";
import { useBulkProductsContext } from "../context/BulkProductsContext";

export const useSelectDisplayed = () => {
  const { setSelectedProductIds } = useBulkProductsContext();

  const selectDisplayed = (displayedProducts: BulkProductDto[]) => {
    const displayedProductIds = displayedProducts.map((product) => product.productId);

    setSelectedProductIds(displayedProductIds);
  };

  return { selectDisplayed };
};
