import { TrashIcon } from "@ordr-as/icons";
import { UseMultipleSelectionReturnValue } from "downshift";
import { AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { FieldErrors, UseFieldArrayRemove, UseFormRegister } from "react-hook-form";
import { ProductLibraryProductSizeDto } from "types/contracts/contracts";
import SizeName from "./SizeName";
import SizePrice from "./SizePrice";

type SelectedSizeProps = {
  size: ProductLibraryProductSizeDto;
  index: number;
  multipleSelect: UseMultipleSelectionReturnValue<ProductLibraryProductSizeDto>;
  register: UseFormRegister<AddProductLibraryProductFormType>;
  errors: FieldErrors<AddProductLibraryProductFormType>;
  remove: UseFieldArrayRemove;
};

const SelectedSize = (props: SelectedSizeProps) => {
  const { size, index, multipleSelect, register, errors, remove } = props;
  const { getSelectedItemProps, removeSelectedItem } = multipleSelect;

  const selectedItemStyles = "flex flex-row items-center gap-4";

  return (
    <span className={selectedItemStyles} {...getSelectedItemProps({ selectedItem: size, index })}>
      <SizeName register={register} errors={errors} index={index} />
      <SizePrice register={register} errors={errors} index={index} />
      <TrashIcon
        className="cursor-pointer h-5 w-5 mt-6"
        onClick={(e) => {
          e.stopPropagation();
          remove(index);
          removeSelectedItem(size);
        }}
      />
    </span>
  );
};

export default SelectedSize;
