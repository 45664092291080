import { useState, useEffect, createContext, useContext, ReactNode } from "react";

const breakpoints = {
  TABLET: 600,
  DESKTOP: 850,
  WIDESCREEN: 1440,
};

export interface ScreenSize {
  isWidescreen: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isPhone: boolean;
}

const getScreenSize = ({ innerWidth }: Window): ScreenSize => {
  return {
    isWidescreen: innerWidth >= breakpoints.WIDESCREEN,
    isDesktop: innerWidth >= breakpoints.DESKTOP && innerWidth < breakpoints.WIDESCREEN,
    isTablet: innerWidth >= breakpoints.TABLET && innerWidth < breakpoints.DESKTOP,
    isPhone: innerWidth < breakpoints.TABLET,
  };
};

const ScreenSizeContext = createContext<ScreenSize | undefined>(undefined);

const ScreenSizeProvider = ({ children }: { children: ReactNode }) => {
  const [screenSize, setScreenSize] = useState(getScreenSize(window));

  useEffect(() => {
    const updateScreenSize = () => setScreenSize(getScreenSize(window));

    window.addEventListener("resize", updateScreenSize);
    window.addEventListener("orientationchange", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
      window.removeEventListener("orientationchange", updateScreenSize);
    };
  }, []);

  return <ScreenSizeContext.Provider value={screenSize}>{children}</ScreenSizeContext.Provider>;
};

const useScreenSize = (): ScreenSize => {
  const context = useContext(ScreenSizeContext);
  if (context === undefined) {
    throw new Error("useScreenSize must be used within a ScreenSizeProvider");
  }
  return context;
};

export { ScreenSizeProvider, useScreenSize };
