import EditPrinters from "./EditPrinters/EditPrinters";
import { useMemo, useState } from "react";
import { TextInput } from "@ordr-as/umami-react";
import SelectAll from "./SelectAll";
import EditTaxGroups from "./EditTaxGroups/EditTaxGroups";
import AddOptionGroups from "./AddOptionGroups/AddOptionGroups";
import RemoveOptionGroups from "./RemoveOptionGroups/RemoveOptionGroups";
import Destroy from "./Destroy/Destroy";
import { useFilterProducts } from "../hooks/useFilterProducts";
import EditCategories from "./EditCategories/EditCategories";
import { useBulkProducts } from "../api/useBulkProducts";
import ProductList from "./ProductList";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import CreateProduct from "./CreateProduct/CreateProduct";
import ShareProducts from "./ShareProducts/ShareProducts";
import EditColor from "./EditColor/EditColor";
import EditCourse from "./EditCourse/EditCourse";
import { useLookups } from "features/lookups/api/useLookups";

const BulkEdit = () => {
  const { data: bulkProducts } = useBulkProducts();
  const { canEditMenu } = useSelectedLocation();

  const { data: lookups } = useLookups();

  const [filterText, setFilterText] = useState("");

  const { filterProducts } = useFilterProducts(lookups?.eposDisplayColors || [], lookups?.productCategories || []);

  const filteredProducts = useMemo(() => {
    return filterProducts(bulkProducts || [], filterText);
  }, [filterProducts, bulkProducts, filterText]);

  return (
    <>
      <div className="flex justify-between items-center p-4 gap-1">
        <TextInput aria-label="Search for products" placeholder="Search for products..." value={filterText} onChange={setFilterText} />
        <CreateProduct />
      </div>
      <div className="flex bg-grey-lightest py-3 px-1 border-t border-b border-grey-medium overflow-x-auto sticky top-0">
        {canEditMenu && (
          <div className="flex flex-shrink-0">
            <SelectAll displayedProducts={filteredProducts} allProducts={bulkProducts || []} />
            <EditPrinters />
            <EditTaxGroups />
            <EditCategories />
            <EditColor />
            <EditCourse />
            <AddOptionGroups />
            <RemoveOptionGroups />
            <ShareProducts />
            <Destroy />
          </div>
        )}
      </div>
      <div className="flex justify-center py-6">
        <ProductList filteredProducts={filteredProducts} allProducts={bulkProducts || []} />
      </div>
    </>
  );
};

export default BulkEdit;
