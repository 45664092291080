import { useCreateCustomizationGroup } from "features/options/api/customizations/useCreateCustomizationGroup";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import CustomizationGroupDialogForm from "./CustomizationGroupDialogForm";

const CreateCustomizationGroup = () => {
  const createCustomizationGroupMutation = useCreateCustomizationGroup();

  const create = (formData: CustomizationGroupFormType, close: () => void) => {
    createCustomizationGroupMutation.mutate(formData, {
      onSuccess: () => {
        close();
      },
    });
  };

  return <CustomizationGroupDialogForm createOrUpdate={create} />;
};

export default CreateCustomizationGroup;
