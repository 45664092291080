import { Dispatch, SetStateAction } from "react";
import ProductListItems from "./ProductListItems";
import { CardBodyCompact, CardFooter } from "components/Card";
import AddExistingProducts from "features/products/components/AddExistingProduct/AddExistingProducts";
import Tabs from "features/sections/components/Section/Tabs";
import { useProducts } from "features/products/api/useProducts";
import Spinner from "components/Spinner";
import { Link } from "react-router-dom";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { Button } from "@ordr-as/umami-react";

type ProductListProps = {
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
};

const ProductList = (props: ProductListProps) => {
  const { tab, setTab } = props;
  const productsQuery = useProducts();
  const { selectedLocation, canEditMenu } = useSelectedLocation();

  if (!productsQuery.isSuccess || !selectedLocation) {
    return <Spinner />;
  }

  const classNames = `flex flex-col min-[390px]:flex-row gap-2`;

  const buttonSizeClassName = `max-[390px]:w-full`;
  return (
    <>
      <CardBodyCompact>
        <div className="p-4">
          <Tabs onTabPress={setTab} selectedTab={tab} />
        </div>
        {canEditMenu && (
          <CardFooter className={classNames}>
            {!selectedLocation.disallowCreateProduct && (
              <Link to={`products/new`}>
                <Button className={buttonSizeClassName} kind="secondary">
                  Create product
                </Button>
              </Link>
            )}
            <AddExistingProducts />
          </CardFooter>
        )}
        <ProductListItems products={productsQuery.data} />
      </CardBodyCompact>
    </>
  );
};

export default ProductList;
