import { BulkProductDto, EposDisplayColorDto } from "types/contracts/contracts";
import { useSelectedProducts } from "../../hooks/useSelectedProducts";
import { useLookups } from "features/lookups/api/useLookups";
import Spinner from "components/Spinner";

const DisplaySelectedProducts = () => {
  const { selectedProducts } = useSelectedProducts();
  const { data: lookups, isSuccess } = useLookups();

  if (!isSuccess) {
    return <Spinner />;
  }

  const getEposDisplayColor = (product: BulkProductDto) => {
    return lookups.eposDisplayColors?.find((color) => color.hexCode == product.eposDisplayColor);
  };

  return (
    <div>
      <div className="flex text-xs font-semibold py-2">
        <span className="w-1/2">Product</span>
        <span className="w-1/2">Colour</span>
      </div>

      {selectedProducts.map((product) => (
        <DisplaySelectedProductsElement key={product.productId} product={product} eposDisplayColor={getEposDisplayColor(product)} />
      ))}
    </div>
  );
};

export default DisplaySelectedProducts;

type Props = { product: BulkProductDto; eposDisplayColor: EposDisplayColorDto | undefined };

const DisplaySelectedProductsElement = (props: Props) => {
  const { product, eposDisplayColor } = props;

  return (
    <div className="flex text-sm border-t border-t-grey-lightest py-2">
      <div className="w-1/2">{product.name}</div>
      <div className="flex w-1/2 gap-1 items-center">
        <div className="h-4 w-4 rounded" style={{ backgroundColor: eposDisplayColor?.hexCode }}></div>
        {eposDisplayColor?.name}
        {eposDisplayColor?.requiresAdmin && <span>(Can only be changed by a Favrit employee)</span>}
      </div>
    </div>
  );
};
