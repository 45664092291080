import config from "../../../config";
import { useFetch } from "../../../api/useFetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";

export const useDeleteSectionFromMicroNetwork = () => {
  const { destroy } = useFetch();
  const { selectedMenu } = useSelectedMenu();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (sectionIdToRemove: string) => {
      const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/micronetwork-sections/${sectionIdToRemove}`;

      return destroy(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sections"]);
        queryClient.invalidateQueries(["availableMicronetworkSections"]);
        queryClient.invalidateQueries(["partnerLocationsForSection"]);
      },
    }
  );

  return mutation;
};
