import { UpdateProductSizeDto } from "types/contracts/contracts";
import { ProductSizeFormType } from "../formValidation";

export const mapToUpdateProductSizeDtos = (formSizes: ProductSizeFormType[]): UpdateProductSizeDto[] => {
  const sizes: UpdateProductSizeDto[] = formSizes.map((size) => ({
    ...size,
    name: size.name || "",
  }));

  return sizes;
};
