import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useSelectedSupplier } from "../hooks/useSelectedSupplier";
import { ProductLibraryProductDto } from "types/contracts/contracts";

export const useProductLibraryProducts = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const { selectedSupplier } = useSelectedSupplier();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/suppliers/${selectedSupplier?.supplierId}/products`;

  const productLibraryProductsQuery = useQuery<ProductLibraryProductDto[], Error>(
    ["productLibraryProducts", selectedLocation?.locationId, selectedSupplier?.supplierId],
    () => get(url),
    { enabled: !!selectedLocation?.locationId && !!selectedSupplier?.supplierId },
  );

  return productLibraryProductsQuery;
};
