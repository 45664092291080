import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { ProductLibrarySupplierDto } from "types/contracts/contracts";

export const useSuppliers = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/suppliers`;

  const suppliersQuery = useQuery<ProductLibrarySupplierDto[], Error>(["suppliers", selectedLocation?.locationId], () => get(url), {
    enabled: !!selectedLocation?.locationId,
  });

  return suppliersQuery;
};
