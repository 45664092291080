import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BulkUpdateSecondaryPrinterDto } from "types/contracts/contracts";

export const useBulkUpdateSecondaryPrinters = () => {
  const { post } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (vars: { secondaryPrinterId: number | undefined; productIds: string[] }) => {
      const { secondaryPrinterId, productIds } = vars;

      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/products/bulk-update-secondary-printer`;

      const updateDto: BulkUpdateSecondaryPrinterDto = {
        productIds: productIds,
        secondaryPrinterId: secondaryPrinterId,
      };

      return post(url, updateDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
