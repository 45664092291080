import { Item, Select } from "@ordr-as/umami-react/lib/form";
import Spinner from "components/Spinner";
import { useLocations } from "features/locations/api/useLocations";

type Props = {
  setSelectedSlaveLocationId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const SelectLocation = (props: Props) => {
  const { setSelectedSlaveLocationId } = props;

  const { data: locations } = useLocations();

  if (!locations) {
    return <Spinner />;
  }

  const handleOnChange = (key: React.Key) => {
    if (key === "-1") {
      setSelectedSlaveLocationId(undefined);
      return;
    }
    setSelectedSlaveLocationId(Number(key));
  };

  const locationsWithDefault = [{ locationId: -1, name: "No location" }, ...locations];

  return (
    <div className="flex max-w-[400px] w-full pb-2">
      <Select fullWidth label="Share" placeholder="Select..." onSelectionChange={handleOnChange}>
        {locationsWithDefault.map((location) => (
          <Item key={location.locationId}>{location.name}</Item>
        ))}
      </Select>
    </div>
  );
};

export default SelectLocation;
