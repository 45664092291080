import { Button, Dialog, OverlayProvider } from "@ordr-as/umami-react";
import { useLookups } from "features/lookups/api/useLookups";
import { useState } from "react";
import AvailableSections from "./AvailableSections";

const AddFromMicroNetworkButton = () => {
  const { data: lookups } = useLookups();
  const [isOpen, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  if (!lookups?.userIsAdmin) {
    return <></>;
  }

  return (
    <OverlayProvider>
      <Button kind="secondary" fullWidth onClick={() => setOpen(true)}>
        Add from network
      </Button>
      {isOpen && (
        <Dialog title="Add section from network" actionLabel="Add" onClose={() => setOpen(false)}>
          <AvailableSections closeDialog={close} />
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default AddFromMicroNetworkButton;
