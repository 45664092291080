import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { LocationDto } from "types/contracts/contracts";

export const useLocations = () => {
  const { get } = useFetch();

  const locationsUrl = `${config.menuBuilderUrl}/v1/locations`;

  const locationsQuery = useQuery<LocationDto[], Error>(["locations"], () => get(locationsUrl), {
    staleTime: 300000,
  });

  return locationsQuery;
};
