import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { LookupsDto } from "types/contracts/contracts";

export const useLookups = () => {
  const { get } = useFetch();

  const url = `${config.menuBuilderUrl}/v1/lookups`;

  const lookupsQuery = useQuery<LookupsDto, Error>(["lookups"], () => get(url), {
    staleTime: 300000,
  });

  return lookupsQuery;
};
