import { Card, CardBody, CardHeader } from "components/Card";
import React from "react";

type SettingsCardProps = { children: React.ReactNode; title?: string };

const SettingsCard = (props: SettingsCardProps) => {
  const { children, title } = props;

  return (
    <Card>
      {title && <CardHeader>{title}</CardHeader>}
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default SettingsCard;
