import { AdvancedImage, lazyload, responsive } from "@cloudinary/react";
import { useCallback, useState } from "react";
import { useFormContext, useController } from "react-hook-form";
import { cloudinary } from "utils/cloudinary";
import { ProductFormType } from "../../../../../formValidation";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";
import { CloudinaryImageRequest } from "./types";

type Props = {
  editable: boolean;
};

const ImageUploader = (props: Props) => {
  const { editable } = props;
  const { control, setValue } = useFormContext<ProductFormType>();
  const { field } = useController<ProductFormType>({ name: "productImageUrl", control });
  const [imageSource, setImageSource] = useState<string | undefined>(field.value as string);

  // Memoizing the function removes re-rendering of CloudinaryUploadWidget
  const memoizedSetImage = useCallback(
    (imageSrc: CloudinaryImageRequest) => {
      setImageSource(imageSrc.public_id);
      setValue("productImageUrl", imageSrc.public_id || "");
    },
    [setImageSource, setValue]
  );

  const handleRemoveImage = () => {
    setImageSource("");
    setValue("productImageUrl", "");
  };

  return (
    <div className="form-image-container-parent">
      <label className="umami-form-label">Picture</label>
      {imageSource && (
        <div className="form-image-container">
          <AdvancedImage cldImg={cloudinary.image(imageSource)} plugins={[lazyload(), responsive()]} />
        </div>
      )}
      {!editable && !imageSource && <>No picture selected</>}
      {editable && <CloudinaryUploadWidget setImage={memoizedSetImage} removeImage={handleRemoveImage} imageIsSet={!!imageSource} />}
    </div>
  );
};

export default ImageUploader;
