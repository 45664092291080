import { Button } from "@ordr-as/umami-react";
import Spinner from "components/Spinner";
import { useExistingProducts } from "features/products/api/useExistingProducts";
import { useState } from "react";
import ProductComboBox from "./ProductsComboBox";

const AddExistingProducts = () => {
  const [active, setActive] = useState(false);
  const { data: existingProducts, isSuccess } = useExistingProducts();

  if (!active) {
    return (
      <Button kind="secondary" onClick={() => setActive(true)}>
        Add existing product
      </Button>
    );
  }

  if (!isSuccess) {
    return <Spinner />;
  }

  return <ProductComboBox products={existingProducts} />;
};

export default AddExistingProducts;
