import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BulkUpdateCourseDto } from "types/contracts/contracts";

export const useBulkUpdateCourse = () => {
  const { post } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (vars: { courseId: string | undefined; productIds: string[] }) => {
      const { courseId, productIds } = vars;
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/products/bulk-update-course`;

      const updateDto: BulkUpdateCourseDto = {
        productIds: productIds,
        courseId: courseId,
      };

      return post(url, updateDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
