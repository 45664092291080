import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useRemoveSection = (deleteIfNotUsedAnywhere: boolean) => {
  const { selectedMenu } = useSelectedMenu();
  const { destroy } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (sectionId: string) => {
      const removeSectionUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${sectionId}/delete-if-not-used-anywhere/${deleteIfNotUsedAnywhere}`;
      return destroy(removeSectionUrl);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sections"]);
        queryClient.invalidateQueries(["existingSections"]);
      },
    }
  );

  return mutation;
};
