import Spinner from "components/Spinner";
import { useUpdateCustomizationSequence } from "features/options/api/customizations/useUpdateCustomizationSequence";
import { useSelectedOptionGroup } from "features/options/hooks/useSelectedOptionGroup";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import CustomizationListItem from "./CustomizationListItem";
import { CustomizationDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Props = {
  customizations: CustomizationDto[];
};

const CustomizationList = (props: Props) => {
  const { customizations } = props;

  const updateCustomizationSequenceMutation = useUpdateCustomizationSequence();
  const { selectedOptionGroup } = useSelectedOptionGroup();
  const { canEditMenu } = useSelectedLocation();

  if (!selectedOptionGroup) {
    return <Spinner />;
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    updateCustomizationSequenceMutation.mutate({
      customizationId: customizations[result.source.index].customizationId,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
  };

  if (!canEditMenu) {
    return (
      <ul>
        {customizations.map((customization) => (
          <div key={customization.customizationId} className="mb-2">
            <CustomizationListItem key={customization.customizationId} customization={customization} />
          </div>
        ))}
      </ul>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`option-group-product-droppable`}>
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {customizations.map((customization, index) => (
              <Draggable key={customization.customizationId} draggableId={customization.customizationId} index={index}>
                {(provided) => (
                  <div className="mb-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <CustomizationListItem key={customization.customizationId} customization={customization} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CustomizationList;
