import config from "../../../config";
import { useFetch } from "../../../api/useFetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { CreateMicronetworkSectionDto } from "types/contracts/contracts";

export const useAddSectionFromMicroNetwork = () => {
  const { post } = useFetch();
  const { selectedMenu } = useSelectedMenu();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (sectionIdToAdd: string) => {
      const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/micronetwork-sections`;

      const body: CreateMicronetworkSectionDto = {
        micronetworkSectionId: sectionIdToAdd,
      };

      return post(url, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sections"]);
        queryClient.invalidateQueries(["availableMicronetworkSections"]);
        queryClient.invalidateQueries(["partnerLocationsForSection"]);
      },
    },
  );

  return mutation;
};
