import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import ProductListItem from "./ProductListItem";
import { useUpdateProductSequence } from "features/products/api/useUpdateProductSequence";
import Spinner from "components/Spinner";
import { ProductOverviewDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Props = {
  products: ProductOverviewDto[];
};

const ProductListItems = (props: Props) => {
  const { products } = props;
  const { selectedSection } = useSelectedSection();
  const { canEditMenu } = useSelectedLocation();
  const mutation = useUpdateProductSequence();

  if (!selectedSection) {
    return <Spinner />;
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    mutation.mutate({
      productId: products[result.source.index].productId,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
  };

  if (!canEditMenu) {
    return (
      <>
        {products.map((product) => (
          <div key={product.productId}>
            <ProductListItem key={product.productId} product={product} />
          </div>
        ))}
      </>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`products-droppable-${selectedSection.sectionId}`}>
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {products.map((product, index) => (
              <Draggable key={product.productId} draggableId={product.productId} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <ProductListItem key={product.productId} product={product} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductListItems;
