import { Card, CardHeader, CardBody } from "../../../components/Card";
import Spinner from "../../../components/Spinner";
import { useTaxGroups } from "../api/useTaxGroups";
import TaxGroupsForm from "./TaxGroupForm";

const TaxGroups = () => {
  const { data: taxGroups, isSuccess } = useTaxGroups();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <Card>
      <CardHeader>
        <h4>Tax groups</h4>
      </CardHeader>
      <CardBody>
        <TaxGroupsForm taxGroups={taxGroups} />
      </CardBody>
    </Card>
  );
};

export default TaxGroups;
