import { useMultipleSelection } from "downshift";
import { AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { Control, FieldErrors, useFieldArray, UseFormRegister } from "react-hook-form";
import { ProductLibraryProductSizeDto } from "types/contracts/contracts";
import SelectedSize from "./SelectedSize";
import SelectSizes from "./SelectSizes";

type Props = {
  sizes: ProductLibraryProductSizeDto[];
  control: Control<AddProductLibraryProductFormType>;
  register: UseFormRegister<AddProductLibraryProductFormType>;
  errors: FieldErrors<AddProductLibraryProductFormType>;
};

const Sizes = (props: Props) => {
  const { sizes, control, register, errors } = props;

  const multipleSelect = useMultipleSelection<ProductLibraryProductSizeDto>();
  const { selectedItems } = multipleSelect;

  const { append, remove } = useFieldArray<AddProductLibraryProductFormType, "sizes">({
    name: "sizes",
    control: control,
  });

  return (
    <div>
      <SelectSizes sizes={sizes} multipleSelect={multipleSelect} append={append} />
      {selectedItems.length ? (
        <div className="flex flex-col mb-4 py-2">
          {selectedItems.map((selectedItem, index) => (
            <SelectedSize
              key={`selected-item-${index}`}
              size={selectedItem}
              index={index}
              multipleSelect={multipleSelect}
              register={register}
              errors={errors}
              remove={remove}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sizes;
