import { Item, TabMenu } from "@ordr-as/umami-react";
import { ConferenceRoomIcon, EditIcon, EntreeIcon, SearchIcon } from "@ordr-as/icons";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";

export enum TabOptions {
  Products = "products",
  Settings = "settings",
  Micronetwork = "micronetwork",
  SectionUsage = "sectionusage",
}

type TabsProps = {
  onTabPress: (value: string | number) => void;
  selectedTab: string | number;
};

const Tabs = (props: TabsProps) => {
  const { selectedTab, onTabPress } = props;
  const { selectedSection } = useSelectedSection();

  if (!selectedSection?.showMicronetworkTab) {
    return (
      <>
        <TabMenu onTabPress={onTabPress} selectedKeys={[selectedTab]} type="compact">
          <Item key={TabOptions.Products} textValue={TabOptions.Products}>
            <a href={`#${TabOptions.Products}`} className="flex items-center">
              <EntreeIcon className="mr-1" /> <span>Products</span>
            </a>
          </Item>
          <Item key={TabOptions.Settings} textValue={TabOptions.Settings}>
            <a href={`#${TabOptions.Settings}`} className="flex items-center">
              <EditIcon className="mr-1" /> <span>Settings</span>
            </a>
          </Item>
          <Item key={TabOptions.SectionUsage} textValue={TabOptions.SectionUsage}>
            <a href={`#${TabOptions.SectionUsage}`} className="flex items-center">
              <SearchIcon className="mr-1" /> <span>Section usage</span>
            </a>
          </Item>
        </TabMenu>
        <span>&nbsp;</span>
      </>
    );
  }

  return (
    <>
      <TabMenu onTabPress={onTabPress} selectedKeys={[selectedTab]} type="compact">
        <Item key={TabOptions.Products} textValue={TabOptions.Products}>
          <a href={`#${TabOptions.Products}`} className="flex items-center">
            <EntreeIcon className="mr-1" /> <span>Products</span>
          </a>
        </Item>
        <Item key={TabOptions.Settings} textValue={TabOptions.Settings}>
          <a href={`#${TabOptions.Settings}`} className="flex items-center">
            <EditIcon className="mr-1" /> <span>Settings</span>
          </a>
        </Item>
        <Item key={TabOptions.SectionUsage} textValue={TabOptions.SectionUsage}>
          <a href={`#${TabOptions.SectionUsage}`} className="flex items-center">
            <SearchIcon className="mr-1" /> <span>Section usage</span>
          </a>
        </Item>
        <Item key={TabOptions.Micronetwork} textValue={TabOptions.Micronetwork}>
          <a href={`#${TabOptions.Micronetwork}`} className="flex items-center">
            <ConferenceRoomIcon className="mr-1" /> <span>Micronetwork</span>
          </a>
        </Item>
      </TabMenu>
      <span>&nbsp;</span>
    </>
  );
};

export default Tabs;
