import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { ProductDetailsDto } from "types/contracts/contracts";

type NameProps = {
  product?: ProductDetailsDto;
};

const GiftCardInformation = (props: NameProps) => {
  const { product } = props;
  const adminUrl = useAdminUrl();

  if (!product?.isGiftCard) {
    return <></>;
  }

  return (
    <div className="bg-yellow-lightest p-4 rounded">
      <span>Gift cards have limited options for editing on this page. You can </span>
      <a href={adminUrl} className="underline">
        manage Gift cards in Admin.
      </a>
      <p>
        Sold gift cards expires after {product.giftCardExpirationYears} {product.giftCardExpirationYears === 1 ? " year" : "years"}.
      </p>
    </div>
  );
};

export default GiftCardInformation;

const useAdminUrl = () => {
  const { selectedLocation } = useSelectedLocation();

  if (!selectedLocation) {
    return config.favritAdminUrl;
  }

  return `${config.favritAdminUrl}#/location/${selectedLocation?.locationId}/gift-cards`;
};
