import { TextInput } from "@ordr-as/umami-react";
import { AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type SizeNameProps = {
  register: UseFormRegister<AddProductLibraryProductFormType>;
  errors: FieldErrors<AddProductLibraryProductFormType>;
  index: number;
};

const SizeName = (props: SizeNameProps) => {
  const { register, errors, index } = props;

  let err = undefined;

  if (errors.sizes) {
    err = errors.sizes[index]?.name;
  }

  return (
    <TextInput
      label={`Name of size`}
      name={`sizes.${index}.name`}
      register={register}
      invalidHelpText={err?.message}
      validationState={err ? "invalid" : undefined}
    />
  );
};

export default SizeName;
