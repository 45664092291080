import { Checkbox } from "@ordr-as/umami-react";
import { useProductLibrarySections } from "features/productLibrary/api/useProductLibrarySections";
import { AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { Control, FieldError, FieldErrors, useController } from "react-hook-form";

type SelectSectionsProps = {
  errors: FieldErrors<AddProductLibraryProductFormType>;
  control: Control<AddProductLibraryProductFormType>;
};

const SelectSections = (props: SelectSectionsProps) => {
  const { control, errors } = props;

  const { data: productLibrarySections, isSuccess } = useProductLibrarySections();

  const { field } = useController<AddProductLibraryProductFormType, "sectionIds">({
    name: "sectionIds",
    control,
  });

  if (!isSuccess) {
    return <></>;
  }

  const handleChange = (sectionId: string, isChecked: boolean) => {
    const sectionIdsWithoutCurrent = field.value.filter((id) => id !== sectionId);

    if (isChecked) {
      return [...sectionIdsWithoutCurrent, sectionId];
    }

    return sectionIdsWithoutCurrent;
  };

  const err = errors.sectionIds as (FieldError | undefined)[];

  return (
    <div className="py-2">
      <p className="bold">Sections to add product to:</p>
      <section>
        {productLibrarySections.map((section) => (
          <Checkbox key={section.sectionId} onChange={(isChecked: boolean) => field.onChange(handleChange(section.sectionId, isChecked))}>
            {section.name}
          </Checkbox>
        ))}
        {errors.sectionIds && (
          <p className="text-sm text-orange-darkest umami-text-input-helper">
            {err.map((error) => (
              <>{error}</>
            ))}
          </p>
        )}
      </section>
    </div>
  );
};

export default SelectSections;
