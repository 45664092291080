import { ArrowLeftIcon } from "@ordr-as/icons";
import { Button } from "@ordr-as/umami-react";
import { UseFormReturn } from "react-hook-form";
import { ProductFormType } from "../formValidation";

type ProductFormWrapperProps = {
  onSubmit: (formData: ProductFormType) => void;
  returnNavigation: () => void;
  methods: UseFormReturn<ProductFormType, object>;
  children: React.ReactNode;
};

const ProductFormWrapper = (props: ProductFormWrapperProps) => {
  const { onSubmit, returnNavigation, methods, children } = props;

  const productName = methods.watch("name");

  return (
    <div className="flex flex-col gap-4 overflow-x-scroll">
      <div className="flex flex-row justify-between items-center">
        <div className="flex gap-3 items-center">
          <Button onClick={returnNavigation} className="p-0">
            <ArrowLeftIcon />
          </Button>
          <h3>{productName}</h3>
        </div>
        <div className="flex gap-3">
          <Button type="submit" kind="secondary" onClick={returnNavigation} className="w-9">
            Cancel
          </Button>
          <Button type="submit" kind="primary" onClick={methods.handleSubmit(onSubmit)} className="w-9">
            Save
          </Button>
        </div>
      </div>
      {children}
      <div className="flex gap-3 justify-end">
        <Button type="submit" kind="secondary" onClick={returnNavigation} className="w-9">
          Cancel
        </Button>
        <Button type="submit" kind="primary" onClick={methods.handleSubmit(onSubmit)} className="w-9">
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProductFormWrapper;
