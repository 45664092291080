import { useFetch } from "api/useFetch";
import config from "config";
import { useQueryClient, useMutation } from "@tanstack/react-query";

export const useCreateDefaultTaxGroups = () => {
  const { post } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (locationId?: number) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${locationId}/tax-groups/defaults`;

      return post(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["taxGroups"]);
      },
    }
  );

  return mutation;
};
