import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateSectionDto } from "types/contracts/contracts";

export const useCreateSection = () => {
  const { selectedMenu } = useSelectedMenu();
  const { post } = useFetch();

  const createSectionUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections`;
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (section: CreateSectionDto) => {
      return post(createSectionUrl, section);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sections"]);
        queryClient.invalidateQueries(["existingSections"]);
      },
    },
  );

  return mutation;
};
