import AppHeader from "features/header/components/AppHeader";
import TaxGroup from "../features/taxGroups/components/TaxGroups";

const TaxGroupPage = () => {
  return (
    <>
      <AppHeader />
      <TaxGroup />
    </>
  );
};

export default TaxGroupPage;
