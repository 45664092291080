import { Cloudinary } from "@cloudinary/url-gen";
import config from "../config";

const cloud_name = "dkjgwja0c";

export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: cloud_name,
    apiKey: config.cloudinaryApiKey,
  },
});

export const cloudinaryConfig = {
  cloud_name,
};

export const cloudinaryWidgetConfig = {
  cloud_name,
  api_key: config.cloudinaryApiKey,
  sources: ["url", "local", "google_drive"],
  defaultSource: "local",
  uploadPreset: "ordr-upload",
  styles: {
    palette: {
      windowBorder: "#0D837D",
      link: "#0D837D",
    },
  },
};
