import Select from "components/Select";
import Spinner from "components/Spinner";
import { useTaxGroups } from "features/taxGroups/api/useTaxGroups";
import { useFormContext } from "react-hook-form";
import { ProductFormType } from "../../../formValidation";

type Props = {
  editable: boolean;
};

const TaxGroups = (props: Props) => {
  const { editable } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductFormType>();
  const { data: taxGroups, isSuccess } = useTaxGroups();

  if (!isSuccess) {
    return <Spinner />;
  }

  if (taxGroups.length < 1) {
    return <NoGroupsFound />;
  }

  return (
    <>
      <Select
        labelText="Tax groups"
        disabled={!editable}
        registerReturn={register("taxGroupId", {
          setValueAs: (value) => (value ? value : undefined),
        })}
        fieldError={errors.taxGroupId}
        widthClassName="w-1/2"
        infoIcon={true}
      >
        <option value={""}>No tax group selected</option>
        {taxGroups.map((taxGroup) => (
          <option key={taxGroup.taxGroupId} value={taxGroup.taxGroupId}>
            {taxGroup.name}
          </option>
        ))}
      </Select>
    </>
  );
};

export default TaxGroups;

const NoGroupsFound = () => {
  return (
    <>
      <label>
        <label>
          <p className="pb-1">Tax groups</p>
          <input disabled className="border border-grey-medium rounded py-3 px-3 w-1/2" value="No tax groups found." />
        </label>
      </label>
    </>
  );
};
