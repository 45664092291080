import { Button, Checkbox } from "@ordr-as/umami-react";
import { createColumnHelper } from "@tanstack/react-table";
import { useLookups } from "features/lookups/api/useLookups";
import { usePrinters } from "features/printers/api/usePrinters";
import { printerName } from "features/printers/utils/printerName";
import { Link } from "react-router-dom";
import { BulkProductDto, BulkProductOptionGroupDto, BulkProductSectionDto } from "types/contracts/contracts";
import { useProductIsSelected } from "../hooks/useProductIsSelected";
import { useToggleSelectedProductId } from "../hooks/useToggleSelectedProductId";
import { ProductListLabel } from "./ProductListLabel";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import Spinner from "components/Spinner";

export const useProductListColumns: any = () => {
  const { data: printers } = usePrinters();
  const { data: lookups } = useLookups();
  const categories = lookups?.productCategories;
  const { selectedLocation } = useSelectedLocation();

  if (!selectedLocation) {
    return <Spinner />;
  }

  const categoryName = (categoryId?: string) => {
    return categories?.find((category) => category.categoryId === categoryId)?.name;
  };

  const eposDisplayColorFromHex = (hexCode: string | undefined) => {
    return lookups?.eposDisplayColors?.find((color) => color.hexCode == hexCode);
  };

  const columnHelper = createColumnHelper<BulkProductDto>();
  const warnIfTaxGroupIsMissing = selectedLocation.requireTaxGroup;

  return [
    columnHelper.accessor("productId", {
      id: "selected",
      header: () => "Selected",
      cell: (info) => <SelectProduct productId={info.getValue()} />,
    }),
    columnHelper.accessor("productId", {
      id: "edit",
      header: () => "Edit",
      cell: (info) => <Edit productId={info.getValue()} />,
    }),
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("printerId", {
      header: () => "Printer",
      cell: (info) => <span>{printerName(info.getValue(), printers)}</span>,
    }),
    columnHelper.accessor("secondaryPrinterId", {
      header: () => "Secondary printer",
      cell: (info) => <span>{printerName(info.getValue(), printers)}</span>,
    }),
    columnHelper.accessor("taxGroupName", {
      header: () => "Tax group",
      cell: (info) => {
        if (warnIfTaxGroupIsMissing && !info.getValue()) {
          return <span style={{ color: "red" }}>Missing</span>;
        }
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("category", {
      header: () => "Category",
      cell: (info) => <span>{categoryName(info.getValue())}</span>,
    }),
    columnHelper.accessor("eposDisplayColor", {
      header: () => "ePOS colour",
      cell: (info) => (
        <div className="flex gap-1 items-center">
          <div className="h-4 w-4 rounded" style={{ backgroundColor: info.getValue() }}></div>
          <span>{eposDisplayColorFromHex(info.getValue())?.name}</span>
        </div>
      ),
    }),
    columnHelper.accessor("courseName", {
      header: () => "Course name",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("optionGroups", {
      header: () => "Option groups",
      cell: (info) => <OptionGroupsCell optionGroups={info.getValue()} />,
    }),
    columnHelper.accessor("sections", {
      header: () => "Sections",
      cell: (info) => <SectionsCell sections={info.getValue()} />,
    }),
    columnHelper.accessor("sizes", {
      id: "sizeName",
      header: () => "Size name",
      cell: (info) => (
        <span>
          {info.getValue().map((size) => (
            <p key={`${size.productSizeId}-name`}>{size.name}</p>
          ))}
        </span>
      ),
    }),
    columnHelper.accessor("sizes", {
      id: "price",
      header: () => "Prices",
      cell: (info) => (
        <span>
          {info.getValue().map((size) => (
            <p key={`${size.productSizeId}-price`}>{size.price}</p>
          ))}
        </span>
      ),
    }),
  ];
};

const SelectProduct = ({ productId }: { productId: string }) => {
  const isSelected = useProductIsSelected(productId);
  const { toggleSelectedProductId } = useToggleSelectedProductId();

  return <Checkbox aria-label="Selected for edit" isSelected={isSelected} onChange={() => toggleSelectedProductId(productId)} />;
};

const Edit = ({ productId }: { productId: string }) => {
  return (
    <Link to={`products/${productId}`}>
      <Button kind="secondary">Edit</Button>
    </Link>
  );
};

const OptionGroupsCell = ({ optionGroups }: { optionGroups: BulkProductOptionGroupDto[] }) => {
  const colorMap: Record<string, string> = {
    RECOMMENDATION: "bg-purple-lightest",
    CUSTOM: "bg-grey-lightest",
  };

  return (
    <div className="flex flex-col gap-2">
      {optionGroups.map((group) => (
        <ProductListLabel key={group.optionGroupId} name={group.name} color={colorMap[group.optionGroupType]} />
      ))}
    </div>
  );
};

const SectionsCell = ({ sections }: { sections: BulkProductSectionDto[] }) => {
  return (
    <div className="flex flex-col gap-2">
      {sections.map((section) => {
        return (
          <ProductListLabel
            key={section.sectionId + "-" + section.locationName}
            name={`${section.locationName}: ${section.sectionName}`}
            color={"bg-brand-accent"}
          />
        );
      })}
    </div>
  );
};
