import Spinner from "components/Spinner";
import AppHeader from "features/header/components/AppHeader";
import { useLastActiveMenuContext } from "features/locations/context/LastActiveMenuContext";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMenus } from "features/menus/api/useMenus";
import { Navigate } from "react-router-dom";

const LocationPage = () => {
  const { data: menus, isSuccess } = useMenus();
  const { selectedLocation } = useSelectedLocation();
  const { lastActiveMenuId } = useLastActiveMenuContext();

  if (!isSuccess) {
    return (
      <>
        <AppHeader />
        <Spinner />
      </>
    );
  }

  const selectedMenu = lastActiveMenuId || menus[0]?.menuId;

  return (
    <>
      <AppHeader />
      <Navigate to={`/locations/${selectedLocation?.locationId}/menus/${selectedMenu}`} replace />
    </>
  );
};

export default LocationPage;
