import { Button } from "@ordr-as/umami-react";
import { HandleIcon, TrashIcon } from "@ordr-as/icons";
import { useRemoveRecommendation } from "features/options/api/recommendations/useRemoveRecommendation";
import { useState } from "react";
import { Switch } from "../../../../components/Switch";
import { useToggleRecommendationActive } from "../../api/recommendations/useToggleRecommendationActive";
import { RecommendedProductSizeDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Props = { productSize: RecommendedProductSizeDto };

const RecommendedProductListItem = (props: Props) => {
  const { productSize } = props;
  const removeOptionGroupMutation = useRemoveRecommendation();
  const toggleReccomendationActiveMutation = useToggleRecommendationActive();
  const { canEditMenu, canToggleItems } = useSelectedLocation();
  const [recommendedActive, setRecommendedActive] = useState(productSize.active);

  const umamiStyles = "flex justify-between items-center bg-brand-white";
  const hoverStyle = "local-option-group";
  const listElementStyle = `${umamiStyles} ${hoverStyle}`;

  const handleToggle = () => {
    toggleReccomendationActiveMutation.mutate({
      recommendationId: productSize.optionGroupProductId,
      shouldBeActive: !recommendedActive,
    });

    setRecommendedActive(!recommendedActive);
  };

  return (
    <li className={listElementStyle}>
      <div className="flex items-center">
        {canEditMenu && <HandleIcon />}
        <Switch
          className="p-2"
          checked={recommendedActive}
          onChange={handleToggle}
          aria-label={"Toggle enabled"}
          stopPropagation={true}
          disabled={!canToggleItems}
        />

        <p className="px-2">{productSize.productName}</p>
      </div>
      <div className="flex px-2 items-center">
        {canEditMenu && (
          <Button onClick={() => removeOptionGroupMutation.mutate(productSize.productSizeId)}>
            <TrashIcon />
          </Button>
        )}
      </div>
    </li>
  );
};

export default RecommendedProductListItem;
