import Spinner from "components/Spinner";
import { useAvailableProductsForRecommendationGroup } from "features/options/api/recommendations/useAvailableProductsForRecommendationGroup";
import AddProductButton from "./AddProductButton";
import ProductComboBox from "./ProductsComboBox";

const AddProduct = () => {
  const { data: existingProducts, isSuccess } = useAvailableProductsForRecommendationGroup();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <AddProductButton>
      <ProductComboBox products={existingProducts} />
    </AddProductButton>
  );
};

export default AddProduct;
