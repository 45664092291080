import { Button, TextInput } from "@ordr-as/umami-react";
import { useState } from "react";
import { useUpdateCourse } from "./api/useUpdateCourse";
import { CourseDto } from "types/contracts/contracts";
import DeleteCourseButton from "./DeleteCourseButton";

type Props = {
  course: CourseDto;
};

const UpdateCourseButton = (props: Props) => {
  const { course } = props;
  const [focused, setFocused] = useState(false);
  const [input, setInput] = useState(course.name);
  const mutation = useUpdateCourse();

  const handleUpdateCourse = () => {
    const updateCourseDto = { courseId: course.courseId, updateCourseDto: { name: input } };
    mutation.mutate(updateCourseDto);
    setFocused(false);
  };

  return (
    <div className="flex">
      <TextInput aria-label="Edit course" value={input} onChange={setInput} onFocus={() => setFocused(true)} />
      {focused && (
        <div className="flex gap-2 ml-2">
          <Button kind="primary" onClick={() => handleUpdateCourse()}>
            Save
          </Button>
          <Button
            kind="secondary"
            onClick={() => {
              setFocused(false);
              setInput(course.name);
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      {!focused && course.usageCount === 0 && <DeleteCourseButton courseId={course.courseId} />}
    </div>
  );
};

export default UpdateCourseButton;
