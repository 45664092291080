import Printer from "./Printer";
import ProductCategory from "./ProductCategory";
import SecondaryPrinter from "./SecondaryPrinter";
import TaxGroups from "./TaxGroups";

type Props = {
  canEditBasicData: boolean;
  isGiftCard: boolean;
  canEditTaxGroupAndPrinter: boolean;
  displaySecondaryPrinter: boolean;
};

const Advanced = (props: Props) => {
  const { canEditBasicData, isGiftCard, canEditTaxGroupAndPrinter, displaySecondaryPrinter } = props;

  return (
    <div className="border border-grey-medium rounded p-4 flex flex-col gap-4">
      <h4>Advanced</h4>
      <ProductCategory editable={canEditBasicData && !isGiftCard} />
      <TaxGroups editable={canEditTaxGroupAndPrinter && !isGiftCard} />
      <Printer editable={canEditTaxGroupAndPrinter} />
      {displaySecondaryPrinter && <SecondaryPrinter editable={canEditTaxGroupAndPrinter} />}
    </div>
  );
};

export default Advanced;
