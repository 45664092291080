import config from "config";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { ProductOverviewDto } from "types/contracts/contracts";

export const useExistingProducts = () => {
  const { selectedSection } = useSelectedSection();
  const { get } = useFetch();

  const getExistingProductsUrl = `${config.menuBuilderUrl}/v1/menus/${selectedSection?.menuId}/sections/${selectedSection?.sectionId}/existing-products`;

  const existingProductsQuery = useQuery<ProductOverviewDto[]>(["existingProducts", selectedSection?.menuId, selectedSection?.sectionId], () =>
    get(getExistingProductsUrl),
  );

  return existingProductsQuery;
};
