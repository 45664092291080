import { Item, Select } from "@ordr-as/umami-react/lib/form";
import Spinner from "components/Spinner";
import { useTaxGroups } from "features/taxGroups/api/useTaxGroups";

type SelectTaxGroupProps = {
  setSelectedTaxGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const SelectTaxGroup = (props: SelectTaxGroupProps) => {
  const { setSelectedTaxGroupId } = props;
  const { data: taxGroups, isSuccess } = useTaxGroups();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <div className="flex max-w-[400px] w-full pb-2">
      <Select fullWidth label="Tax group" placeholder="Select..." onSelectionChange={(key) => setSelectedTaxGroupId(key as string)}>
        {taxGroups.map((taxGroup) => (
          <Item key={taxGroup.taxGroupId}>{taxGroup.name}</Item>
        ))}
      </Select>
    </div>
  );
};

export default SelectTaxGroup;
