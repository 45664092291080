import { useQuery } from "@tanstack/react-query";

export const useIosAppVersion = () => {
  const appVersionQuery = useQuery({
    queryKey: ["iosAppVersion"],
    queryFn: () => localStorage.getItem("ios_app_version"),
  });

  return appVersionQuery;
};
