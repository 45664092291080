import config from "config";
import { ErrorDto } from "types/contracts/contracts";

export const reactQueryOnError = (queryError: QueryError) => {
  const errorDto: ErrorDto = {
    httpStatus: queryError.status || 0,
    url: queryError.url,
    httpVerb: queryError.verb,
    message: queryError.error.message,
  };

  fetch(`${config.menuBuilderUrl}/v1/errors`, {
    method: "POST",
    body: JSON.stringify(errorDto),
    headers: {
      Authorization: `Bearer ${queryError?.token}`,
      "Content-Type": "application/json",
    },
  });
};

export type QueryError = {
  error: Error;
  token: string | null;
  status?: number;
  url: string;
  verb: "GET" | "PUT" | "POST" | "DELETE";
};
