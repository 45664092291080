import { ProductFormType } from "features/productForm/formValidation";
import { mapToUpdateProductSizeDtos } from "features/productForm/utils/mapToUpdateProductSizeDtos";
import { useCreateNewProductFromMenu } from "features/products/api/useCreateNewProductFromMenu";
import { CreateProductDto } from "types/contracts/contracts";
import ProductForm from "features/productForm/components/ProductForm";
import { useNavigateToSection } from "features/products/hooks/useNavigateToSection";

const CreateProductFromMenu = () => {
  const mutation = useCreateNewProductFromMenu();
  const navigateToSection = useNavigateToSection();

  const createProduct = (formData: ProductFormType) => {
    const productBeingCreated: CreateProductDto = {
      description: formData.description || "",
      productCategory: formData.productCategoryId,
      sizes: mapToUpdateProductSizeDtos(formData.sizes),
      eposDisplayColor: formData.productEposDisplayColor,
      productLibraryId: formData.productLibraryId,
      eposOnly: formData.eposOnly,
      addons: formData.addons,
      allergenIds: formData.allergenIds,
      centrallyManagedChildProductLocations: formData.centrallyManagedChildProductLocations,
      name: formData.name,
      removables: formData.removables,
      printerId: formData.printerId,
      secondaryPrinterId: formData.secondaryPrinterId,
      productImageUrl: formData.productImageUrl === "" ? undefined : formData.productImageUrl,
      taxGroupId: formData.taxGroupId,
      courseId: formData.courseId,
    };

    mutation.mutate(productBeingCreated, {
      onSettled: () => {
        navigateToSection();
      },
    });
  };

  return <ProductForm onSubmit={createProduct} returnNavigation={navigateToSection} />;
};

export default CreateProductFromMenu;
