import AppHeader from "features/header/components/AppHeader";
import ProductLibrarySearch from "features/productLibrary/components/ProductLibrarySearch";
import Suppliers from "features/productLibrary/components/Suppliers";

const ProductLibraryPage = () => {
  return (
    <>
      <AppHeader />
      <div className="flex m-3 justify-between">
        <Suppliers />
        <ProductLibrarySearch />
      </div>
    </>
  );
};

export default ProductLibraryPage;
