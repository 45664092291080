import Spinner from "components/Spinner";
import { useLookups } from "features/lookups/api/useLookups";
import MultiSelectAllergens from "./MultiSelectAllergens";

type Props = {
  editable: boolean;
};

const Allergens = (props: Props) => {
  const { editable } = props;
  const { data: lookups, isSuccess } = useLookups();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <>
      <label className="umami-form-label">Allergens</label>
      <MultiSelectAllergens allergens={lookups.allergens} disabled={!editable} />
    </>
  );
};

export default Allergens;
