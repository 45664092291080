import React from "react";

export const Card = (props: { children: React.ReactNode; className?: string; onClick?: React.MouseEventHandler<HTMLDivElement> }) => {
  return (
    <div className={`flex-row border bg-brand-white border-purple-lightest rounded-md m-4 ${props.className}`} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export const CardHeader = (props: { children: React.ReactNode; className?: string }) => {
  return <div className={`p-4 border-b border-purple-lightest ${props.className ?? ""}`}>{props.children}</div>;
};

export const CardBody = (props: { children: React.ReactNode; className?: string }) => {
  return <div className={`p-4 ${props.className ?? ""}`}>{props.children}</div>;
};

export const CardFooter = (props: { children: React.ReactNode; className?: string }) => {
  return <div className={`p-4 border-t border-purple-lightest ${props.className ?? ""}`}>{props.children}</div>;
};

export const CardCompact = (props: { children: React.ReactNode; className?: string; onClick?: React.MouseEventHandler<HTMLDivElement> }) => {
  return (
    <div className={`flex-row border bg-brand-white border-grey-lightest ${props.className}`} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export const CardBodyCompact = (props: { children: React.ReactNode; className?: string }) => {
  return <div className={`${props.className ?? ""}`}>{props.children}</div>;
};
