import { EposDisplayColorDto, SectionContainingProductDto } from "types/contracts/contracts";

type Props = {
  selectedProductColor: EposDisplayColorDto | undefined;
  selectedSection: SectionContainingProductDto | undefined;
  setColor: (color: EposDisplayColorDto | undefined) => void;
};

const ColorFromSectionRow = (props: Props) => {
  const { selectedProductColor, selectedSection, setColor } = props;
  const isSelected = !selectedProductColor && !selectedSection;

  return (
    <button
      type="button"
      className="flex items-center justify-between w-full h-[54px] px-4 hover:bg-purple-lightest"
      onClick={() => {
        setColor(undefined);
      }}
      data-id="select-epos-display-color-inherit-from-section"
    >
      <span className={`text-brand-black ${isSelected ? "font-semibold" : ""}`}>Use section colour</span>
      <span className={`text-grey-dark text-sm ${isSelected ? "font-semibold" : ""}`}>Defined by each section</span>
    </button>
  );
};

export default ColorFromSectionRow;
