import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { updateLocationDto } from "../utils/updateLocationDto";
import { useUpdateLocation } from "./useUpdateLocation";

export const useRequireTaxGroup = () => {
  const updateLocationMutation = useUpdateLocation();
  const { selectedLocation } = useSelectedLocation();

  const requireTaxGroup = (requireTaxGroup: boolean) => {
    if (!selectedLocation) {
      return;
    }

    const updateDto = updateLocationDto({ requireTaxGroup }, selectedLocation);

    updateLocationMutation.mutate(updateDto);
  };

  return requireTaxGroup;
};
