import ProductForm from "features/productForm/components/ProductForm";
import { ProductFormType } from "features/productForm/formValidation";
import { mapToUpdateProductSizeDtos } from "features/productForm/utils/mapToUpdateProductSizeDtos";
import { useUpdateProductFromMenu } from "features/products/api/useUpdateProductFromMenu";
import { useNavigateToSection } from "features/products/hooks/useNavigateToSection";
import { ProductDetailsDto, UpdateProductDto } from "types/contracts/contracts";

type Props = {
  product: ProductDetailsDto;
};

const EditProductFromMenu = (props: Props) => {
  const { product } = props;

  const mutation = useUpdateProductFromMenu();
  const navigateToSection = useNavigateToSection();

  const updateProduct = (formData: ProductFormType) => {
    const productToUpdate: UpdateProductDto = {
      sizes: mapToUpdateProductSizeDtos(formData.sizes),
      description: formData.description || "",
      productCategory: formData.productCategoryId,
      productLibraryId: formData.productLibraryId,
      eposDisplayColor: formData.productEposDisplayColor === "" ? undefined : formData.productEposDisplayColor,
      eposDisplayColorSectionId: formData.eposDisplayColorSectionId === "" ? undefined : formData.eposDisplayColorSectionId,
      allergenIds: formData.allergenIds,
      printerId: formData.printerId,
      secondaryPrinterId: formData.secondaryPrinterId,
      eposOnly: formData.eposOnly,
      name: formData.name,
      addons: formData.addons,
      centrallyManagedChildProductLocations: formData.centrallyManagedChildProductLocations,
      removables: formData.removables,
      productImageUrl: formData.productImageUrl === "" ? undefined : formData.productImageUrl,
      taxGroupId: formData.taxGroupId,
      courseId: formData.courseId,
      active: formData.active,
    };

    mutation.mutate(
      { productId: product.productId, product: productToUpdate },
      {
        onSettled: () => {
          navigateToSection();
        },
      },
    );
  };

  return <ProductForm product={product} onSubmit={updateProduct} returnNavigation={navigateToSection} />;
};

export default EditProductFromMenu;
