import { CardBody, CardCompact, CardFooter, CardHeader } from "components/Card";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { CustomizationGroupDto, RecommendationGroupDto } from "types/contracts/contracts";
import CreateCustomizationGroup from "../Customizations/CustomizationGroup/CreateAndUpdate/CreateCustomizationGroup";
import NewRecommendationGroupButton from "../Recommendations/NewRecommendationGroupButton";
import CustomizationGroupListItems from "./CustomizationGroupListItems";
import RecommendationGroupListItems from "./RecommendationGroupListItems";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

type Props = {
  recommendationGroups: RecommendationGroupDto[];
  customizationGroups: CustomizationGroupDto[];
};

const OptionGroupList = (props: Props) => {
  const { recommendationGroups, customizationGroups } = props;
  const { canEditMenu } = useSelectedLocation();
  const { isPhone } = useScreenSize();

  const cardCompactClassNames = isPhone ? "w-full" : "w-1/2";
  const cardFooterClassNames = `flex justify-between gap-2 ${isPhone && "flex-col"}`;

  return (
    <>
      <CardCompact className={cardCompactClassNames}>
        <CardHeader className="flex justify-between items-center px-4">
          <h4>Option groups</h4>
        </CardHeader>

        <CardBody>
          {recommendationGroups.length > 0 && (
            <>
              <p className="font-semibold">Recommendation groups</p>
              <RecommendationGroupListItems recommendationGroups={recommendationGroups} />
            </>
          )}

          {customizationGroups.length > 0 && (
            <>
              <p className="font-semibold">Customization groups</p>
              <CustomizationGroupListItems customizationGroups={customizationGroups} />
            </>
          )}
        </CardBody>
        {canEditMenu && (
          <CardFooter className={cardFooterClassNames}>
            <NewRecommendationGroupButton />
            <CreateCustomizationGroup />
          </CardFooter>
        )}
      </CardCompact>
    </>
  );
};

export default OptionGroupList;
