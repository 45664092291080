import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { PrinterDto } from "types/contracts/contracts";

export const usePrinters = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const getPrintersUrl = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/printers`;

  const printersQuery = useQuery<PrinterDto[], Error>(["printers", selectedLocation?.locationId], () => get(getPrintersUrl), {
    enabled: !!selectedLocation?.locationId,
  });

  return printersQuery;
};
