import { SectionContainingProductDto } from "types/contracts/contracts";

type Props = {
  sections: SectionContainingProductDto[];
};

const DisplayedOnSections = (props: Props) => {
  const { sections } = props;

  return (
    <>
      <div className=" flex-wrap items-center border rounded py-3 px-3 border-grey-medium">
        <h4>Availability</h4>
        <p className="py-2">
          {sections.length === 0 && <>This product is not available on any sections.</>}
          {sections.length > 0 && (
            <>
              This product is available in the following sections:
              {sections.map((section) => (
                <li key={section.sectionId}>
                  {section.sectionName} ({section.locationName})
                </li>
              ))}
            </>
          )}
        </p>
      </div>
    </>
  );
};

export default DisplayedOnSections;
