import { Button, Checkbox, OverlayProvider } from "@ordr-as/umami-react";
import { BulkProductDto } from "types/contracts/contracts";
import { useSelectDisplayed } from "../hooks/useSelectDisplayed";
import { useSelectedProducts } from "../hooks/useSelectedProducts";

type Props = { displayedProducts: BulkProductDto[]; allProducts: BulkProductDto[] };

const SelectAll = (props: Props) => {
  const { displayedProducts, allProducts } = props;
  const { selectDisplayed } = useSelectDisplayed();
  const { selectedProducts } = useSelectedProducts();

  const useIsSelected = () => {
    if (allProducts.length > displayedProducts.length) {
      const allFilteredProductsSelected = displayedProducts.every((product) => selectedProducts.includes(product));
      return allFilteredProductsSelected;
    }

    return selectedProducts.length === allProducts.length;
  };

  const isSelected = useIsSelected();

  const handleClick = () => {
    selectDisplayed(isSelected ? [] : displayedProducts);
  };

  return (
    <OverlayProvider className="px-1">
      <Button onClick={handleClick} className="h-[36px]">
        <Checkbox aria-label="Select all" isSelected={isSelected} />
        <div className="flex flex-col text-left px-1">
          <span>Select all</span>
          <span className="text-sm text-grey-dark">{selectedProducts.length} selected</span>
        </div>
      </Button>
    </OverlayProvider>
  );
};

export default SelectAll;
