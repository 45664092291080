import { EposDisplayColorDto, SectionContainingProductDto } from "types/contracts/contracts";
import Divider from "./Divider";
import SectionColorRows from "./SectionColorRows";
import ColorFromSectionRow from "./ColorFromSectionRow";
import ColorPickerGrid from "./ColorPickerGrid";

type Props = {
  eposDisplayColors: EposDisplayColorDto[];
  sectionsContainingProduct: SectionContainingProductDto[] | undefined;
  selectedSection: SectionContainingProductDto | undefined;
  selectedProductColor: EposDisplayColorDto | undefined;
  setColor: (selectedSection: SectionContainingProductDto | undefined, selectedProductColor: EposDisplayColorDto | undefined) => void;
};

const ColorSelectorMenu = (props: Props) => {
  const { eposDisplayColors, sectionsContainingProduct, selectedSection, selectedProductColor, setColor } = props;

  const handleSetSectionColor = (selectedSection: SectionContainingProductDto | undefined) => {
    setColor(selectedSection, undefined);
  };

  const handleSetProductColor = (selectedProductColor: EposDisplayColorDto | undefined) => {
    setColor(undefined, selectedProductColor);
  };

  return (
    <>
      <div className="absolute top-full w-full h-[310px] mt-2 bg-brand-white shadow-lg z-10 overflow-y-scroll">
        <div className="text-grey-dark text-sm font-semibold px-4 pt-4 pb-2">Section colour</div>
        <ColorFromSectionRow selectedProductColor={selectedProductColor} selectedSection={selectedSection} setColor={handleSetProductColor} />
        <SectionColorRows
          eposDisplayColors={eposDisplayColors}
          sectionsContainingProduct={sectionsContainingProduct}
          selectedSection={selectedSection}
          setSelectedSection={handleSetSectionColor}
        />
        <Divider />
        <div className="text-grey-dark text-sm font-semibold px-4 pt-4 pb-2">Product specific colour</div>
        <ColorPickerGrid eposDisplayColors={eposDisplayColors} selectedProductColor={selectedProductColor} setColor={handleSetProductColor} />
      </div>
    </>
  );
};

export default ColorSelectorMenu;
