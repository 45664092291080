import { useForm } from "react-hook-form";
import EnableOpeningHours from "./EnableOpeningHours";
import Name from "./Name";
import EposOnly from "./EposOnly";
import { SectionSettingsFormType } from "./formValidation";
import { Dispatch, SetStateAction } from "react";
import { CardBody } from "components/Card";
import Tabs from "../Tabs";
import DeleteSectionButton from "./DeleteSectionButton";
import HideFromMenuWhenClosed from "./HideWhenSectionIsClosed";
import OpeningHours from "./OpeningHours/OpeningHours";
import { MenuSectionDto } from "types/contracts/contracts";
import ColorSelector from "./ColorSelector/ColorSelector";
import IgnoreProducerOpeningHours from "./IgnoreProducerOpeningHours";

type Props = {
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
  section: MenuSectionDto;
};

const PartnerSectionSettingsForm = (props: Props) => {
  const { tab, setTab, section } = props;
  const { register, control } = useForm<SectionSettingsFormType>({
    defaultValues: { ...section },
  });

  return (
    <CardBody>
      <Tabs onTabPress={setTab} selectedTab={tab} />

      <form className="flex flex-col gap-4">
        <Name register={register} disabled={true} />
        <ColorSelector control={control} disabled={true} />
        <EposOnly control={control} disabled={true} />
        <HideFromMenuWhenClosed control={control} disabled={true} />
        <IgnoreProducerOpeningHours control={control} disabled={true} />
        <EnableOpeningHours control={control} disabled={true} />
        <OpeningHours menuId={section.menuId} openingHours={section.openingHours} register={register} control={control} disabled={true} />
        <DeleteSectionButton sectionId={section.sectionId} />
      </form>
    </CardBody>
  );
};

export default PartnerSectionSettingsForm;
