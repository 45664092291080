import { FormProvider, useForm } from "react-hook-form";
import { ProductDetailsDto } from "types/contracts/contracts";
import { productFormSchema, ProductFormType } from "features/productForm/formValidation";
import Form from "./Form";
import ProductFormWrapper from "./ProductFormWrapper";
import { zodResolver } from "@hookform/resolvers/zod";
import { mapToProductFormType } from "features/productForm/utils/mapToProductFormType";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useTaxGroups } from "../../taxGroups/api/useTaxGroups";

type Props = {
  onSubmit: (formData: ProductFormType) => void;
  product?: ProductDetailsDto;
  returnNavigation: () => void;
};

const ProductForm = (props: Props) => {
  const { onSubmit, product, returnNavigation } = props;
  const { data: taxGroups } = useTaxGroups();

  const { selectedLocation } = useSelectedLocation();

  const defaultProduct = product
    ? mapToProductFormType(product)
    : {
        active: true,
        sizes: [
          {
            active: true,
          },
        ],
      };

  const methods = useForm<ProductFormType>({
    resolver: zodResolver(productFormSchema(selectedLocation?.requireTaxGroup || false, taxGroups)),
    defaultValues: defaultProduct,
  });

  return (
    <FormProvider {...methods}>
      <ProductFormWrapper onSubmit={onSubmit} methods={methods} returnNavigation={returnNavigation}>
        <Form onSubmit={onSubmit} methods={methods} product={product} />
      </ProductFormWrapper>
    </FormProvider>
  );
};

export default ProductForm;
