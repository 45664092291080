import Spinner from "components/Spinner";
import { useSuppliers } from "../api/useSuppliers";
import Supplier from "./Supplier";

const Suppliers = () => {
  const { data: suppliers, isSuccess } = useSuppliers();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-wrap">
      {suppliers.map((supplier) => (
        <Supplier key={supplier.supplierId} supplier={supplier} />
      ))}
    </div>
  );
};

export default Suppliers;
