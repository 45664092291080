import { useFetch } from "api/useFetch";
import config from "config";
import { useQuery } from "@tanstack/react-query";

export const useProductActiveDetails = (locationId?: number, menuId?: string, sectionId?: string, productId?: string) => {
  const { get } = useFetch();

  const getProductActiveUrl = `${config.menuBuilderUrl}/v1/locations/${locationId}/menus/${menuId}/sections/${sectionId}/products/${productId}/active-details`;

  const result = useQuery<string[], Error>([], () => get(getProductActiveUrl), {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 0,
  });

  return result.data;
};
