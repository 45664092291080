import { OverlayProvider, Button, Dialog, Checkbox } from "@ordr-as/umami-react";
import { useSelectedPotentialPartners } from "features/micronetworks/hooks/useSelectedPotentialPartners";
import { useState } from "react";
import { MenuSectionDto } from "types/contracts/contracts";

type Props = {
  section: MenuSectionDto;
  selectedLocationIds: number[];
  handleShare: (useTakeawayVat: boolean, allowActiveInactiveOverrideOnSectionProducts: boolean) => void;
};

const ShareDialog = (props: Props) => {
  const { section, selectedLocationIds, handleShare } = props;
  const [isOpen, setOpen] = useState(false);
  const [useTakeawayVat, setUseTakeawayVat] = useState(false);
  const [useAllowActiveInactiveOverrideOnSectionProducts, setUseAllowActiveInactiveOverrideOnSectionProducts] = useState(false);

  const { selectedPotentialPartners } = useSelectedPotentialPartners(selectedLocationIds);

  const handleAction = () => {
    handleShare(useTakeawayVat, useAllowActiveInactiveOverrideOnSectionProducts);

    setOpen(false);
  };

  return (
    <OverlayProvider>
      <Button kind="primary" onClick={() => setOpen(true)} isDisabled={selectedLocationIds.length < 1}>
        Share with {selectedLocationIds.length} locations
      </Button>
      {isOpen && (
        <Dialog title={`Share ${section.name}`} actionLabel="Share" onAction={handleAction} onClose={() => setOpen(false)} className="w-2/4">
          <div>
            <p className="bold">You are about to share {section.name} with these locations:</p>
            <ul>
              {selectedPotentialPartners.map((partner) => (
                <li key={partner.locationId}>{partner.name}</li>
              ))}
            </ul>
            <label className="flex py-2">
              <Checkbox aria-label="Selected for edit" isSelected={useTakeawayVat} onChange={setUseTakeawayVat} />
              <p>Use takeaway VAT</p>
            </label>
            {
              <label className="flex py-2">
                <Checkbox
                  aria-label="Selected for edit"
                  isSelected={useAllowActiveInactiveOverrideOnSectionProducts}
                  onChange={setUseAllowActiveInactiveOverrideOnSectionProducts}
                />
                <p>Allow host location to toggle products on/off</p>
              </label>
            }
          </div>
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default ShareDialog;
