import { Switch } from "components/Switch";
import { useState } from "react";
import { LocationDto } from "types/contracts/contracts";
import { useUpdateDisplaySizesAsSeparateProductsInEpos } from "../api/useDisplaySizesAsSeparateProductsInEpos";
import SettingsCard from "./SettingsCard";

type DisplaySizesAsSeparateProductsInEposProps = { location: LocationDto };

const DisplaySizesAsSeparateProductsInEpos = (props: DisplaySizesAsSeparateProductsInEposProps) => {
  const { location } = props;
  const [checked, setChecked] = useState(location.displaySizesAsSeparateProductsInEpos);
  const updateDisplaySizesAsSeparateProductsInEpos = useUpdateDisplaySizesAsSeparateProductsInEpos();

  const handleToggle = () => {
    updateDisplaySizesAsSeparateProductsInEpos(!checked);
    setChecked((prevState) => !prevState);
  };

  return (
    <SettingsCard title="ePOS display mode (available for admin only)">
      <div className="flex flex-col gap-2">
        <p>Display sizes as separate products in ePOS?</p>
        <p>
          When toggled, each product size will have its own card in ePOS. When not toggled, ePOS will display all the the base product as one item,
          and sizes in a dialog box similar to customizations etc.
        </p>
        <Switch checked={checked} onChange={handleToggle} aria-label={"Toggle ePOS size mode"} />
      </div>
    </SettingsCard>
  );
};

export default DisplaySizesAsSeparateProductsInEpos;
