import { ProductDetailsDto } from "types/contracts/contracts";

type Props = {
  product?: ProductDetailsDto;
};

const ProductRelationships = (props: Props) => {
  const { product } = props;

  if (product == null) {
    return <></>;
  }

  if (product.isMicronetworkedProduct) {
    return (
      <>
        <span className="bg-yellow-lightest p-4">
          This product is micronetworked from {product.ownerLocationName}. It can only be edited from there.
        </span>
      </>
    );
  }

  if (!product.productLibraryProductName && !product.centrallyManagedChildProducts) {
    return <></>;
  }

  if (product.centrallyManagedParentProductLocationName) {
    return (
      <>
        <span className="bg-yellow-lightest p-4">
          This product is owned by {product.centrallyManagedParentProductLocationName}. Some fields can only be edited from there.
        </span>
      </>
    );
  }

  if (product.centrallyManagedChildProducts.length > 0) {
    return (
      <>
        <div className="bg-yellow-lightest p-4">
          <p>
            This product is the parent of other products. Changes to this product will also affect the connected products. The other products are on
            these locations:
          </p>
          <p className="pt-2">{product.centrallyManagedChildProducts.map((x) => x.locationName).join(", ")}</p>
        </div>
      </>
    );
  }

  return <></>;
};

export default ProductRelationships;
