import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { UseFormReturn } from "react-hook-form";
import DisplayText from "./DisplayText";
import MultipleChoiceElements from "./MultipleChoiceElements";
import Name from "./Name";
import Required from "./Required";
import SelectionType from "./SelectionType";

type FormElementsProps = {
  methods: UseFormReturn<CustomizationGroupFormType, object>;
};

const FormElements = (props: FormElementsProps) => {
  const { methods } = props;
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = methods;

  const selectionType = watch("selectionType");

  return (
    <div className="flex flex-col gap-2">
      <Name register={register} errors={errors} />
      <DisplayText register={register} errors={errors} />
      <Required control={control} />
      <SelectionType register={register} errors={errors} />
      {selectionType !== "SINGLE_CHOICE" && <MultipleChoiceElements methods={methods} />}
    </div>
  );
};

export default FormElements;
