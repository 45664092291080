import ColorSelectorButton from "./ColorSelectorButton/ColorSelectorButton";
import { useFormContext, useController } from "react-hook-form";
import { ProductFormType } from "features/productForm/formValidation";
import { useState } from "react";
import ColorSelectorMenu from "./ColorSelectorMenu/ColorSelectorMenu";
import { EposDisplayColorDto, SectionContainingProductDto } from "types/contracts/contracts";
import { useProduct } from "features/products/api/useProduct";
import { useParams } from "react-router-dom";

type Props = {
  editable: boolean;
  availableColors: EposDisplayColorDto[];
};

const ColorSelector = (props: Props) => {
  const { editable, availableColors } = props;
  const { control } = useFormContext<ProductFormType>();
  const [isOpen, setIsOpen] = useState(false);
  const { locationId, productId } = useParams<{ locationId: string; productId: string }>();
  const { data: selectedProduct } = useProduct(Number(locationId), productId);
  const { field: eposDisplayColorSectionId } = useController<ProductFormType>({ name: "eposDisplayColorSectionId", control });
  const { field: productEposDisplayColor } = useController<ProductFormType>({ name: "productEposDisplayColor", control });

  const selectedSection = selectedProduct?.sectionsContainingProduct.find((section) => section.sectionId === eposDisplayColorSectionId.value);

  const useSelectedProductColor = () => {
    return availableColors.find((item) => item.hexCode === productEposDisplayColor.value);
  };

  const productColor = useSelectedProductColor();

  const handleSetColor = (
    selectedSectionContainingProduct: SectionContainingProductDto | undefined,
    selectedProductColor: EposDisplayColorDto | undefined,
  ) => {
    eposDisplayColorSectionId.onChange(selectedSectionContainingProduct?.sectionId || "");
    productEposDisplayColor.onChange(selectedProductColor?.hexCode || "");
    setIsOpen(false);
  };

  return (
    <div className="max-w-md flex flex-col relative gap-4">
      <label className="umami-form-label">Colour (ePOS only)</label>
      <ColorSelectorButton
        editable={editable}
        eposDisplayColors={availableColors}
        selectedSection={selectedSection}
        selectedProductColor={productColor}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <ColorSelectorMenu
          eposDisplayColors={availableColors}
          sectionsContainingProduct={selectedProduct?.sectionsContainingProduct}
          selectedSection={selectedSection}
          selectedProductColor={productColor}
          setColor={handleSetColor}
        />
      )}
    </div>
  );
};

export default ColorSelector;
