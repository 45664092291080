import { Button } from "@ordr-as/umami-react";
import { useVersionContext } from "features/version/context/VersionContext";
import { InfoIcon } from "@ordr-as/icons";
import { useNavigate } from "react-router-dom";

const NavigationButtons = () => {
  const { outdated } = useVersionContext();
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };

  return (
    <div className="flex p-2 justify-between">
      {outdated ? (
        <Button kind="primary" onClick={refreshPage}>
          <InfoIcon />
          <div className="px-1" />
          {"You're on an outdated version. Click to update."}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NavigationButtons;
