import { Header } from "@ordr-as/umami-react";
import Hamburger from "features/header/components/Hamburger";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useProduct } from "features/products/api/useProduct";
import EditProductFromProductList from "features/products/components/Edit/EditProductFromProductList";
import { useParams } from "react-router-dom";

const EditProductFromProductListPage = () => {
  const { locationId, productId } = useParams<{ locationId: string; productId: string }>();
  const { data: selectedProduct } = useProduct(Number(locationId), productId);
  const { selectedLocation } = useSelectedLocation();

  return (
    <>
      <Header>
        <p>{selectedLocation?.name}</p>
        <Hamburger />
      </Header>
      <div className="flex justify-center p-4">{selectedProduct && <EditProductFromProductList product={selectedProduct} />}</div>
    </>
  );
};

export default EditProductFromProductListPage;
