import Select from "components/Select";
import Spinner from "components/Spinner";
import { usePrinters } from "features/printers/api/usePrinters";
import { ProductFormType } from "features/productForm/formValidation";
import { useFormContext } from "react-hook-form";

type Props = {
  editable: boolean;
};

const SecondaryPrinter = (props: Props) => {
  const { editable } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductFormType>();

  const { data: printers, isSuccess } = usePrinters();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <Select
      labelText="Additional printer"
      registerReturn={register("secondaryPrinterId", {
        setValueAs: (value) => (value ? Number(value) : undefined),
      })}
      fieldError={errors.secondaryPrinterId}
      disabled={!editable}
      widthClassName="w-1/2"
    >
      <option value={""}>None</option>
      {printers.map((printer) => (
        <option key={printer.printerId} value={printer.printerId}>
          {printer.name}
        </option>
      ))}
    </Select>
  );
};

export default SecondaryPrinter;
