import { ProductOverviewDto } from "types/contracts/contracts";
import ProductListItem from "./PartnerProductListItem";

type Props = {
  products: ProductOverviewDto[];
};

const ProductListItems = (props: Props) => {
  const { products } = props;

  return (
    <>
      {products.map((product) => (
        <div key={product.productId}>
          <ProductListItem key={product.productId} product={product} />
        </div>
      ))}
    </>
  );
};

export default ProductListItems;
