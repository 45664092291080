import { Item, Select } from "@ordr-as/umami-react/lib/form";
import Spinner from "components/Spinner";
import { useCourses } from "features/courses/api/useCourses";

type Props = {
  setSelectedCourseId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const SelectCourse = (props: Props) => {
  const { setSelectedCourseId } = props;
  const { data: courses, isSuccess } = useCourses();

  const handleOnChange = (key: React.Key) => {
    if (key === "none") {
      setSelectedCourseId(undefined);
      return;
    }
    setSelectedCourseId(key as string);
  };

  if (!isSuccess) {
    return <Spinner />;
  }

  const coursesWithDefault = [{ courseId: "none", name: "No course" }, ...courses];

  return (
    <div className="flex max-w-[400px] w-full pb-2">
      <Select fullWidth label="Course" placeholder="Select..." onSelectionChange={handleOnChange}>
        {coursesWithDefault.map((course) => (
          <Item key={course.courseId}>{course.name}</Item>
        ))}
      </Select>
    </div>
  );
};

export default SelectCourse;
