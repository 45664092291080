import { CustomizationGroupDto } from "types/contracts/contracts";
import UpdateCustomizationGroup from "./CreateAndUpdate/UpdateCustomizationGroup";

type Props = {
  customizationGroup: CustomizationGroupDto;
};

const CustomizationGroupSettings = (props: Props) => {
  const { customizationGroup } = props;

  return <UpdateCustomizationGroup customizationGroup={customizationGroup} />;
};

export default CustomizationGroupSettings;
