import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteCourseMutation = () => {
  const { selectedLocation } = useSelectedLocation();
  const { destroy } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (courseId: string) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/courses/${courseId}`;

      return destroy(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["courses"]);
        queryClient.invalidateQueries(["locations"]);
      },
    },
  );

  return mutation;
};
