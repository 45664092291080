import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useSelectedOptionGroup } from "../../hooks/useSelectedOptionGroup";
import { CreateCustomizationFormType } from "../../formValidation/createCustomization";
import { useQueryClient, useMutation } from "@tanstack/react-query";

export const useCreateCustomization = () => {
  const { selectedLocation } = useSelectedLocation();
  const { selectedOptionGroup } = useSelectedOptionGroup();
  const queryClient = useQueryClient();
  const { post } = useFetch();

  const mutation = useMutation(
    (formCustomization: CreateCustomizationFormType) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/customization-groups/${selectedOptionGroup?.optionGroupId}`;

      return post(url, formCustomization);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["optionGroups"]);
      },
    }
  );

  return mutation;
};
