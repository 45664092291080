import { useNavigate, useParams } from "react-router-dom";

export const useNavigateToSection = () => {
  const navigate = useNavigate();
  const { locationId, menuId, sectionId } = useParams<{
    locationId: string;
    menuId: string;
    sectionId: string;
  }>();

  const navigateToSection = () => {
    navigate(`/locations/${locationId}/menus/${menuId}/sections/${sectionId}`);
  };

  return navigateToSection;
};
