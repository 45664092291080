import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useSelectedOptionGroup } from "features/options/hooks/useSelectedOptionGroup";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useAddRecommendationFromExisting = () => {
  const { selectedLocation } = useSelectedLocation();
  const { selectedOptionGroup } = useSelectedOptionGroup();
  const queryClient = useQueryClient();
  const { post } = useFetch();

  const mutation = useMutation(
    (productSizeId: string) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/recommendation-groups/${selectedOptionGroup?.optionGroupId}/existing-products/${productSizeId}`;

      return post(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["optionGroups"]);
        queryClient.invalidateQueries(["availableProductForRecommendationGroup"]);
      },
    }
  );

  return mutation;
};
