import { Button, Radio, RadioGroup, TextInput } from "@ordr-as/umami-react";
import { TrashIcon } from "@ordr-as/icons";
import { UseFieldArrayRemove, useFormContext, useWatch } from "react-hook-form";
import { ProductFormType } from "../../../formValidation";
import ControlledSwitch from "components/ControlledSwitch";
import NumberInput from "components/NumberInput";
import { useEffect, useState } from "react";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { ProductDetailsDto } from "types/contracts/contracts";
import PriceType, { locationsEnabledForTakeawayPricing } from "./PriceType";
import { useTaxGroups } from "../../../../taxGroups/api/useTaxGroups";

type SizeProps = {
  index: number;
  canEditBasicData?: boolean;
  isGiftCard: boolean;
  canEditInventoryAndActiveToggleOnSizes?: boolean;
  isCentrallyManaged: boolean;
  remove: UseFieldArrayRemove;
  product?: ProductDetailsDto;
};

type PriceMode = "defaultPrice" | "customPrice";

const Size = (props: SizeProps) => {
  const { index, canEditBasicData, isGiftCard, canEditInventoryAndActiveToggleOnSizes, isCentrallyManaged, remove, product } = props;
  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<ProductFormType>();

  const selectedLocation = useSelectedLocation();

  const initialPriceMode = product?.sizes[index]?.priceOverride ? "customPrice" : "defaultPrice";

  const [priceMode, setPriceMode] = useState<PriceMode>(initialPriceMode);

  const hasAutomaticOut = useWatch({ control, name: `sizes.${index}.hasAutomaticOut` });

  const handlePriceModeChange = (priceMode: string) => {
    setPriceMode(priceMode as PriceMode);

    if (priceMode === "defaultPrice") {
      setValue(`sizes.${index}.priceOverride`, undefined);
    }
  };

  // Disable takeaway price input if the chosen tax group doesn't have a takeaway VAT specified
  const taxGroupId = watch("taxGroupId");
  const { data: taxGroups } = useTaxGroups();
  const [canSetTakeawayPrice, setCanSetTakeawayPrice] = useState(true);
  useEffect(() => {
    const selectedTaxGroup = taxGroups?.find((group) => group.taxGroupId === taxGroupId);
    if (selectedTaxGroup?.takeawayVatId !== null) {
      setCanSetTakeawayPrice(true);
    } else {
      setCanSetTakeawayPrice(false);
      // Set blank values for all takeaway prices
      setValue(`sizes.${index}.takeawayPrice`, undefined);
    }
  }, [setValue, taxGroupId, taxGroups, index]);

  return (
    <div className="flex flex-col gap-4 py-5">
      <div className="flex gap-4 flex-wrap">
        <div>
          <label className="umami-form-label mb-4">Active</label>
          <ControlledSwitch control={control} disabled={!canEditInventoryAndActiveToggleOnSizes || isGiftCard} name={`sizes.${index}.active`} />
        </div>
        <TextInput
          aria-label="Name of size"
          name={`sizes.${index}.name`}
          register={register}
          label="name"
          invalidHelpText={errors.sizes?.[index]?.name?.message}
          validationState={errors.sizes?.[index]?.name ? "invalid" : undefined}
          isDisabled={!canEditBasicData || isGiftCard}
        />
        {!isCentrallyManaged && (
          <div className="flex gap-4">
            <NumberInput
              widthClassName="w-3/4"
              labelText={"Eat in price"}
              registerReturn={register(`sizes.${index}.price`, { valueAsNumber: true })}
              fieldError={errors.sizes?.[index]?.price}
              disabled={!canEditBasicData || isGiftCard}
              min={0}
            />
            {selectedLocation.selectedLocation?.locationId &&
              locationsEnabledForTakeawayPricing.includes(selectedLocation.selectedLocation?.locationId) && (
                <NumberInput
                  widthClassName="w-3/4"
                  labelText={"Takeaway price"}
                  registerReturn={register(`sizes.${index}.takeawayPrice`, { valueAsNumber: true })}
                  fieldError={errors.sizes?.[index]?.takeawayPrice}
                  disabled={!canEditBasicData || isGiftCard || !canSetTakeawayPrice}
                  noDefault={true}
                  min={0}
                />
              )}
            <PriceType index={index} isDisabled={!canEditInventoryAndActiveToggleOnSizes || isGiftCard} />
          </div>
        )}

        <div>
          <label className="umami-form-label mb-4">Automatic sold out</label>
          <ControlledSwitch
            control={control}
            disabled={!canEditInventoryAndActiveToggleOnSizes || isGiftCard}
            name={`sizes.${index}.hasAutomaticOut`}
          />
        </div>

        <NumberInput
          widthClassName="w-3/4"
          labelText={"Quantity in stock"}
          registerReturn={register(`sizes.${index}.automaticOutQuantity`, { valueAsNumber: true })}
          fieldError={errors.sizes?.[index]?.automaticOutQuantity}
          disabled={!canEditInventoryAndActiveToggleOnSizes || !hasAutomaticOut || isGiftCard}
        />

        {canEditBasicData && !isGiftCard && (
          <div className="flex flex-col text-center">
            <label className="umami-form-label mb-4">Delete</label>
            <Button onClick={() => remove(index)}>
              <TrashIcon className="h-5 w-5 mb-4" />
            </Button>
          </div>
        )}
      </div>

      {isCentrallyManaged && (
        <>
          <label className="umami-form-label">Price</label>
          <RadioGroup value={priceMode} onChange={handlePriceModeChange}>
            <Radio value="defaultPrice" className="mb-4">
              Use price from {product?.centrallyManagedParentProductLocationName || product?.ownerLocationName}
              <b>
                &nbsp;
                {product?.sizes[index].price} {selectedLocation.selectedLocation?.currency}
              </b>
            </Radio>
            <Radio value="customPrice">Use custom price</Radio>
          </RadioGroup>

          {priceMode === "customPrice" && (
            <div className="flex gap-4">
              <NumberInput
                labelText={""}
                registerReturn={register(`sizes.${index}.priceOverride`)}
                fieldError={errors.sizes?.[index]?.priceOverride}
                disabled={!(isCentrallyManaged && canEditInventoryAndActiveToggleOnSizes)}
                min={0}
              />
            </div>
          )}
          <PriceType index={index} isDisabled={!canEditInventoryAndActiveToggleOnSizes || isGiftCard} />
        </>
      )}
    </div>
  );
};

export default Size;
