import { useTaxGroups } from "features/taxGroups/api/useTaxGroups";
import useLocalVats from "features/taxGroups/hooks/useLocalVats";
import { useMemo } from "react";
import { TaxGroupDto } from "types/contracts/contracts";

export const useGetTaxGroup = (taxGroupId: string | undefined) => {
  const { data: taxGroups, isSuccess } = useTaxGroups();

  if (!isSuccess) {
    return undefined;
  }

  return taxGroups.find((group) => group.taxGroupId === taxGroupId);
};

export const useVatDisplayTexts = (taxGroup: TaxGroupDto | undefined) => {
  const localVats = useLocalVats();

  const eatInVat = useMemo(() => localVats?.find((vat) => vat.vatId === taxGroup?.eatInVatId), [taxGroup, localVats]);

  const takeawayVat = useMemo(() => localVats?.find((vat) => vat.vatId === taxGroup?.takeawayVatId), [taxGroup, localVats]);

  return {
    eatInVatDisplayText: eatInVat?.displayText,
    takeawayVatDisplayText: takeawayVat?.displayText,
  };
};
