import { PrinterDto } from "types/contracts/contracts";

export const printerName = (printerId: number | undefined, printers: PrinterDto[] | undefined) => {
  if (!printerId || !printers) {
    return "";
  }

  const matchingPrinter = printers.find((printer) => printer.printerId === printerId)?.name;

  if (matchingPrinter) {
    return matchingPrinter;
  }

  return `${printerId} (unknown printer)`;
};
