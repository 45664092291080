import { useFetch } from "api/useFetch";
import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reorder, updateSequenceOnItems } from "utils/utils";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { MenuSectionDto } from "types/contracts/contracts";

export const useUpdateSectionSequence = () => {
  const { selectedMenu } = useSelectedMenu();
  const { put } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (variables: { sectionId: string; sourceIndex: number; destinationIndex: number }) => {
      const { sectionId, destinationIndex } = variables;

      const updateSectionSequenceUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${sectionId}/update-sequence/${destinationIndex}`;

      return put(updateSectionSequenceUrl);
    },
    {
      onMutate: async ({ sourceIndex, destinationIndex }) => {
        await queryClient.cancelQueries(["sections", selectedMenu?.menuId]);

        const previousSections = queryClient.getQueryData<MenuSectionDto[]>(["sections", selectedMenu?.menuId]);

        const reorderedSections = reorder(previousSections || [], sourceIndex, destinationIndex);
        const sectionsWithUpdatedSequence = updateSequenceOnItems(reorderedSections);

        queryClient.setQueryData<MenuSectionDto[]>(["sections", selectedMenu?.menuId], () => sectionsWithUpdatedSequence);

        return { previousSections };
      },
      onError: (err, variables, context) => {
        if (context?.previousSections) {
          queryClient.setQueryData<MenuSectionDto[]>(["sections", selectedMenu?.menuId], context.previousSections);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["sections"]);
      },
    },
  );

  return mutation;
};
