import { CreateProductDto, ProductLibraryProductDto } from "types/contracts/contracts";
import { AddProductLibraryProductFormType } from "./formValidation";

export const mapToCreateDto = (inputDto: ProductLibraryProductDto, formData: AddProductLibraryProductFormType): CreateProductDto => {
  const sizes =
    formData.sizes.length > 0
      ? formData.sizes.map((size) => ({
          active: true,
          name: size.name || "",
          allowOpenPrice: false,
          price: size.price,
          hasAutomaticOut: false,
        }))
      : [{ active: true, allowOpenPrice: false, price: 0, hasAutomaticOut: false, name: "" }];

  const createDto: CreateProductDto = {
    name: inputDto.name,
    description: inputDto.description,
    productCategory: inputDto.productCategory,
    productImageUrl: inputDto.mainImageSource === "" ? undefined : inputDto.mainImageSource,
    productLibraryId: inputDto.productLibraryId,
    eposOnly: false,
    allergenIds: inputDto.allergens,
    sizes: sizes,
    addons: [],
    removables: [],
    centrallyManagedChildProductLocations: [],
  };

  return createDto;
};
