import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateMenuSectionDto } from "types/contracts/contracts";
import { useSelectedSection } from "../hooks/useSelectedSection";

export const useUpdateSection = () => {
  const { selectedMenu } = useSelectedMenu();
  const { put } = useFetch();
  const { selectedSection } = useSelectedSection();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (updatedSection: UpdateMenuSectionDto) => {
      const updateSectionUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${selectedSection?.sectionId}`;

      return put(updateSectionUrl, updatedSection);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sections"]);
        queryClient.invalidateQueries(["products"]);
      },
    },
  );

  return mutation;
};
