import { Button } from "@ordr-as/umami-react";
import { TrashIcon } from "@ordr-as/icons";
import { useDeleteRecommendationGroup } from "features/options/api/recommendations/useDeleteRecommendationGroup";

type DeleteRecommendationGroupButtonProps = { optionGroupId: string };

const DeleteRecommendationGroupButton = (props: DeleteRecommendationGroupButtonProps) => {
  const { optionGroupId } = props;
  const deleteRecommendationGroupMutation = useDeleteRecommendationGroup();

  const handleRemove = () => {
    deleteRecommendationGroupMutation.mutate(optionGroupId);
  };

  return (
    <Button kind="secondary" onClick={handleRemove} className="text-orange-darkest border-orange-darkest">
      <TrashIcon />
      <div className="p-2" />
      Delete recommendation group
    </Button>
  );
};

export default DeleteRecommendationGroupButton;
