import { AddIcon } from "@ordr-as/icons";
import { Button } from "@ordr-as/umami-react";
import { Link } from "react-router-dom";

const CreateProduct = () => {
  return (
    <Link to={`products/new`}>
      <Button kind="secondary">
        <div className="flex items-center gap-2 flex-shrink-0">
          <AddIcon />
          <span>Create product</span>
        </div>
      </Button>
    </Link>
  );
};

export default CreateProduct;
