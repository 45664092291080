import { CardBody, CardFooter } from "components/Card";
import CustomizationList from "../CustomizationList";
import CreateCustomization from "../create/CreateCustomization";
import CreateCustomizationFromProduct from "../create/CreateCustomizationFromProduct";
import { CustomizationGroupDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

type Props = {
  customizationGroup: CustomizationGroupDto;
};

const CustomizationGroupOptions = (props: Props) => {
  const { customizationGroup } = props;
  const { canEditMenu } = useSelectedLocation();
  const { isPhone } = useScreenSize();

  return (
    <>
      <CardBody>
        <CustomizationList customizations={customizationGroup.customizations} />
      </CardBody>
      {canEditMenu && (
        <CardFooter className={`flex justify-between gap-2 ${isPhone && "flex-col"}`}>
          <CreateCustomization />
          <CreateCustomizationFromProduct />
        </CardFooter>
      )}
    </>
  );
};

export default CustomizationGroupOptions;
