import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { InfoIcon } from "@ordr-as/icons";
import { useId } from 'react';

type SelectProps = {
  labelText: string;
  registerReturn: UseFormRegisterReturn;
  disabled?: boolean;
  fieldError?: FieldError;
  children: React.ReactNode;
  widthClassName?: string;
  infoIcon?: boolean;
};

const Select = (props: SelectProps) => {
  const { labelText, registerReturn, disabled, fieldError, children, widthClassName, infoIcon } = props;
  const selectId = useId();

  return (
    <div className={`umami-select py-2`}>

        <div className="flex items-center">
          <label htmlFor={selectId}>
            <div className="pb-2 umami-form-label">{labelText}</div>
          </label>
          {infoIcon && <div className="pb-2 pl-2"><InfoIcon/></div>}
        </div>

        <select
          id={selectId}
          {...registerReturn}
          className={`text-sm border rounded py-[14px] px-[12px] ${widthClassName} ${fieldError ? "border-orange-darkest" : "border-grey-medium"}`}
          disabled={disabled}
        >
          {children}
        </select>

      {fieldError && <p className="text-sm text-orange-darkest">{fieldError.message}</p>}
    </div>
  );
};

export default Select;
