import Spinner from "components/Spinner";
import { useOptionGroups } from "../api/useOptionGroups";
import OptionGroup from "./OptionGroup/OptionGroup";
import OptionGroupList from "./OptionGroupList/OptionGroupList";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";
import { Link, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@ordr-as/icons";
import AppHeader from "features/header/components/AppHeader";

const Options = () => {
  const { data, isSuccess } = useOptionGroups();
  const { locationId, optionGroupId } = useParams<{ locationId: string; optionGroupId: string }>();
  const { isPhone } = useScreenSize();

  if (!isSuccess) {
    return <Spinner />;
  }

  const { recommendationGroups, customizationGroups } = data;

  if (isPhone) {
    const showOptionGroup = optionGroupId !== undefined;

    return (
      <>
        {showOptionGroup ? (
          <>
            <Link to={`/locations/${locationId}/options`} className="flex h-7 px-4 items-center gap-2 text-grey-dark">
              <ChevronLeftIcon />
              Back
            </Link>
            <OptionGroup />
          </>
        ) : (
          <div className="flex flex-col">
            <AppHeader />
            <OptionGroupList recommendationGroups={recommendationGroups} customizationGroups={customizationGroups} />
          </div>
        )}
      </>
    );
  }

  return (
    <div className="flex flex-col">
      <AppHeader />
      <div className="flex">
        <OptionGroupList recommendationGroups={recommendationGroups} customizationGroups={customizationGroups} />
        <OptionGroup />
      </div>
    </div>
  );
};

export default Options;
