import { OverlayProvider, Button, Dialog } from "@ordr-as/umami-react";
import { useBulkUpdateTaxGroups } from "features/bulkEditProducts/api/useBulkUpdateTaxGroup";
import { useSelectedProducts } from "features/bulkEditProducts/hooks/useSelectedProducts";
import { useState } from "react";
import DisplaySelectedProducts from "./DisplaySelectedProducts";
import SelectTaxGroup from "./SelectTaxGroup";

const EditTaxGroups = () => {
  const { selectedProducts } = useSelectedProducts();

  const [isOpen, setOpen] = useState(false);
  const [selectedTaxGroupId, setSelectedTaxGroupId] = useState<string | undefined>();
  const bulkUpdateTaxGroupsMutation = useBulkUpdateTaxGroups();

  const handleSave = () => {
    bulkUpdateTaxGroupsMutation.mutate(
      {
        taxGroupId: selectedTaxGroupId,
        productIds: selectedProducts.map((x) => x.productId),
      },
      {
        onSettled: () => {
          setSelectedTaxGroupId(undefined);
          setOpen(false);
        },
      },
    );
  };

  const handleOnClose = () => {
    setSelectedTaxGroupId(undefined);
    setOpen(false);
  };

  return (
    <OverlayProvider className="px-1">
      <Button kind="secondary" className="rounded-3xl h-[36px] text-sm" onClick={() => setOpen(true)} isDisabled={!selectedProducts.length}>
        Select tax group (VAT)
      </Button>
      {isOpen && (
        <Dialog
          title="Select tax group"
          actionLabel="Save"
          onAction={handleSave}
          isDisabledAction={selectedTaxGroupId === undefined}
          onClose={handleOnClose}
        >
          <SelectTaxGroup setSelectedTaxGroupId={setSelectedTaxGroupId} />
          <DisplaySelectedProducts />
          <div className="pb-8" />
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default EditTaxGroups;
