import { Switch } from "components/Switch";
import { useRemoveProductFromSection } from "features/products/api/useRemoveProductFromSection";
import { useState } from "react";
import { useToggleProductActive } from "features/products/api/useToggleProductActive";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { Link } from "react-router-dom";
import { PartnerLabel } from "features/sections/components/SectionList/SectionLabel";
import { ProductOverviewDto } from "types/contracts/contracts";
import Spinner from "components/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faBook, faBatteryEmpty, faBattery4, faPenToSquare, faHandDots, faTowerCell } from "@fortawesome/free-solid-svg-icons";
import { HandleIcon } from "@ordr-as/icons";

type Props = {
  product: ProductOverviewDto;
};

const ProductListItem = (props: Props) => {
  const { product } = props;
  const [toggleProduct, setToggleProduct] = useState(product);
  const { selectedLocation, canEditMenu, canToggleItems } = useSelectedLocation();
  const removeProductFromSectionMutation = useRemoveProductFromSection();
  const toggleProductActiveMutation = useToggleProductActive();

  if (!selectedLocation) {
    return <Spinner />;
  }

  const handleToggleEnable = () => {
    toggleProductActiveMutation.mutate({
      productId: product.productId,
      shouldBeActive: !toggleProduct.active,
    });

    if (toggleProduct.isOwner) {
      setToggleProduct((prevState) => ({
        ...prevState,
        active: !toggleProduct.active,
        activeOnOwnerLocation: !toggleProduct.active,
      }));

      return;
    }

    setToggleProduct((prevState) => ({
      ...prevState,
      active: !toggleProduct.active,
    }));
  };

  const umamiStyles = "flex justify-between items-center bg-brand-white";
  const borderStyle = "rounded-none border-b-[1px] border-b-grey-lightest";
  const disabledStyle = !toggleProduct.active ? "local-section-disabled" : "";
  const listElementStyle = `${umamiStyles} ${borderStyle} ${disabledStyle}`;

  const prices = product.sizes.filter((x) => x.active).map((x) => x.price);
  const minPrice = Math.min(...prices);
  const activeSizeCount = product.sizes.filter((x) => x.active).length;
  const openPriceCount = product.sizes.filter((x) => x.allowOpenPrice).length;
  const sizeCount = product.sizes.length;

  const editProductLink = `products/${product.productId}`;

  return (
    <li className={listElementStyle}>
      <Link
        to={editProductLink}
        className="flex flex-grow py-3 border-l-[12px] border-l-transparent"
        style={{ borderLeftColor: product.eposDisplayColor }}
      >
        <div className="flex items-center pr-2 pl-1">{canEditMenu && <HandleIcon />}</div>
        <div className="left flex flex-col px-2 w-full">
          <p>{product.name}</p>
          <p className="text-sm ">
            {activeSizeCount > 1 && (
              <>
                From&nbsp;
                <span>{minPrice}</span>&nbsp;
                <span>{selectedLocation?.currency}</span>
                {activeSizeCount !== sizeCount && (
                  <span>
                    .&nbsp;{activeSizeCount} of {sizeCount} sizes active.
                  </span>
                )}
                {openPriceCount > 0 && <> Open price allowed.</>}
              </>
            )}
            {activeSizeCount === 1 && sizeCount === 1 && (
              <>
                <span>{minPrice}</span>&nbsp;
                <span>{selectedLocation?.currency}</span>
                {openPriceCount > 0 && <> Open price allowed.</>}
              </>
            )}
            {activeSizeCount === 1 && sizeCount > 1 && (
              <>
                <span>{minPrice}</span>&nbsp;
                <span>{selectedLocation?.currency}</span>.&nbsp;
                <span>
                  {activeSizeCount} of {sizeCount} sizes active.
                </span>
                {openPriceCount > 0 && <> Open price allowed.</>}
              </>
            )}
            {activeSizeCount === 0 && (
              <>
                0 of {sizeCount} sizes active. {openPriceCount > 0 && <>Open price allowed.</>}
              </>
            )}
          </p>
          <p className="text-sm ">
            {selectedLocation.requireTaxGroup && !product.hasTaxGroup && <span style={{ color: "red" }}>Missing tax group</span>}
          </p>
        </div>
      </Link>

      <EposLabel isEposOnly={product.eposOnly} />
      {product.productIconSet?.hasAllergens && (
        <span title="This product contains allergens" className="p-1">
          <FontAwesomeIcon icon={faHandDots} style={{ color: "#888" }} />
        </span>
      )}
      {product.productIconSet?.hasCustomizationsOrRecommendations && (
        <span title="This product has customizations or recommendations" className="p-1">
          <FontAwesomeIcon icon={faPenToSquare} style={{ color: "#888" }} />
        </span>
      )}
      {product.productIconSet?.hasPicture && (
        <span title="This product has an image" className="p-1">
          <FontAwesomeIcon icon={faImage} style={{ color: "#888" }} />
        </span>
      )}
      {product.productIconSet?.isFromProductLibrary && (
        <span title="This product is from the Product Library" className="p-1">
          <FontAwesomeIcon icon={faBook} style={{ color: "#888" }} />
        </span>
      )}
      {product.productIconSet?.isParentInCentrallyManagedProduct && (
        <span title="This product is the master of other centrally managed products" className="p-1">
          <FontAwesomeIcon icon={faTowerCell} style={{ color: "#888" }} />
        </span>
      )}
      {product.productIconSet?.stockEnabledAndNotEmpty && (
        <span title="This product is using automatic out and has items in stock" className="p-1">
          <FontAwesomeIcon icon={faBattery4} style={{ color: "#888" }} />
        </span>
      )}
      {product.productIconSet?.stockEnabledAndEmpty && (
        <span title="This product is using automatic out and is out of stock" className="p-1">
          <FontAwesomeIcon icon={faBatteryEmpty} style={{ color: "#888" }} />
        </span>
      )}
      <PartnerLabel isPartner={product.isCentrallyManagedProduct} ownerLocationName={product.centrallyManagedParentProductLocationName} />
      <div className="right flex items-center p-2">
        <Switch
          className="pl-2 pr-3"
          checked={toggleProduct.active}
          onChange={handleToggleEnable}
          aria-label={"Toggle enabled"}
          secondaryActive={toggleProduct.activeOnOwnerLocation}
          stopPropagation={true}
          disabled={!canToggleItems}
        />

        {canEditMenu && (
          <ConfirmDeleteDialog deleteAction={() => removeProductFromSectionMutation.mutate(toggleProduct.productId)}>
            <div>
              <p>Do you want to remove {toggleProduct.name} from the section?</p>
              <div className="pl-2" />
              <p>To permanently delete a product you must also delete it from the master product list.</p>
            </div>
          </ConfirmDeleteDialog>
        )}
      </div>
    </li>
  );
};

export default ProductListItem;

type EposLabelProps = { isEposOnly: boolean };

export const EposLabel = (props: EposLabelProps) => {
  const { isEposOnly } = props;
  const color = "bg-grey-medium";

  return <SectionLabel display={isEposOnly} text="EPOS" classNames={color} />;
};

type SectionLabelProps = {
  display: boolean;
  text?: string;
  classNames: string;
};

const SectionLabel = (props: SectionLabelProps) => {
  const { display, text, classNames } = props;

  if (!display || !text) {
    return null;
  }

  const labelStyles = `text-xs font-semibold flex items-center rounded px-2 h-5 uppercase text-brand-black`;
  const styles = `${labelStyles} ${classNames}`;

  return <p className={styles}>{text}</p>;
};
