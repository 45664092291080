import { createContext, ReactNode, useContext, useMemo, useState } from "react";

type addVersionType = (version: string | null) => void;

type VersionContextType = {
  outdated: boolean;
  addApiVersion: addVersionType;
  addLatestClientVersion: addVersionType;
  clientVersion: string;
};

const VersionContext = createContext<VersionContextType | undefined>(undefined);

const clientVersion = `menu-builder-client-${import.meta.env.VITE_BUILD_TIME || "0"}`;

const VersionProvider = ({ children }: { children: ReactNode }) => {
  const [apiVersions, setApiVersions] = useState<string[]>([]);
  const [latestClientVersion, setLatestClientVersion] = useState<string>();

  const addApiVersion: addVersionType = (version) => {
    if (!version) {
      return;
    }

    setApiVersions((prevVersions) => {
      if (prevVersions.includes(version)) {
        return apiVersions;
      }
      return [...prevVersions, version];
    });
  };

  const addLatestClientVersion: addVersionType = (version) => {
    if (!version) {
      return;
    }

    setLatestClientVersion(version);
  };

  const outdated = useMemo(() => {
    if (apiVersions.length > 1) {
      return true;
    }

    if (latestClientVersion && latestClientVersion > clientVersion) {
      return true;
    }

    return false;
  }, [apiVersions, latestClientVersion]);

  return <VersionContext.Provider value={{ outdated, addApiVersion, addLatestClientVersion, clientVersion }}>{children}</VersionContext.Provider>;
};

export default VersionProvider;

export const useVersionContext = () => {
  const context = useContext(VersionContext);

  if (context === undefined) {
    throw Error("useVersionContext must be used within VersionProvider");
  }

  return context;
};
