import { Dispatch, SetStateAction, useCallback } from "react";

export const useToggleSelectedLocationId = (setState: Dispatch<SetStateAction<number[]>>) => {
  const toggleSelectedLocationId = useCallback(
    (locationIdToBeToggled: number) => {
      setState((prevState) => {
        if (!prevState.includes(locationIdToBeToggled)) {
          const locationIdAdded = [...prevState, locationIdToBeToggled];
          return locationIdAdded;
        }

        const locationIdRemoved = [...prevState.filter((location) => location !== locationIdToBeToggled)];
        return locationIdRemoved;
      });
    },
    [setState]
  );

  return { toggleSelectedLocationId };
};
