import { Button } from "@ordr-as/umami-react";
import { useState } from "react";
import { useProductActiveDetails } from "./useProductActiveDetails";

type Props = {
  locationId: number;
  menuId: string;
  sectionId: string;
  productId: string;
};

const DisplayActiveDetails = (props: Props) => {
  const { locationId, menuId, sectionId, productId } = props;
  const productActiveDetails = useProductActiveDetails(locationId, menuId, sectionId, productId);

  const [isClicked, setClicked] = useState(false);

  return (
    <>
      {!isClicked && (
        <Button kind="secondary" onClick={() => setClicked(true)}>
          Show active information
        </Button>
      )}
      {isClicked && <>{productActiveDetails?.map((x) => (x === "" ? <p key={x}>&nbsp;</p> : <p key={x}>{x}</p>))}</>}
    </>
  );
};

export default DisplayActiveDetails;
