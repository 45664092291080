import { useFetch } from "api/useFetch";
import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";

export const useAddSectionFromExisting = () => {
  const { selectedMenu } = useSelectedMenu();
  const { post } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (sectionId: string) => {
      const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/existing-sections/${sectionId}`;
      return post(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["existingSections"]);
        queryClient.invalidateQueries(["sections"]);
      },
    }
  );
  return mutation;
};
