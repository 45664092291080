import { TextInput } from "@ordr-as/umami-react";
import { ProductFormType } from "features/productForm/formValidation";
import { useFormContext } from "react-hook-form";

type Props = {
  editable: boolean;
};

const Description = (props: Props) => {
  const { editable } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductFormType>();

  return (
    <TextInput
      multiline
      label="Description"
      name="description"
      register={register}
      invalidHelpText={errors.description?.message}
      validationState={errors.description ? "invalid" : undefined}
      isDisabled={!editable}
      className="w-3/4"
    />
  );
};

export default Description;
