import NumberInput from "components/NumberInput";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type MinimumSelectionLimitProps = {
  register: UseFormRegister<CustomizationGroupFormType>;
  errors: FieldErrors<CustomizationGroupFormType>;
};

const MinimumSelectionLimit = (props: MinimumSelectionLimitProps) => {
  const { register, errors } = props;

  return (
    <NumberInput
      labelText="Minimum"
      registerReturn={register("minimumSelectionLimit", {
        setValueAs: (value: string) => Number(value) || undefined,
      })}
      fieldError={errors.minimumSelectionLimit}
    />
  );
};

export default MinimumSelectionLimit;
