import { useGetTaxGroup, useVatDisplayTexts } from "../../hooks/useTaxGroupVats";
import { useSelectedProducts } from "../../hooks/useSelectedProducts";
import { BulkProductDto } from "types/contracts/contracts";

const DisplaySelectedProducts = () => {
  const { selectedProducts } = useSelectedProducts();

  return (
    <div>
      <div className="flex text-xs font-semibold py-2">
        <span className="w-3/6">Product</span>
        <span className="w-1/6">Tax group</span>
        <span className="w-1/6">Eat-in VAT</span>
        <span className="w-1/6">Takeaway VAT</span>
      </div>

      {selectedProducts.map((product) => (
        <DisplaySelectedProductsElement key={product.productId} product={product} />
      ))}
    </div>
  );
};

export default DisplaySelectedProducts;

type Props = { product: BulkProductDto };

const DisplaySelectedProductsElement = (props: Props) => {
  const { product } = props;

  const taxGroup = useGetTaxGroup(product.taxGroupId);
  const { eatInVatDisplayText, takeawayVatDisplayText } = useVatDisplayTexts(taxGroup);

  return (
    <div className="flex text-sm border-t border-t-grey-lightest py-2">
      <div className="w-3/6">{product.name}</div>
      <div className="w-1/6">{product.taxGroupName}</div>
      <div className="w-1/6">{eatInVatDisplayText}</div>
      <div className="w-1/6">{takeawayVatDisplayText}</div>
    </div>
  );
};
