import AppHeader from "features/header/components/AppHeader";
import LocationList from "../features/locations/components/LocationList";

const AllLocationsPage = () => {
  document.title = "Favrit Menu";
  return (
    <div>
      <AppHeader />
      <div className="flex flex-row justify-center">
        <LocationList />
      </div>
    </div>
  );
};

export default AllLocationsPage;
