import { useState } from "react";
import SectionSettingsForm from "./SectionSettingsForm/SectionSettingsForm";
import { TabOptions } from "./Tabs";
import ShareSectionTab from "features/micronetworks/components/ShareSection/ShareSectionTab";
import ProductList from "features/products/components/ProductList/ProductList";
import { CardCompact, CardHeader } from "components/Card";
import Spinner from "components/Spinner";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import PartnerSection from "./PartnerSection";
import SectionUsageTab from "./SectionSettingsForm/SectionUsageTab";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { SectionOpeningHoursInfoDto } from "types/contracts/contracts";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

const Section = () => {
  const { selectedSection } = useSelectedSection();
  const { selectedMenu } = useSelectedMenu();
  const [tab, setTab] = useState<string | number>(TabOptions.Products);
  const { isPhone } = useScreenSize();

  if (!selectedSection || !selectedMenu) {
    return <Spinner />;
  }

  if (selectedSection.isMicronetwork) {
    return <PartnerSection section={selectedSection} tab={tab} setTab={setTab} />;
  }

  const getOpeningHoursMessage = (openingHoursInfo: SectionOpeningHoursInfoDto) => {
    if (!openingHoursInfo) {
      return <></>;
    }

    if (openingHoursInfo.locationIsOpen && openingHoursInfo.locationSectionIsOpen) {
      return <></>;
    }

    if (!openingHoursInfo.locationIsOpen) {
      return <p className="mt-4">Location is outside of opening hours. Section is closed.</p>;
    }

    return <p className="mt-4">Section is outside of opening hours. Section is closed.</p>;
  };

  const classNames = isPhone ? "w-full" : "w-1/2";

  return (
    <CardCompact className={classNames}>
      <CardHeader>
        <h4>{selectedSection.name}</h4>

        {selectedSection.availableOnMenus.length > 1 && (
          <p className="flex justify-between my-2">
            This section is available on {selectedSection.availableOnMenus.length} menus.
            {" Adding, removing, toggling or reordering products here will change the section everywhere it's used."}
          </p>
        )}

        <p>{getOpeningHoursMessage(selectedSection.sectionOpeningHoursInfo)}</p>
      </CardHeader>
      <>
        {tab === TabOptions.Products && <ProductList tab={tab} setTab={setTab} />}
        {tab === TabOptions.Settings && (
          <SectionSettingsForm
            key={selectedSection.sectionId}
            tab={tab}
            setTab={setTab}
            selectedSection={selectedSection}
            selectedMenu={selectedMenu}
          />
        )}
        {tab === TabOptions.SectionUsage && <SectionUsageTab tab={tab} setTab={setTab} />}
        {tab === TabOptions.Micronetwork && <ShareSectionTab tab={tab} setTab={setTab} />}
      </>
    </CardCompact>
  );
};

export default Section;
