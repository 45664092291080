import { useFormContext } from "react-hook-form";
import { useLookups } from "features/lookups/api/useLookups";
import { ProductFormType } from "features/productForm/formValidation";
import Spinner from "components/Spinner";
import Select from "components/Select";

type Props = {
  editable: boolean;
};

const ProductCategory = (props: Props) => {
  const { editable } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductFormType>();

  const { data: lookups, isSuccess } = useLookups();

  if (!isSuccess) {
    return <Spinner />;
  }

  const productCategories = lookups.productCategories;

  return (
    <Select
      labelText="Category"
      disabled={!editable}
      registerReturn={register("productCategoryId")}
      fieldError={errors.productCategoryId}
      widthClassName="w-1/2"
    >
      <option value={""}>No category selected</option>
      {productCategories.map((category) => (
        <option key={category.categoryId} value={category.categoryId}>
          {category.name}
        </option>
      ))}
    </Select>
  );
};

export default ProductCategory;
