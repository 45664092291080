import ControlledSwitch from "components/ControlledSwitch";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { Control } from "react-hook-form";

type RequiredProps = {
  control: Control<CustomizationGroupFormType>;
};

const Required = (props: RequiredProps) => {
  const { control } = props;

  return (
    <label className="flex flex-col gap-4">
      <p className="text-sm text-grey-dark text-bold">Required?</p>
      <ControlledSwitch control={control} name={"required"} />
    </label>
  );
};

export default Required;
