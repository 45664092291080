import { LocationDto } from "./contracts";

export class permissionCalculator {
  private static readonly menuToggleItems: string = "MENU_TOGGLE_ITEMS";
  private static readonly menuEditMenu: string = "MENU_EDIT_MENU";

  public static canEditMenu = (location?: LocationDto): boolean => {
    if (!location) {
      return false;
    }

    return location.userPermissions.includes(permissionCalculator.menuEditMenu);
  };

  public static canToggleItems = (location?: LocationDto): boolean => {
    if (!location) {
      return false;
    }

    return location.userPermissions.includes(permissionCalculator.menuToggleItems);
  };
}
