import { useOrdrServiceConfig } from "features/ordrservice/useOrdrServiceConfig";
import { useUpdateOrderLinesOnFireTicket } from "features/ordrservice/useUpdateOrderLinesOnFireTicket";
import Spinner from "components/Spinner";
import { Radio, RadioGroup } from "@ordr-as/umami-react";
import { useEffect, useState } from "react";

const ToggleOrderLinesOnFireTickets = () => {
  const ordrServiceQuery = useOrdrServiceConfig();
  const mutation = useUpdateOrderLinesOnFireTicket();

  const isCheckedInitially = ordrServiceQuery?.data?.enabledFeatures?.includes("PRINT_ORDER_LINES_ON_FIRE_TICKETS") || false;
  const [selected, setSelected] = useState("0");

  useEffect(() => {
    setSelected(isCheckedInitially ? "1" : "0");
  }, [isCheckedInitially]);

  if (!ordrServiceQuery || !ordrServiceQuery.data?.enabledFeatures) {
    return <Spinner />;
  }

  const handleRadioChange = (value: string) => {
    setSelected(value);
    const isSelected = value === "1";
    mutation.mutate(isSelected);
  };

  return (
    <div>
      <div className="flex flex-col py-3">
        <span>Fire course tickets</span>
        <span className="text-sm text-grey-dark">Let kitchen know when to prepare a course by printing a ticket.</span>
      </div>
      <RadioGroup value={selected} onChange={handleRadioChange} aria-labelledby="fire-course-tickets-label">
        <Radio value="0" className="flex py-3 border-y border-grey-lightest">
          Don’t include orders in the course when Firing
        </Radio>
        <Radio value="1" className="flex py-3 border-b border-grey-lightest">
          Include orders in the course when Firing
        </Radio>
      </RadioGroup>
    </div>
  );
};

export default ToggleOrderLinesOnFireTickets;
