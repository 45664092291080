import AppHeader from "features/header/components/AppHeader";
import Settings from "features/settings/components/Settings";

const SettingsPage = () => {
  return (
    <>
      <AppHeader />
      <Settings />
    </>
  );
};

export default SettingsPage;
