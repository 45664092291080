import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useFetch } from "api/useFetch";
import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useRemoveProductFromSection = () => {
  const { selectedSection } = useSelectedSection();
  const { destroy } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (productId: string) => {
      const removeProductFromSectionUrl = `${config.menuBuilderUrl}/v1/menus/${selectedSection?.menuId}/sections/${selectedSection?.sectionId}/products/${productId}`;

      return destroy(removeProductFromSectionUrl);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["products"]);
        queryClient.invalidateQueries(["sections"]);
        queryClient.invalidateQueries(["existingProducts"]);
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
