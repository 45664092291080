import { WastageDto } from "types/contracts/contracts";

type Props = { wastage: WastageDto };

const WastageOverviewItem = (props: Props) => {
  const { wastage } = props;

  const quantifierLabel = (quantity: number, quantityInMilliliters?: number) => {
    if (!quantityInMilliliters) {
      return `${quantity} x `;
    }
    return `${quantity} x ${quantityInMilliliters}ml `;
  };

  return (
    <div key={wastage.wastageId} className="grid grid-cols-3 ">
      <div className="flex flex-col gap-2 text-grey-dark border border-grey-lightest p-4">
        <span>{wastage.wastageTimestampInLocationTimeZoneDisplay}</span>
        <span>{wastage.username}</span>
      </div>
      <div className="bold text-left border border-grey-lightest p-4">
        {quantifierLabel(wastage.quantity, wastage.quantityInMilliliters)}
        {wastage.productName}
        {wastage.sizeName && ` - ${wastage.sizeName}`}
      </div>
      <div className="flex flex-col gap-2 text-grey-dark border border-grey-lightest p-4">
        <span>
          <span className="bold">Cause: </span>
          <span className="capitalize">{wastage.wastageType.toLocaleLowerCase()}</span>
        </span>
        {wastage.comment && (
          <span>
            <span className="bold">Comment: </span>
            {wastage.comment}
          </span>
        )}
      </div>
    </div>
  );
};

export default WastageOverviewItem;
