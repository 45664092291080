import Select from "components/Select";
import Spinner from "components/Spinner";
import useLocalVats from "features/taxGroups/hooks/useLocalVats";
import { useFormContext } from "react-hook-form";
import { TaxGroupsFormType } from "../../types/types";

type Props = {
  index: number;
  disabled: boolean;
};

const TakeawayVatId = (props: Props) => {
  const { index, disabled } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<TaxGroupsFormType>();
  const formRegister = register(`taxGroups.${index}.takeawayVatId`, {
    setValueAs: (value) => (value ? value : undefined),
  });

  const vats = useLocalVats();

  if (!vats) {
    return <Spinner />;
  }

  return (
    <Select labelText="" registerReturn={formRegister} fieldError={errors.taxGroups?.[index]?.takeawayVatId} disabled={disabled}>
      <option key={0} value={""}>
        No vat selected
      </option>
      {vats.map((vat) => (
        <option key={vat.vatId} value={vat.vatId}>
          {vat.displayText}
        </option>
      ))}
    </Select>
  );
};

export default TakeawayVatId;
