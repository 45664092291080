import { useMemo } from "react";
import { useSelectedProducts } from "./useSelectedProducts";

export const useSelectedProductIds = () => {
  const { selectedProducts } = useSelectedProducts();

  const selectedProductIds = useMemo(() => selectedProducts.map((product) => product.productId), [selectedProducts]);

  return { selectedProductIds };
};
