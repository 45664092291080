import { Dispatch, SetStateAction } from "react";
import { TabOptions } from "./Tabs";
import ShareSectionTab from "features/micronetworks/components/ShareSection/ShareSectionTab";
import PartnerProductList from "features/products/components/PartnerProductList/PartnerProductList";
import PartnerSectionSettingsForm from "./SectionSettingsForm/PartnerSectionSettingsForm";
import { CardCompact, CardHeader } from "components/Card";
import SectionUsageTab from "./SectionSettingsForm/SectionUsageTab";
import { MenuSectionDto, SectionOpeningHoursInfoDto } from "types/contracts/contracts";

type Props = {
  section: MenuSectionDto;
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
};

const PartnerSection = (props: Props) => {
  const { section, tab, setTab } = props;

  const getOpeningHoursMessage = (openingHoursInfo: SectionOpeningHoursInfoDto) => {
    if (!openingHoursInfo) {
      return <></>;
    }

    if (
      openingHoursInfo.locationIsOpen &&
      openingHoursInfo.locationSectionIsOpen &&
      openingHoursInfo.micronetworkedSectionLocationIsOpen &&
      openingHoursInfo.micronetworkedSectionIsOpenOnOwnerLocation
    ) {
      return <></>;
    }

    if (!openingHoursInfo.locationIsOpen) {
      return <p className="mt-4">Location is outside of opening hours. Section is closed.</p>;
    }

    if (!openingHoursInfo.locationSectionIsOpen) {
      return <p className="mt-4">Section is outside of opening hours. Section is closed.</p>;
    }

    if (!openingHoursInfo.micronetworkedSectionLocationIsOpen) {
      return <p className="mt-4">Micronetwork location is outside of opening hours. Section is closed.</p>;
    }

    if (openingHoursInfo.ownerOpeningHoursIgnored) {
      return (
        <p className="mt-4">
          Micronetwork owner section is outside of opening hours, but it is configured to be ignored for this section. Section is open.
        </p>
      );
    }

    return <p className="mt-4">Micronetwork owner section is outside of opening hours. Section is closed.</p>;
  };

  return (
    <CardCompact className="w-1/2">
      <CardHeader>
        <h4>{section.name}</h4>

        <p>{getOpeningHoursMessage(section.sectionOpeningHoursInfo)}</p>
      </CardHeader>
      <>
        {tab === TabOptions.Products && <PartnerProductList tab={tab} setTab={setTab} />}
        {tab === TabOptions.Settings && <PartnerSectionSettingsForm tab={tab} setTab={setTab} section={section} />}
        {tab === TabOptions.SectionUsage && <SectionUsageTab tab={tab} setTab={setTab} />}
        {tab === TabOptions.Micronetwork && <ShareSectionTab tab={tab} setTab={setTab} />}
      </>
    </CardCompact>
  );
};

export default PartnerSection;
