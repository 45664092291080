import { Button } from "@ordr-as/umami-react";
import { useForm } from "react-hook-form";
import EnableOpeningHours from "./EnableOpeningHours";
import Name from "./Name";
import EposOnly from "./EposOnly";
import { sectionSettingsFormSchema, SectionSettingsFormType } from "./formValidation";
import { Dispatch, SetStateAction } from "react";
import { CardBody } from "components/Card";
import { useUpdateSection } from "features/sections/api/useUpdateSection";
import Tabs, { TabOptions } from "../Tabs";
import DeleteSectionButton from "./DeleteSectionButton";
import HideFromMenuWhenClosed from "./HideWhenSectionIsClosed";
import { zodResolver } from "@hookform/resolvers/zod";
import OpeningHours from "./OpeningHours/OpeningHours";
import MicronetworkOpeningHours from "./OpeningHours/MicronetworkOpeningHours";
import MicronetworkEposOnly from "./MicronetworkEposOnly";
import MicronetworkHideFromMenuWhenClosed from "./MicronetworkHideWhenSectionIsClosed";
import MicronetworkEnableOpeningHours from "./MicronetworkEnableOpeningHours";
import { MenuDto, MenuSectionDto, UpdateMenuSectionDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import ColorSelector from "./ColorSelector/ColorSelector";
import MicronetworkIgnoreProducerOpeningHours from "./MicronetworkIgnoreProducerOpeningHours";

type Props = {
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
  selectedSection: MenuSectionDto;
  selectedMenu: MenuDto;
};

const SectionSettingsForm = (props: Props) => {
  const { tab, setTab, selectedSection, selectedMenu } = props;
  const mutation = useUpdateSection();
  const { canEditMenu, canToggleItems } = useSelectedLocation();

  const { register, handleSubmit, control } = useForm<SectionSettingsFormType>({
    defaultValues: { ...selectedSection },
    resolver: zodResolver(sectionSettingsFormSchema),
  });

  const updateMenuSection = (formData: SectionSettingsFormType) => {
    const updatedSection: UpdateMenuSectionDto = {
      ...formData,
      active: selectedSection.active,
    };

    mutation.mutate(updatedSection, {
      onSettled: () => {
        setTab(TabOptions.Products);
      },
    });
  };

  return (
    <CardBody>
      <Tabs onTabPress={setTab} selectedTab={tab} />

      <form onSubmit={handleSubmit(updateMenuSection)} className="flex flex-col gap-4">
        <Name register={register} disabled={!canEditMenu} />
        <ColorSelector control={control} disabled={!canEditMenu} />
        <h4 className="mt-4">
          Section opening hours for {selectedSection.sectionOwnerName} - {selectedMenu.menuName}
        </h4>
        <EposOnly control={control} disabled={!canEditMenu} />
        <HideFromMenuWhenClosed control={control} disabled={!canToggleItems} />
        <EnableOpeningHours control={control} disabled={!canToggleItems} />
        <OpeningHours openingHours={selectedSection.openingHours} register={register} control={control} menuId={selectedSection.menuId} />
        {selectedSection.micronetworkOpeningHours.length > 0 && <h4 className="mt-6">Micronetwork sections</h4>}
        {selectedSection.micronetworkOpeningHours.map((x, index) => (
          <div key={x.menuId}>
            <h4 className="mt-4">
              Section opening hours for {x.locationName} - {x.menuName}
            </h4>
            <MicronetworkEposOnly control={control} menuIndex={index} key={"eposOnly" + x.menuId} />
            <MicronetworkHideFromMenuWhenClosed control={control} menuIndex={index} key={"whenClosed" + x.menuId} />
            <MicronetworkIgnoreProducerOpeningHours control={control} menuIndex={index} key={"ignore" + x.menuId} />
            <MicronetworkEnableOpeningHours control={control} menuIndex={index} key={"enableOpeningHours" + x.menuId} />
            <MicronetworkOpeningHours
              openingHours={x.openingHours}
              register={register}
              control={control}
              key={"openinghours" + x.menuId}
              menuIndex={index}
            />
          </div>
        ))}
        <Button kind="primary" type="submit" isDisabled={!canEditMenu && !canToggleItems}>
          Save
        </Button>
        {canEditMenu && <DeleteSectionButton sectionId={selectedSection.sectionId} />}
      </form>
    </CardBody>
  );
};

export default SectionSettingsForm;
