import { useCourses } from "features/courses/api/useCourses";
import SettingsCard from "../SettingsCard";
import CreateNewCourseButton from "./CreateCourseButton";
import Spinner from "components/Spinner";
import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";
import UpdateCourseButton from "./UpdateCourseButton";
import { useUpdateCourseSequence } from "./api/useUpdateCourseSequence";
import ToggleOrderLinesOnFireTickets from "./ToggleOrderLinesOnFireTickets";
import HandleIcon from "@ordr-as/icons/lib/HandleIcon";

const CourseManagement = () => {
  const coursesQuery = useCourses();
  const updateCourseSequence = useUpdateCourseSequence();

  if (!coursesQuery.isSuccess) {
    return <Spinner />;
  }

  const courses = coursesQuery.data;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    updateCourseSequence.mutate({
      courseId: courses[result.source.index].courseId,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
  };

  return (
    <>
      <SettingsCard title="Courses">
        <span className="flex pb-5">
          Set up courses to assign to products, so they automatically appear in the correct course in the cart, and on the printed ticket.
        </span>
        <div className="w-full max-w-lg">
          <span className="flex font-semibold pb-5">Course order:</span>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`sections-droppable`}>
              {(provided) => (
                <ul ref={provided.innerRef} {...provided.droppableProps}>
                  {courses.map((course, index) => (
                    <Draggable key={course.courseId} draggableId={course.courseId} index={index}>
                      {(provided) => (
                        <div
                          className="flex items-center mb-1 rounded-md pb-5"
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <div>
                            <div className="flex gap-2 items-center">
                              <HandleIcon className="cursor-move w-5 h-5 text-grey-dark" />
                              <UpdateCourseButton course={course} />
                            </div>
                            <span className="ml-6 text-xs text-grey-dark">Used by {course.usageCount} products</span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="flex items-center gap-4 pb-5">
          <CreateNewCourseButton />
        </div>
        <div className="flex flex-col text-grey-dark">
          <span className="text-xs font-semibold">NO COURSE</span>
          <span className="text-sm">If a product does not have a course assigned to it, it will be added under “No course” in the cart. </span>
        </div>
        <div className="flex items-center gap-4 pb-2 pt-5">
          <ToggleOrderLinesOnFireTickets />
        </div>
      </SettingsCard>
      <div className="pb-8"></div>
    </>
  );
};

export default CourseManagement;
