import Select from "components/Select";
import Spinner from "components/Spinner";
import { useCourses } from "features/courses/api/useCourses";
import { ProductFormType } from "features/productForm/formValidation";
import { useFormContext } from "react-hook-form";

type Props = {
  editable: boolean;
};

const Courses = (props: Props) => {
  const { editable } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductFormType>();
  const { data: courses, isSuccess } = useCourses();

  if (!isSuccess) {
    return <Spinner />;
  }

  if (courses.length < 1) {
    return <NoCoursesFound />;
  }

  return (
    <>
      <Select
        labelText="Course"
        disabled={!editable}
        registerReturn={register("courseId", {
          setValueAs: (value) => (value ? value : undefined),
        })}
        fieldError={errors.taxGroupId}
        widthClassName="w-1/2"
      >
        <option value={""}>No course selected</option>
        {courses.map((course) => (
          <option key={course.courseId} value={course.courseId}>
            {course.name}
          </option>
        ))}
      </Select>
    </>
  );
};

export default Courses;

const NoCoursesFound = () => {
  return (
    <>
      <label>
        <label>
          <p className="pb-1">Course</p>
          <input disabled className="border border-grey-medium rounded py-3 px-3 w-1/2" value="No courses defined." />
        </label>
      </label>
    </>
  );
};
