import * as z from "zod";

export type TaxGroupsFormType = z.infer<typeof taxGroupsFormSchema>;

const taxGroupFormSchema = z.object({
  taxGroupId: z.string().uuid().nullish(),
  name: z.string().min(1, { message: "Name is required." }),
  eatInAccountReference: z.string().nullish(),
  takeawayAccountReference: z.string().nullish(),
  takeawayVatId: z.string().uuid().nullish(),
  eatInVatId: z.string().uuid().nullish(),
});

export const taxGroupsFormSchema = z.object({
  taxGroups: z.array(taxGroupFormSchema),
});
