import { Button } from "@ordr-as/umami-react";
import { FieldError, useFieldArray, UseFormReturn } from "react-hook-form";
import { ProductDetailsDto } from "types/contracts/contracts";
import { ProductFormType } from "../../../formValidation";
import Size from "./Size";

type SizesProps = {
  canEditBasicData?: boolean;
  isGiftCard: boolean;
  canEditInventoryAndActiveToggleOnSizes?: boolean;
  isCentrallyManaged: boolean;
  methods: UseFormReturn<ProductFormType, object>;
  product?: ProductDetailsDto;
};

const Sizes = (props: SizesProps) => {
  const { canEditBasicData, isGiftCard, canEditInventoryAndActiveToggleOnSizes, isCentrallyManaged, methods, product } = props;

  const {
    formState: { errors },
    clearErrors,
  } = methods;

  const sizeError = errors.sizes as any as FieldError;

  const { fields, append, remove } = useFieldArray<ProductFormType, "sizes">({
    name: "sizes",
  });

  return (
    <>
      <div className="flex-wrap items-center border rounded py-3 px-3 border-grey-medium">
        <h4>Sizes and prices</h4>
        <div className="py-2 flex w-full flex-col px-4 divide-y-2 divide-grey-lightest">
          {fields.map((_, index) => (
            <Size
              index={index}
              key={index}
              canEditBasicData={canEditBasicData}
              isGiftCard={isGiftCard}
              canEditInventoryAndActiveToggleOnSizes={canEditInventoryAndActiveToggleOnSizes}
              isCentrallyManaged={isCentrallyManaged}
              remove={remove}
              product={product}
            />
          ))}
        </div>
        {sizeError && sizeError.message && <p className="text-sm text-orange-darkest umami-text-input-helper">{sizeError.message}</p>}
        {canEditBasicData && !isGiftCard && (
          <>
            <Button
              kind="secondary"
              onClick={() => {
                append(emptySize);
                clearErrors("sizes");
              }}
            >
              Add new size
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Sizes;

const emptySize = {
  name: "",
  allowOpenPrice: false,
  price: 0,
  priceType: "PER_ITEM",
  active: true,
  hasAutomaticOut: false,
  automaticOutQuantity: 0,
  hasComponents: false,
};
