import { EposDisplayColorDto, SectionContainingProductDto } from "types/contracts/contracts";
import Divider from "./Divider";

type Props = {
  eposDisplayColors: EposDisplayColorDto[];
  sectionsContainingProduct: SectionContainingProductDto[] | undefined;
  selectedSection: SectionContainingProductDto | undefined;
  setSelectedSection: (section: SectionContainingProductDto | undefined) => void;
};

const SectionColorRows = (props: Props) => {
  const { eposDisplayColors, sectionsContainingProduct, selectedSection, setSelectedSection } = props;
  return (
    <div className="w-full">
      {sectionsContainingProduct &&
        sectionsContainingProduct.map((section) => {
          const sectionColor = eposDisplayColors.find((color) => color.hexCode === section.sectionEposDisplayColor);
          const selected = section === selectedSection;
          return (
            <div key={section.sectionName}>
              <Divider />
              <button
                type="button"
                className="flex items-center justify-between w-full min-h-[48px] px-4 hover:bg-purple-lightest"
                onClick={() => {
                  setSelectedSection(section);
                }}
                data-id="select-epos-display-color-inherit-from-specific-section"
              >
                <span className={`text-brand-black text-left ${selected ? "font-semibold" : ""}`}>{section.sectionName}</span>
                <div className="flex items-center">
                  {sectionColor && <div className="h-5 w-5 rounded" style={{ backgroundColor: sectionColor.hexCode }} />}
                  <span className={`pl-3 text-grey-dark text-sm ${selected ? "font-semibold" : ""}`}>{sectionColor?.name ?? "No colour"}</span>
                </div>
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default SectionColorRows;
