import { Button } from "@ordr-as/umami-react";
import { useFetch } from "api/useFetch";
import config from "config";
import { useParams } from "react-router-dom";

type DownloadButtonProps = { fromDate?: string; toDate?: string };

const DownloadButton = (props: DownloadButtonProps) => {
  const { fromDate, toDate } = props;
  const { download } = useFetch();

  const { locationId } = useParams<{ locationId: string }>();
  const url = `${config.menuBuilderUrl}/v1/locations/${locationId}/wastages/excel?fromDate=${fromDate}&toDate=${toDate}`;

  const handleClick = () => {
    download(url);
  };

  return (
    <Button kind="secondary" onClick={handleClick} className="w-full mt-4">
      Download Excel
    </Button>
  );
};

export default DownloadButton;
