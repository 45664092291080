import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BulkUpdatePrinterDto } from "types/contracts/contracts";

export const useBulkUpdatePrinters = () => {
  const { post } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (vars: { printerId: number | undefined; productIds: string[] }) => {
      const { printerId, productIds } = vars;

      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/products/bulk-update-printer`;

      const updateDto: BulkUpdatePrinterDto = {
        productIds: productIds,
        printerId: printerId,
      };

      return post(url, updateDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
