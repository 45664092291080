import { asOptionalField } from "utils/utils";
import * as z from "zod";
import { isNumber } from "lodash";
import { TaxGroupDto } from "../../types/contracts/contracts";

export type ProductFormType = z.infer<ReturnType<typeof productFormSchema>>;

export type ProductSizeFormType = z.infer<typeof productSizeFormSchema>;

const requiredNameWhenMultipleSizes = (sizes: z.infer<typeof productSizeFormSchema>[], ctx: z.RefinementCtx) => {
  if (sizes.length < 2) {
    return;
  }

  sizes.forEach((size, index) => {
    if (size.name) {
      return;
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Size name is required when there is more than one size.",
      path: [`${index}.name`],
    });
  });
};

const productSizeFormSchema = z
  .object({
    productSizeId: asOptionalField(z.string().uuid()),
    active: z.boolean().default(true),
    name: asOptionalField(z.string()),
    allowOpenPrice: z.boolean().default(false),
    priceType: z.string(),
    price: z.number().min(0, { message: "Price cannot be below 0" }),
    takeawayPrice: asOptionalField(z.preprocess((value) => {
      if (value === '' || (isNumber(value) && isNaN(<number>value))) {
        return undefined;
      }
      return value;
    }, z.number().optional())),
    priceOverride: asOptionalField(
      z
        .number()
        .or(z.string().regex(/\d+/).transform(Number))
        .refine((n) => n >= 0, "Price override cannot be below 0"),
    ),
    hasAutomaticOut: z.boolean().default(false),
    automaticOutQuantity: asOptionalField(z.number().min(0, "Quantity cannot be below 0")),
  })
  // Set priceType from open price or as PER_ITEM, if not set
  // TODO: Remove this once there are no more nulls in the database
  .transform(
    (data) => {
        if (!data.priceType) {
          // These values come from LookupService.cs in the backend
          // We should get rid of allowOpenPrice and make the priceType field
          // non-nullable asap.
          data.priceType = data.allowOpenPrice ? "OPEN_PRICE" : "PER_ITEM"
        }
        return data
  })
  // Set allowOpen price from priceType, if not set
  .transform(
    (data) => {
        if (data.priceType) {
          data.allowOpenPrice = data.priceType == "OPEN_PRICE"
        }
        return data
  })
  .refine(
    (data) => {
      if (!data.hasAutomaticOut) {
        return true;
      }
      if (!data.active) {
        return true;
      }
      if (data.automaticOutQuantity === undefined) {
        return false;
      }

      if (data.automaticOutQuantity < 0) {
        return false;
      }

      return true;
    },
    {
      message: "If a size is active and has inventory tracking, quantity must be greater than 0.",
      path: ["inventoryQuantity"],
    },
  );

export const productFormSchema = (requireTaxGroup: boolean, taxGroups: TaxGroupDto[] | undefined) => {
  const taxGroupSchema = requireTaxGroup ? z.string().uuid() : asOptionalField(z.string().uuid());

  return z.object({
    eposDisplayColorSectionId: asOptionalField(z.string()),
    name: z.string().min(1, { message: "Name is required." }),
    description: asOptionalField(z.string()),
    allergenIds: z.array(z.string()),
    active: z.boolean().default(true),
    eposOnly: z.boolean().default(false),
    productLibraryId: asOptionalField(z.number()),
    productLibraryProductName: asOptionalField(z.string()),
    productLibrarySupplierName: asOptionalField(z.string()),
    productCategoryId: z.string(),
    taxGroupId: taxGroupSchema,
    courseId: asOptionalField(z.string().uuid()),
    sizes: z.array(productSizeFormSchema).min(1, { message: "At least one size is required" }).superRefine(requiredNameWhenMultipleSizes),
    printerId: asOptionalField(z.number()),
    secondaryPrinterId: asOptionalField(z.number()),
    productImageUrl: asOptionalField(z.string()),
    removables: z.array(
      z.object({
        productSizeId: asOptionalField(z.string().uuid()),
        price: z.number().min(0),
        name: z.string(),
      }),
    ),
    addons: z.array(
      z.object({
        productSizeId: asOptionalField(z.string().uuid()),
        price: z.number().min(0),
        name: z.string(),
      }),
    ),
    centrallyManagedChildProductLocations: z.array(z.number()),
    productEposDisplayColor: asOptionalField(z.string())
  }).superRefine((data, ctx) => {
    data.sizes.forEach((size, index) => {
      const takeawayPriceHasBeenSet = size.takeawayPrice !== undefined;

      if (takeawayPriceHasBeenSet) {
        const selectedTaxGroup = taxGroups?.find(group => group.taxGroupId === data.taxGroupId);
        if (selectedTaxGroup?.taxGroupId === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "A tax group with takeaway VAT is required when setting takeaway prices.",
            path: [`taxGroupId`]
          });
          return;
        }

        if (selectedTaxGroup?.takeawayVatId === null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "The selected tax group does not have a VAT set for takeaway deliveries. Update the tax group before setting a takeaway price.",
            path: [`sizes.${index}.takeawayPrice`]
          });
        }
      }
    });
  });
};
