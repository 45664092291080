import * as z from "zod";

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const updateSequenceOnItems = <T>(items: T[]) => {
  return items.map((item, index) => updateSequenceOnItem<T>(item, index));
};

const updateSequenceOnItem = <T>(items: T, sequence: number): T => {
  return { ...items, sequence: sequence };
};

export const findUnique = <T>(list: T[]): T[] => {
  const set = new Set<T>(list);
  return Array.from(set);
};

const nullToUndefined = z.literal(null).transform(() => undefined);
const emptyStringToUndefined = z.literal("").transform(() => undefined);

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
  return schema.optional().or(nullToUndefined).or(emptyStringToUndefined);
}
