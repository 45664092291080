import React, { createContext, ReactNode, useContext, useState, Dispatch } from "react";

type LastActiveMenuContextType = {
  lastActiveMenuId?: string;
  setLastActiveMenuId: Dispatch<React.SetStateAction<string | undefined>>;
};

const LastActiveMenuContext = createContext<LastActiveMenuContextType | undefined>(undefined);

const LastActiveMenuProvider = ({ children }: { children: ReactNode }) => {
  const [lastActiveMenuId, setLastActiveMenuId] = useState<string>();

  return <LastActiveMenuContext.Provider value={{ lastActiveMenuId, setLastActiveMenuId }}>{children}</LastActiveMenuContext.Provider>;
};

export default LastActiveMenuProvider;

export const useLastActiveMenuContext = () => {
  const context = useContext(LastActiveMenuContext)!;

  if (context === undefined) {
    throw Error("useLastActiveMenuContext must be used within LastActiveMenuProvider");
  }

  return context;
};
