import { Route, Routes } from "react-router-dom";
import AllLocationsPage from "../AllLocationsPage";
import LocationRoutes from "./LocationRoutes";
import CopyLocationPage from "pages/CopyLocationPage";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<AllLocationsPage />} />;
      <Route path="/locations/:locationId/*" element={<LocationRoutes />} />
      <Route path="/copy-location" element={<CopyLocationPage />} />
    </Routes>
  );
};

export default Routing;
