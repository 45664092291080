import { EposDisplayColorDto } from "types/contracts/contracts";

type Props = {
  eposDisplayColors: EposDisplayColorDto[];
  selectedProductColor: EposDisplayColorDto | undefined;
  setColor: (color: EposDisplayColorDto | undefined) => void;
};

const ColorPickerGrid = (props: Props) => {
  const { eposDisplayColors, selectedProductColor, setColor } = props;
  return (
    <div className="grid grid-cols-3 gap-4 py-5 px-4">
      {eposDisplayColors.map((color) => (
        <button
          type="button"
          key={color.name}
          className="flex flex-col items-center gap-2 px-1 py-2 hover:bg-purple-lightest rounded"
          onClick={() => {
            setColor(color);
          }}
          data-id={`select-epos-display-color-${color.name.replace(/\s+/g, "-").toLowerCase()}`}
        >
          <div
            className={`h-[40px] w-[40px] border-4 rounded-lg ${color === selectedProductColor ? " border-brand-secondary" : "border-brand-white"}`}
            style={{ backgroundColor: color.hexCode }}
          />
          <span className={`text-sm text-brand-black text-center ${color === selectedProductColor ? "font-semibold" : ""}`}>{color.name}</span>
        </button>
      ))}
    </div>
  );
};

export default ColorPickerGrid;
