import * as z from "zod";

export type CustomizationGroupFormType = z.infer<typeof customizationGroupFormSchema>;

export const customizationGroupFormSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  displayTextInMenu: z.string().min(1, { message: "Display text is required" }),
  selectionType: z.enum(["SINGLE_CHOICE", "MULTIPLE_CHOICE", "QUANTITY"]),
  required: z.boolean(),
  useSelectionLimit: z.boolean(),
  minimumSelectionLimit: z.number().min(0).optional(),
  maximumSelectionLimit: z.number().min(0).optional(),
});
