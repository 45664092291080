import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "config";
import { useFetch } from "api/useFetch";
import { UpdateTaxGroupDto } from "types/contracts/contracts";

export const useUpdateTaxGroups = () => {
  const { selectedLocation } = useSelectedLocation();
  const { put } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (saveTaxGroup: UpdateTaxGroupDto[]) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/tax-groups`;

      return put(url, saveTaxGroup);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["taxGroups"]);
      },
    },
  );

  return mutation;
};
