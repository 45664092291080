import { useNavigate, useParams } from "react-router-dom";

export const useNavigateToSupplier = () => {
  const navigate = useNavigate();
  const { locationId, supplierId } = useParams<{ locationId: string; supplierId: string }>();

  const navigateToSupplier = () => {
    navigate(`/locations/${locationId}/product-library/suppliers/${supplierId}`);
  };

  return navigateToSupplier;
};
