import { CustomizationGroupDto } from "types/contracts/contracts";
import { Link } from "react-router-dom";
import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";
import { ChevronRightIcon, HandleIcon } from "@ordr-as/icons";
import { useUpdateCustomizationGroupSequence } from "features/options/api/customizations/useUpdateCustomizationGroupSequence";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import Spinner from "components/Spinner";

type Props = {
  customizationGroups: CustomizationGroupDto[];
};

const CustomizationGroupListItems = (props: Props) => {
  const { customizationGroups } = props;
  const updateCustomizationGroupSequenceMutation = useUpdateCustomizationGroupSequence();

  const { selectedLocation, canEditMenu } = useSelectedLocation();

  if (!selectedLocation) {
    return <Spinner />;
  }

  const locationId = selectedLocation.locationId;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    updateCustomizationGroupSequenceMutation.mutate({
      customizationGroupId: customizationGroups[result.source.index].optionGroupId,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
  };

  const umamiStyles = "flex justify-between items-center bg-brand-white py-2 gap-4";
  const hoverStyle = "local-option-group";
  const listElementStyle = `${umamiStyles} ${hoverStyle}`;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`option-group-product-droppable`}>
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {customizationGroups.map((customizationGroup, index) => (
              <Draggable
                key={customizationGroup.optionGroupId}
                draggableId={customizationGroup.optionGroupId}
                index={index}
                isDragDisabled={!canEditMenu}
              >
                {(provided) => (
                  <div className="mb-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Link to={`/locations/${locationId}/options/${customizationGroup.optionGroupId}`}>
                      <li className={listElementStyle}>
                        <div className="flex">
                          <div className="flex items-center">{canEditMenu && <HandleIcon />}</div>
                          <p className="px-2">{customizationGroup.name}</p>
                          {customizationGroup.usageCount < 1 && <UnusedLabel />}
                        </div>

                        <div className="flex items-center">
                          <ChevronRightIcon />
                        </div>
                      </li>
                    </Link>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CustomizationGroupListItems;

const UnusedLabel = () => {
  const labelStyles = `text-xs font-semibold flex items-center rounded px-2 h-5 uppercase text-brand-black bg-grey-medium`;

  return <p className={labelStyles}>Not used</p>;
};
