import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedOptionGroup } from "../../hooks/useSelectedOptionGroup";

export const useRemoveRecommendation = () => {
  const { selectedLocation } = useSelectedLocation();
  const { selectedOptionGroup } = useSelectedOptionGroup();
  const { destroy } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (productSizeId: string) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/recommendation-groups/${selectedOptionGroup?.optionGroupId}/product-sizes/${productSizeId}`;

      return destroy(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["optionGroups"]);
        queryClient.invalidateQueries(["availableProductForRecommendationGroup"]);
      },
    }
  );

  return mutation;
};
