import { useLocations } from "features/locations/api/useLocations";
import { useMemo } from "react";
import { usePartnerLocationsForSection } from "../api/usePartnerLocationsForSection";

export const usePotentialAndExistingPartners = () => {
  const { data: partnerLocations } = usePartnerLocationsForSection();
  const { data: locations } = useLocations();

  const partnerLocationIds = useMemo(() => partnerLocations?.map((pl) => pl.partnerLocationId) ?? [], [partnerLocations]);

  const locationsWithOutPartners = useMemo(
    () => locations?.filter((location) => !partnerLocationIds.includes(location.locationId)) ?? [],
    [locations, partnerLocationIds]
  );

  return { partnerLocations, locationsWithOutPartners };
};
