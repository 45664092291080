import SettingsCard from "../SettingsCard";
import DeleteMenu from "./DeleteMenu";
import NewMenuButton from "./NewMenuButton";
import SelectMenu from "./SelectMenu";

const MenuSelect = () => {
  return (
    <>
      <SettingsCard title="Active menus">
        <div className="flex items-center mx-4 gap-4 pb-4">
          <SelectMenu />
        </div>
      </SettingsCard>

      <SettingsCard title="Create new menu">
        <div className="flex items-center mx-4 gap-4 pb-4">
          <NewMenuButton />
        </div>
      </SettingsCard>

      <SettingsCard title="Delete unused menus">
        <div className="flex items-center mx-4 gap-4 pb-4">
          <DeleteMenu />
        </div>
      </SettingsCard>
    </>
  );
};

export default MenuSelect;
