import { useUpdateCustomizationGroup } from "features/options/api/customizations/useUpdateCustomizationGroup";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { CustomizationGroupDto } from "types/contracts/contracts";
import CustomizationGroupCardForm from "./CustomizationGroupCardForm";

type Props = {
  customizationGroup: CustomizationGroupDto;
};

const UpdateCustomizationGroup = (props: Props) => {
  const { customizationGroup } = props;
  const updateCustomizationGroupMutation = useUpdateCustomizationGroup();

  const update = (formData: CustomizationGroupFormType) => {
    updateCustomizationGroupMutation.mutate(formData);
  };

  return <CustomizationGroupCardForm createOrUpdate={update} defaultValues={customizationGroup} />;
};

export default UpdateCustomizationGroup;
