import Spinner from "components/Spinner";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useLookups } from "features/lookups/api/useLookups";
import DisallowCreateProduct from "./DisallowCreateProduct";
import DisplaySizesAsSeparateProductsInEpos from "./DisplaySizesAsSeparateProductsInEpos";
import MultiMenuControl from "./multimenu/MultiMenuControl";
import RequireTaxGroup from "./RequireTaxGroup";
import CourseManagement from "./courses/Courses";

const Settings = () => {
  const { selectedLocation } = useSelectedLocation();
  const { data: lookups, isSuccess } = useLookups();

  if (!isSuccess || !selectedLocation) {
    return <Spinner />;
  }

  return (
    <div>
      {lookups.userIsAdmin && (
        <>
          <DisallowCreateProduct location={selectedLocation} />
          <RequireTaxGroup location={selectedLocation} />
          <DisplaySizesAsSeparateProductsInEpos location={selectedLocation} />
        </>
      )}

      <MultiMenuControl />
      <CourseManagement />
    </div>
  );
};

export default Settings;
