import { OverlayProvider, Button, Dialog } from "@ordr-as/umami-react";
import { useBulkDestroyProducts } from "features/bulkEditProducts/api/useBulkDestroyProducts";
import { useSelectedProducts } from "features/bulkEditProducts/hooks/useSelectedProducts";
import { useMemo, useState } from "react";

const Destroy = () => {
  const { selectedProducts } = useSelectedProducts();
  const bulkDestroyProductsMutation = useBulkDestroyProducts();

  const notDeletableProducts = useMemo(() => selectedProducts.filter((product) => !product.isDeletable), [selectedProducts]);

  const deletableProducts = useMemo(() => selectedProducts.filter((product) => product.isDeletable), [selectedProducts]);

  const deletableProductIds = useMemo(() => deletableProducts.map((product) => product.productId), [deletableProducts]);

  const [isOpen, setOpen] = useState(false);

  const handleSave = () => {
    bulkDestroyProductsMutation.mutate(deletableProductIds, {
      onSettled: () => {
        setOpen(false);
      },
    });
  };

  return (
    <OverlayProvider className="px-1">
      <Button kind="secondary" className="rounded-3xl h-[36px] text-sm" onClick={() => setOpen(true)} isDisabled={!selectedProducts.length}>
        Delete product
      </Button>
      {isOpen && (
        <Dialog
          title="Delete products"
          actionLabel="Delete"
          onAction={handleSave}
          secondaryActionLabel="Cancel"
          onSecondaryAction={() => setOpen(false)}
          onClose={() => setOpen(false)}
          isDisabledAction={!deletableProductIds.length}
        >
          {deletableProducts.length > 0 && (
            <div className="pt-4">
              <h3>Deletable products</h3>
              <p className="pb-6">Are you sure you want to permanently delete these products?</p>

              <table>
                <thead>
                  <tr className="border-b">
                    <th>Product that can be deleted</th>
                  </tr>
                </thead>
                <tbody>
                  {deletableProducts.map((product) => (
                    <tr key={product.productId}>
                      <td>{product.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {notDeletableProducts.length > 0 && (
            <div className="pt-8">
              <h3>Not deletable products</h3>
              <p className="pb-6">
                Some products will not be deleted because they are either:
                <ul className="pb-4">
                  <li>- on a section</li>
                  <li>- part of a customization or recommendation group</li>
                  <li>- the parent of other products</li>
                  <li>- a gift card</li>
                </ul>
                <span className="italic">If you want to delete them, remove them from all used places before deleting it here.</span>
              </p>

              <table>
                <thead>
                  <tr className="border-b">
                    <th>Product that cannot be deleted</th>
                  </tr>
                </thead>
                <tbody>
                  {notDeletableProducts.map((product) => (
                    <tr key={product.productId}>
                      <td>{product.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default Destroy;
