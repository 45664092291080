import { useLocations } from "features/locations/api/useLocations";
import { useMemo } from "react";

export const useSelectedPotentialPartners = (selectedLocationIds: number[]) => {
  const { data: locations } = useLocations();

  const selectedPotentialPartners = useMemo(
    () => locations?.filter((location) => selectedLocationIds.includes(location.locationId)) ?? [],
    [selectedLocationIds, locations]
  );

  return { selectedPotentialPartners };
};
