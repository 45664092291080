import { ProductLibraryProductDto } from "types/contracts/contracts";

type Props = { product: ProductLibraryProductDto };

const AlreadyAddedToProductList = (props: Props) => {
  const { product } = props;

  return (
    <div>
      <p>This product already exists in the product list</p>
      <div className="py-2" />
      <div>
        {product.connectedSections.length > 0 && (
          <>
            <h4>Connected sections</h4>
            {product.connectedSections.map((section) => (
              <p key={section.menuSectionExternalId}>{section.sectionName}</p>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AlreadyAddedToProductList;
