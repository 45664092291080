import NumberInput from "components/NumberInput";
import { CreateCustomizationFormType } from "features/options/formValidation/createCustomization";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type MaximumSelectionLimitProps = {
  register: UseFormRegister<CreateCustomizationFormType>;
  errors: FieldErrors<CreateCustomizationFormType>;
};

const MaximumSelectionLimit = (props: MaximumSelectionLimitProps) => {
  const { register, errors } = props;

  return <NumberInput labelText="Additional price" registerReturn={register("price", { valueAsNumber: true })} fieldError={errors.price} />;
};

export default MaximumSelectionLimit;
