import { CardBody, CardFooter } from "components/Card";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { RecommendationGroupDto } from "types/contracts/contracts";
import AddProduct from "./AddProduct/AddProduct";
import RecommendedProductList from "./RecommendedProductList";

type Props = {
  recommendationGroup: RecommendationGroupDto;
};

const RecommendationGroupOptions = (props: Props) => {
  const { recommendationGroup } = props;
  const { canEditMenu } = useSelectedLocation();

  return (
    <>
      <CardBody>
        <RecommendedProductList products={recommendationGroup.recommendedProducts} />
      </CardBody>
      {canEditMenu && (
        <CardFooter className="flex justify-between">
          <AddProduct />
        </CardFooter>
      )}
    </>
  );
};

export default RecommendationGroupOptions;
