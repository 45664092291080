import { Button } from "@ordr-as/umami-react";
import { HandleIcon, TrashIcon } from "@ordr-as/icons";
import Spinner from "components/Spinner";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useDeleteCustomization } from "features/options/api/customizations/useDeleteCustomization";
import { useState } from "react";
import { Switch } from "../../../../components/Switch";
import { useToggleCustomizationActive } from "../../api/customizations/useToggleCustomizationActive";
import { CustomizationDto } from "types/contracts/contracts";

type Props = { customization: CustomizationDto };

const CustomizationListItem = (props: Props) => {
  const { customization } = props;
  const deleteCustomizationMutation = useDeleteCustomization();
  const { selectedLocation, canEditMenu, canToggleItems } = useSelectedLocation();
  const toggleCustomizationActiveMutation = useToggleCustomizationActive();
  const [customizationActive, setCustomizationActive] = useState(customization.active);

  const umamiStyles = "flex justify-between items-center bg-brand-white";
  const hoverStyle = "local-option-group";
  const listElementStyle = `${umamiStyles} ${hoverStyle}`;

  const handleToggle = () => {
    toggleCustomizationActiveMutation.mutate({
      customizationId: customization.customizationId,
      shouldBeActive: !customizationActive,
    });

    setCustomizationActive(!customizationActive);
  };

  if (!selectedLocation) {
    return <Spinner />;
  }

  return (
    <li className={listElementStyle}>
      <div className="flex items-center">
        {canEditMenu && <HandleIcon />}
        <Switch
          className="p-2"
          checked={customizationActive}
          onChange={handleToggle}
          aria-label={"Toggle enabled"}
          stopPropagation={true}
          disabled={!canToggleItems}
        />

        <p className="px-2">{customization.displayText}</p>
      </div>
      <div className="flex items-center gap-4">
        <p>
          Price: {customization.price} {selectedLocation.currencySymbol}
        </p>
        {canEditMenu && (
          <Button onClick={() => deleteCustomizationMutation.mutate(customization.customizationId)}>
            <TrashIcon />
          </Button>
        )}
      </div>
    </li>
  );
};

export default CustomizationListItem;
