import config from "../../../config";
import { useFetch } from "../../../api/useFetch";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useQuery } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { SectionMicronetworkPartnerDto } from "types/contracts/contracts";

export const usePartnerLocationsForSection = () => {
  const { get } = useFetch();
  const { selectedMenu } = useSelectedMenu();
  const { selectedSection } = useSelectedSection();

  const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${selectedSection?.sectionId}/micronetwork-partners`;

  const partnerLocationsForSectionQuery = useQuery<SectionMicronetworkPartnerDto[], Error>(
    ["partnerLocationsForSection", selectedMenu?.menuId, selectedSection?.sectionId],
    () => get(url),
    { enabled: !!selectedMenu?.menuId && !!selectedSection?.sectionId },
  );

  return partnerLocationsForSectionQuery;
};
