import { Item, Select } from "@ordr-as/umami-react/lib/form";
import Spinner from "components/Spinner";
import { useOptionGroups } from "features/options/api/useOptionGroups";
import { Dispatch, SetStateAction } from "react";

type SelectOptionGroupProps = {
  setSelectedOptionGroupId: Dispatch<SetStateAction<string | undefined>>;
};

const SelectOptionGroup = (props: SelectOptionGroupProps) => {
  const { setSelectedOptionGroupId } = props;
  const { data: optionGroups, isSuccess } = useOptionGroups();

  if (!isSuccess) {
    return <Spinner />;
  }

  const { recommendationGroups, customizationGroups } = optionGroups;

  return (
    <div className="flex gap-4 w-full pb-2">
      <div className="flex max-w-[400px] w-full">
        <Select fullWidth label="Select Group" placeholder="Select..." onSelectionChange={(key) => setSelectedOptionGroupId(String(key))}>
          {[...recommendationGroups, ...customizationGroups].map((group) => (
            <Item key={group.optionGroupId}>
              {group.optionGroupType} - {group.name}
            </Item>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SelectOptionGroup;
