import { useState } from "react";
import { useCombobox } from "downshift";
import { useAddProductFromExisting } from "../../api/useAddProductFromExisting";
import { Link } from "react-router-dom";
import { ProductOverviewDto } from "types/contracts/contracts";

type Props = {
  products: ProductOverviewDto[];
};

const ProductComboBox = (props: Props) => {
  const { products } = props;
  const [filteredProducts, setFilteredProducts] = useState(products);
  const mutation = useAddProductFromExisting();

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items: filteredProducts,
    onSelectedItemChange: (changes) => {
      if (!changes.selectedItem) {
        return;
      }
      mutation.mutate(changes.selectedItem.productId);
    },
    onInputValueChange: (changes) => {
      const { inputValue } = changes;

      if (!inputValue) {
        return;
      }

      setFilteredProducts(products.filter((product) => product.name.toLowerCase().includes(inputValue.toLowerCase())));
    },
    itemToString: (item) => (item ? item.name : ""),
  });

  const noResultFromSearch = () => {
    if (filteredProducts.length === 0) {
      return (
        <li className="underline hover:bg-brand-accent cursor-pointer">
          <Link to={`products/new`}>Couldn&apos;t find product, create new?</Link>
        </li>
      );
    }
  };

  const searchResults = () => {
    return filteredProducts.map((product, index) => (
      <li
        className={highlightedIndex === index ? "bg-brand-accent" : ""}
        key={`${product.name}-${index}`}
        {...getItemProps({ item: product, index })}
      >
        {product.name}
      </li>
    ));
  };

  return (
    <div>
      <div>
        <input
          {...getInputProps({ role: "combobox" })}
          aria-label="Add existing product"
          className="border border-grey-medium rounded py-3 px-3"
          placeholder="Add existing product..."
        />
      </div>
      <ul {...getMenuProps()}>
        {isOpen && searchResults()}
        {isOpen && noResultFromSearch()}
      </ul>
    </div>
  );
};

export default ProductComboBox;
