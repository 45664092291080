type PartnerLabelProps = {
  isPartner: boolean;
  ownerLocationName?: string;
};

export const PartnerLabel = (props: PartnerLabelProps) => {
  const { isPartner, ownerLocationName } = props;
  const color = "bg-brand-accent";

  return <SectionLabel display={isPartner} text={ownerLocationName} classNames={color} />;
};

type EposLabelProps = { isEposSection: boolean };

export const EposLabel = (props: EposLabelProps) => {
  const { isEposSection } = props;
  const color = "bg-grey-medium";

  return <SectionLabel display={isEposSection} text="EPOS" classNames={color} />;
};

type SectionLabelProps = {
  display: boolean;
  text?: string;
  classNames: string;
};

const SectionLabel = (props: SectionLabelProps) => {
  const { display, text, classNames } = props;

  if (!display || !text) {
    return null;
  }

  const labelStyles = `text-xs font-semibold flex items-center rounded px-2 h-5 uppercase text-brand-black`;
  const styles = `${labelStyles} ${classNames}`;

  return <p className={styles}>{text}</p>;
};
