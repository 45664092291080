import { Item, TabMenu } from "@ordr-as/umami-react";
import { AlcoholIcon, EditIcon } from "@ordr-as/icons";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

export enum TabOptions {
  Options = "options",
  Settings = "settings",
}

type TabsProps = {
  onTabPress: (value: string | number) => void;
  selectedTab: string | number;
};

const Tabs = (props: TabsProps) => {
  const { selectedTab, onTabPress } = props;
  const { canEditMenu } = useSelectedLocation();

  if (!canEditMenu) {
    return (
      <TabMenu onTabPress={onTabPress} selectedKeys={[selectedTab]}>
        <Item key={TabOptions.Options} textValue={TabOptions.Options}>
          <a href={`#${TabOptions.Options}`} className="flex items-center">
            <AlcoholIcon className="mr-1" /> <span>Options</span>
          </a>
        </Item>
      </TabMenu>
    );
  }

  return (
    <TabMenu onTabPress={onTabPress} selectedKeys={[selectedTab]}>
      <Item key={TabOptions.Options} textValue={TabOptions.Options}>
        <a href={`#${TabOptions.Options}`} className="flex items-center">
          <AlcoholIcon className="mr-1" /> <span>Options</span>
        </a>
      </Item>
      <Item key={TabOptions.Settings} textValue={TabOptions.Settings}>
        <a href={`#${TabOptions.Settings}`} className="flex items-center">
          <EditIcon className="mr-1" /> <span>Settings</span>
        </a>
      </Item>
    </TabMenu>
  );
};

export default Tabs;
