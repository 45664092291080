import { Button, TextInput } from "@ordr-as/umami-react";
import { useCreateSection } from "features/sections/api/useCreateSection";
import { useState } from "react";

const NewSectionButton = () => {
  const [active, setActive] = useState(false);
  const [input, setInput] = useState("");
  const mutation = useCreateSection();

  const handleCreateSection = () => {
    const section = { name: input };

    mutation.mutate(section);
    setActive(false);
    setInput("");
  };

  if (!active) {
    return (
      <Button kind="secondary" onClick={() => setActive(true)}>
        Create new section
      </Button>
    );
  }

  return (
    <div className="flex gap-2">
      <TextInput aria-label="Create new section" placeholder="Name of section..." value={input} onChange={setInput} />
      <Button kind="secondary" onClick={() => handleCreateSection()}>
        Create
      </Button>
    </div>
  );
};

export default NewSectionButton;
