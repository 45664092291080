import { Button, Dialog, OverlayProvider } from "@ordr-as/umami-react";
import { TrashIcon } from "@ordr-as/icons";
import { useRemoveSection } from "features/sections/api/useRemoveSection";
import { useState } from "react";

type DeleteSectionButtonProps = { sectionId: string };

const DeleteSectionButton = (props: DeleteSectionButtonProps) => {
  const { sectionId } = props;
  const mutation = useRemoveSection(true);
  const [isOpen, setOpen] = useState(false);

  const handleRemove = () => {
    mutation.mutate(sectionId);
    setOpen(false);
  };

  return (
    <>
      <Button kind="secondary" onClick={() => setOpen(true)} className="text-orange-darkest border-orange-darkest">
        <TrashIcon />
        <div className="p-2" />
        Delete section
      </Button>

      <OverlayProvider>
        {isOpen && (
          <Dialog
            title="Remove section"
            actionLabel="Remove"
            onAction={handleRemove}
            secondaryActionLabel="Cancel"
            onSecondaryAction={() => setOpen(false)}
            onClose={() => setOpen(false)}
            className="w-2/4"
          >
            <div>
              <p>Do you want to remove the section?</p>
              <div className="p-2" />
            </div>
          </Dialog>
        )}
      </OverlayProvider>
    </>
  );
};

export default DeleteSectionButton;
