import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateProductWithSectionsDto } from "types/contracts/contracts";

export const useCreateNewProductFromProductLibrary = () => {
  const { post } = useFetch();
  const queryClient = useQueryClient();

  const { selectedLocation } = useSelectedLocation();

  const mutation = useMutation(
    (dto: CreateProductWithSectionsDto) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/product-from-product-library`;

      return post(url, dto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["productLibraryProducts"]);
        queryClient.invalidateQueries(["bulkProducts"]);
        queryClient.invalidateQueries(["products"]);
        queryClient.invalidateQueries(["sections"]);
      },
    },
  );

  return mutation;
};
