import { TextInput, Checkbox } from "@ordr-as/umami-react";
import { Dispatch, useState, useMemo } from "react";
import { LocationDto } from "types/contracts/contracts";
import { useToggleSelectedLocationId } from "../../hooks/useToggleSelectedLocationId";

type Props = {
  potentialPartners: LocationDto[];
  selectedLocationIds: number[];
  setSelectedLocationIds: Dispatch<React.SetStateAction<number[]>>;
};

const PotentialPartnerList = (props: Props) => {
  const { potentialPartners, selectedLocationIds, setSelectedLocationIds } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const filteredPotentialPartners = useMemo(
    () => potentialPartners.filter((location) => location.name && location.name.toLowerCase().includes(searchTerm.toLowerCase())),
    [searchTerm, potentialPartners],
  );

  return (
    <>
      <TextInput aria-label="Location filter" placeholder="Filter locations..." value={searchTerm} onChange={setSearchTerm} />
      <p>Locations available for sharing:</p>
      {filteredPotentialPartners.map((location) => (
        <PotentialPartnerListElement
          key={location.locationId}
          location={location}
          selectedLocationIds={selectedLocationIds}
          setSelectedLocationIds={setSelectedLocationIds}
        />
      ))}
    </>
  );
};

export default PotentialPartnerList;

type PotentialPartnerListElementProps = {
  location: LocationDto;
  selectedLocationIds: number[];
  setSelectedLocationIds: Dispatch<React.SetStateAction<number[]>>;
};

const PotentialPartnerListElement = (props: PotentialPartnerListElementProps) => {
  const { location, selectedLocationIds, setSelectedLocationIds } = props;

  const { toggleSelectedLocationId } = useToggleSelectedLocationId(setSelectedLocationIds);

  const isSelected = useMemo(
    () => selectedLocationIds.filter((selected) => selected === location.locationId).length > 0,
    [location.locationId, selectedLocationIds],
  );

  return (
    <label className="flex">
      <Checkbox aria-label="Selected for edit" isSelected={isSelected} onChange={() => toggleSelectedLocationId(location.locationId)} />
      <p>{location.name}</p>
    </label>
  );
};
