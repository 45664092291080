import { BulkProductDto, EposDisplayColorDto, ProductCategoryDto } from "types/contracts/contracts";

export const useFilterProducts = (eposDisplayColors: EposDisplayColorDto[], categories: ProductCategoryDto[]) => {
  const filterProducts = (products: BulkProductDto[], filter: string) => {
    const lowercaseFilter = filter.toLowerCase();

    const productsFilteredByName = products.filter((product) => product.name.toLowerCase().includes(lowercaseFilter));

    const productsFilteredBySections = products.filter((product) =>
      product.sections.some((section) => section.sectionName.toLowerCase().includes(lowercaseFilter)),
    );

    const productsFilteredByOptionGroup = products.filter((product) =>
      product.optionGroups.some((optionGroup) => optionGroup.name.toLowerCase().includes(lowercaseFilter)),
    );

    const productsFilteredBySizeName = products.filter((product) => product.sizes.some((size) => size.name?.toLowerCase().includes(lowercaseFilter)));

    const productsFilteredByTaxGroup = products.filter(
      (product) => product.taxGroupName?.toLowerCase().includes(lowercaseFilter) || (!product.taxGroupName && lowercaseFilter === "missing"),
    );

    const productsFilteredByCourseName = products.filter((product) => product.courseName?.toLowerCase().includes(lowercaseFilter));

    const colorsToFilterBy = eposDisplayColors.filter((x) => x.name.toLowerCase().includes(lowercaseFilter)).map((x) => x.hexCode);

    const productsFilteredByColors = products.filter((product) => {
      if (!product.eposDisplayColor) {
        return false;
      }

      return colorsToFilterBy.includes(product.eposDisplayColor);
    });

    const categoriesToFilterBy = categories.filter((x) => x.name.toLowerCase().includes(lowercaseFilter)).map((x) => x.categoryId);

    const productsFilteredByCategories = products.filter((product) => {
      if (!product.category) {
        return false;
      }

      return categoriesToFilterBy.includes(product.category);
    });

    const productsFilteredByPrinter = products.filter((product) => product.printerId?.toString().includes(lowercaseFilter));
    const productsFilteredBySecondaryPrinter = products.filter((product) => product.secondaryPrinterId?.toString().includes(lowercaseFilter));

    const combinedFilters = Array.from(
      new Set([
        ...productsFilteredByName,
        ...productsFilteredBySections,
        ...productsFilteredByOptionGroup,
        ...productsFilteredBySizeName,
        ...productsFilteredByTaxGroup,
        ...productsFilteredByPrinter,
        ...productsFilteredBySecondaryPrinter,
        ...productsFilteredByCourseName,
        ...productsFilteredByColors,
        ...productsFilteredByCategories,
      ]),
    );

    const productsFilteredByFilters = products.filter((product) => combinedFilters.includes(product));

    return productsFilteredByFilters;
  };

  return { filterProducts };
};
