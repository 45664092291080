import ControlledSwitch from "components/ControlledSwitch";
import { Control } from "react-hook-form";
import { SectionSettingsFormType } from "./formValidation";

type HideWhenSectionIsClosedProps = {
  control: Control<SectionSettingsFormType>;
  disabled?: boolean;
};

const HideFromMenuWhenClosed = (props: HideWhenSectionIsClosedProps) => {
  const { control, disabled } = props;

  return (
    <label className="flex justify-between items-center">
      <p>Hide from menu when outside of custom opening hours</p>
      <ControlledSwitch control={control} disabled={disabled} name={"hideFromMenuWhenClosed"} />
    </label>
  );
};

export default HideFromMenuWhenClosed;
