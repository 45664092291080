import { TextInput } from "@ordr-as/umami-react";
import { useFormContext } from "react-hook-form";
import { TaxGroupsFormType } from "../../types/types";

type EatInAccountReferenceProps = { index: number };

const EatInAccountReference = (props: EatInAccountReferenceProps) => {
  const { index } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<TaxGroupsFormType>();

  return (
    <TextInput
      aria-label="Eat in account reference"
      name={`taxGroups.${index}.eatInAccountReference`}
      register={register}
      invalidHelpText={errors.taxGroups?.[index]?.eatInAccountReference?.message}
      validationState={errors.taxGroups?.[index]?.eatInAccountReference ? "invalid" : undefined}
    />
  );
};

export default EatInAccountReference;
