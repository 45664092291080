import { useUpdateSectionSequence } from "features/sections/api/useUpdateSectionSequence";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import SectionListItem from "./SectionListItem";
import { MenuSectionDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Props = {
  sections: MenuSectionDto[];
};

const SectionListItems = (props: Props) => {
  const { sections } = props;
  const { canEditMenu } = useSelectedLocation();
  const mutation = useUpdateSectionSequence();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    mutation.mutate({
      sectionId: sections[result.source.index].sectionId,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
  };

  if (!canEditMenu) {
    return (
      <>
        {sections.map((section) => (
          <div key={section.sectionId}>
            <SectionListItem section={section} />
          </div>
        ))}
      </>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`sections-droppable`}>
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {sections.map((section, index) => (
              <Draggable key={section.sectionId} draggableId={section.sectionId} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                    <SectionListItem section={section} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SectionListItems;
