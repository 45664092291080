import { TextInput } from "@ordr-as/umami-react";
import { useFormContext } from "react-hook-form";
import { TaxGroupsFormType } from "../../types/types";

type Props = {
  index: number;
  disabled: boolean;
};

const Name = (props: Props) => {
  const { index, disabled } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<TaxGroupsFormType>();

  return (
    <TextInput
      aria-label="Name of tax group"
      register={register}
      name={`taxGroups.${index}.name`}
      invalidHelpText={errors?.taxGroups?.[index]?.name?.message}
      validationState={errors?.taxGroups?.[index]?.name ? "invalid" : undefined}
      isDisabled={disabled}
    />
  );
};

export default Name;
