import * as z from "zod";

export type AddProductLibraryProductFormType = z.infer<typeof addProductLibraryProductFormSchema>;

const addProductLibraryProductSizeFormSchema = z.object({
  name: z.string().nullish(),
  price: z.number().min(0, "Price must be more than 0"),
});

const requiredNameWhenMultipleSizes = (sizes: z.infer<typeof addProductLibraryProductSizeFormSchema>[], ctx: z.RefinementCtx) => {
  if (sizes.length < 2) {
    return;
  }

  sizes.forEach((size, index) => {
    if (size.name) {
      return;
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Size name is required when there is more than one size.",
      path: [`${index}.name`],
    });
  });
};

export const addProductLibraryProductFormSchema = z.object({
  sectionIds: z.array(z.string().uuid()),
  sizes: z.array(addProductLibraryProductSizeFormSchema).superRefine(requiredNameWhenMultipleSizes),
});
