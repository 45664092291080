import { useFetch } from "api/useFetch";
import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteMenu = () => {
  const { destroy } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (menuId: string) => {
      const deleteMenuUrl = `${config.menuBuilderUrl}/v1/menus/${menuId}`;

      return destroy(deleteMenuUrl);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["menus"]);
      },
    }
  );

  return mutation;
};
