import { useParams } from "react-router-dom";
import { permissionCalculator } from "types/contracts/permissionConstants";
import { useLocations } from "../api/useLocations";

export const useSelectedLocation = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const { data: locations } = useLocations();

  const selectedLocation = locations?.find((location) => location.locationId === Number(locationId));

  return {
    selectedLocation,
    canEditMenu: permissionCalculator.canEditMenu(selectedLocation),
    canToggleItems: permissionCalculator.canToggleItems(selectedLocation),
  };
};
