import { useParams } from "react-router-dom";
import { useSections } from "../api/useSections";

export const useSelectedSection = () => {
  const { sectionId } = useParams<{ sectionId: string }>();
  const { data: sections, isSuccess } = useSections();

  if (!isSuccess || !sectionId) {
    return { selectedSection: undefined };
  }

  const selectedSection = sections.find((section) => section.sectionId === sectionId);

  return { selectedSection };
};
