import { EposDisplayColorDto } from "types/contracts/contracts";
import Divider from "./Divider";

type Props = {
  eposDisplayColors: EposDisplayColorDto[];
  selectedColor: EposDisplayColorDto | undefined;
  setSelectedColor: (color: EposDisplayColorDto | undefined) => void;
};

const ColorSelectorMenu = (props: Props) => {
  const { eposDisplayColors, selectedColor, setSelectedColor } = props;

  const NoColorRow = () => {
    const isSelected = !selectedColor;
    return (
      <button
        type="button"
        className="flex items-center justify-between w-full h-[54px] px-4 hover:bg-purple-lightest"
        onClick={() => {
          setSelectedColor(undefined);
        }}
        data-id="select-epos-display-color-no-color"
      >
        <span className={`text-brand-black ${isSelected ? "font-semibold" : ""}`}>No colour</span>
        <div className="h-5 w-5 overflow-hidden rounded">
          <div className="flex items-center">
            <div className="h-5 w-5 border border-grey-dark rounded" />
          </div>
          <div className="w-[30px] h-[1px] -translate-y-[1px] translate-x-[1px] -rotate-45 origin-top-left bg-grey-dark" />
        </div>
      </button>
    );
  };

  const ColorPickerGrid = () => {
    return (
      <div className="grid grid-cols-3 gap-4 py-5 px-4">
        {eposDisplayColors.map((color) => (
          <button
            type="button"
            key={color.name}
            className="flex flex-col items-center gap-2 px-1 py-2 hover:bg-purple-lightest rounded"
            onClick={() => {
              setSelectedColor(color);
            }}
            data-id={`select-epos-display-color-${color.name.replace(/\s+/g, "-").toLowerCase()}`}
          >
            <div
              className={`h-[40px] w-[40px] border-4 rounded-lg ${color === selectedColor ? " border-brand-secondary" : "border-brand-white"}`}
              style={{ backgroundColor: color.hexCode }}
            />
            <span className={`text-sm text-brand-black text-center ${color === selectedColor ? "font-semibold" : ""}`}>{color.name}</span>
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="absolute top-full w-full h-[310px] mt-2 bg-brand-white shadow-lg z-10 overflow-y-scroll">
        <Divider />
        <NoColorRow />
        <Divider />
        <ColorPickerGrid />
      </div>
    </>
  );
};

export default ColorSelectorMenu;
