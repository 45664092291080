import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useSelectedOptionGroup } from "features/options/hooks/useSelectedOptionGroup";
import { useQuery } from "@tanstack/react-query";
import { ExistingProductSizeDto } from "types/contracts/contracts";

export const useAvailableProductsForRecommendationGroup = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const { selectedOptionGroup } = useSelectedOptionGroup();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/recommendation-groups/${selectedOptionGroup?.optionGroupId}/existing-products`;

  const availableProductForRecommendationGroupQuery = useQuery<ExistingProductSizeDto[], Error>(
    ["availableProductForRecommendationGroup", selectedLocation?.locationId, selectedOptionGroup?.optionGroupId],
    () => get(url),
    { enabled: !!selectedLocation?.locationId && !!selectedOptionGroup?.optionGroupId },
  );

  return availableProductForRecommendationGroupQuery;
};
