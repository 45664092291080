import config from "config";
import { useFetch } from "api/useFetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { ShareSectionDto } from "types/contracts/contracts";

export const useShareSectionWithPartners = () => {
  const { post } = useFetch();
  const { selectedMenu } = useSelectedMenu();
  const { selectedSection } = useSelectedSection();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (shareSectionDto: ShareSectionDto) => {
      const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${selectedSection?.sectionId}/micronetwork-partners`;

      return post(url, shareSectionDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["partnerLocationsForSection"]);
      },
    },
  );

  return mutation;
};
