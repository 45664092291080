import { Switch as HeadlessSwitch } from "@headlessui/react";
import React from "react";

type SwitchProps = {
  checked: boolean;
  onChange: (event: any) => void;
  label?: string;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  secondaryActive?: boolean;
  stopPropagation?: boolean;
};

const toggleColor = (checked: boolean, disabled: boolean | undefined, secondaryActive: boolean | undefined): string => {
  if (disabled) {
    return "bg-grey-medium";
  }

  if (secondaryActive === false) {
    return checked ? "bg-orange-light" : "bg-grey-medium";
  }

  return checked ? "bg-green-dark" : "bg-grey-medium";
};

export const Switch = (props: SwitchProps) => {
  const { checked, onChange, stopPropagation, label, ariaLabel, className, disabled, secondaryActive } = props;
  const backgroundColor = toggleColor(checked, disabled, secondaryActive);

  return (
    <HeadlessSwitch.Group as="div" className={`block ${className}`} aria-label={ariaLabel}>
      <div
        onClick={(event: React.MouseEvent) => {
          if (stopPropagation) {
            event.stopPropagation();
          }
          return !stopPropagation;
        }}
      >
        <HeadlessSwitch
          checked={checked}
          onChange={onChange}
          className={`${backgroundColor} relative inline-flex items-center h-5 rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          style={{ width: "44px" }}
          disabled={disabled}
        >
          <span
            className={`${
              checked ? "translate-x-5" : "translate-x-1"
            } inline-block w-4 h-4 transform bg-brand-white rounded-full transition-transform`}
          />
        </HeadlessSwitch>
        {label && <HeadlessSwitch.Label className="ml-4">{label}</HeadlessSwitch.Label>}
      </div>
    </HeadlessSwitch.Group>
  );
};
