import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateCourseDto } from "types/contracts/contracts";

export const useUpdateCourse = () => {
  const { selectedLocation } = useSelectedLocation();
  const { put } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (course: { courseId: string; updateCourseDto: UpdateCourseDto }) => {
      const createUrl = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/courses/${course.courseId}`;

      return put(createUrl, course.updateCourseDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["courses"]);
      },
    },
  );

  return mutation;
};
