import { useFetch } from "api/useFetch";
import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateProductDto } from "types/contracts/contracts";
import { useParams } from "react-router-dom";

export const useCreateNewProductFromProductList = () => {
  const { post } = useFetch();
  const queryClient = useQueryClient();
  const { locationId } = useParams<{ locationId: string }>();

  const mutation = useMutation(
    (product: CreateProductDto) => {
      const createProductUrl = `${config.menuBuilderUrl}/v1/locations/${locationId}/products`;

      return post(createProductUrl, product);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["products"]);
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
