import { FC } from "react";

interface MenuSelectorProps {
  selectedLocation: { menuDisabled: boolean };
  selectedMenu: { menuId: string };
  menus: { menuId: string; menuName: string }[];
  handleSelectEdit: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const MenuSelector: FC<MenuSelectorProps> = ({ selectedLocation, selectedMenu, menus, handleSelectEdit }) => {
  return (
    <div className="flex flex-col gap-4">
      {selectedLocation.menuDisabled && <span className="bg-yellow-lightest p-4">Your menu is disabled. Please contact support to reenable.</span>}
      <div className="flex-col gap-2 mx-4 pb-2">
        You&apos;re now editing:{" "}
        <select className="border border-grey-medium rounded py-3 px-3" onChange={(e) => handleSelectEdit(e)} defaultValue={selectedMenu.menuId}>
          <option value={""} disabled>
            No menu selected
          </option>
          {menus.map((menu) => (
            <option key={menu.menuId} value={menu.menuId}>
              {menu.menuName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default MenuSelector;
