import { ProductDetailsDto } from "types/contracts/contracts";

type NameProps = {
  product?: ProductDetailsDto;
};

const ProductLibraryName = (props: NameProps) => {
  const { product } = props;

  if (!product?.productLibraryProductName) {
    return <></>;
  }

  return (
    <span className="bg-yellow-lightest p-4">
      {product.productLibraryProductName} is a Product Library product from the supplier {product.productLibrarySupplierName}.
    </span>
  );
};

export default ProductLibraryName;
