import { CustomizationGroupDto } from "types/contracts/contracts";
import { CustomizationGroupFormType } from "../formValidation/customizationGroupForm";

export const defaultCustomizationGroupValues = (dto?: CustomizationGroupDto): CustomizationGroupFormType => {
  return {
    name: dto?.name || "",
    displayTextInMenu: dto?.displayTextInMenu || "",
    selectionType: (dto?.selectionType as "SINGLE_CHOICE" | "MULTIPLE_CHOICE" | "QUANTITY") || "SINGLE_CHOICE",
    required: dto?.required || false,
    useSelectionLimit: dto?.useSelectionLimit || false,
    minimumSelectionLimit: dto?.minimumSelectionLimit || 0,
    maximumSelectionLimit: dto?.maximumSelectionLimit || 0,
  };
};
