import { Item, Select } from "@ordr-as/umami-react/lib/form";
import Spinner from "components/Spinner";
import { useLookups } from "features/lookups/api/useLookups";

type SelectCategoryProps = {
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const SelectCategory = (props: SelectCategoryProps) => {
  const { setSelectedCategory } = props;

  const { isSuccess, data: lookups } = useLookups();

  if (!isSuccess) {
    return <Spinner />;
  }

  const categories = lookups.productCategories;

  return (
    <div className="flex max-w-[400px] w-full pb-2">
      <Select fullWidth label="Category" placeholder="Select..." onSelectionChange={(key) => setSelectedCategory(key as string)}>
        {categories.map((category) => (
          <Item key={category.categoryId}>{category.name}</Item>
        ))}
      </Select>
    </div>
  );
};

export default SelectCategory;
