import { Button } from "@ordr-as/umami-react";
import { useState } from "react";

type AddProductButtonProps = {
  children: React.ReactNode;
};

const AddProductButton = (props: AddProductButtonProps) => {
  const { children } = props;
  const [active, setActive] = useState(false);

  if (!active) {
    return (
      <Button kind="secondary" onClick={() => setActive(true)}>
        Add product
      </Button>
    );
  }

  return <div>{children}</div>;
};

export default AddProductButton;
