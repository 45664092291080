import { TextInput } from "@ordr-as/umami-react";
import { ProductFormType } from "features/productForm/formValidation";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  editable: boolean;
};

const Name = (props: Props) => {
  const { editable } = props;
  const {
    register,
    formState: { errors },
    setFocus,
  } = useFormContext<ProductFormType>();

  useEffect(() => {
    if (editable) {
      setFocus("name");
    }
  }, [editable, setFocus]);

  return (
    <TextInput
      label="Product name"
      name="name"
      register={register}
      invalidHelpText={errors.name?.message}
      validationState={errors.name ? "invalid" : undefined}
      isDisabled={!editable}
      className="w-3/4"
    />
  );
};

export default Name;
