import { CardCompact, CardHeader } from "components/Card";
import { useState } from "react";
import { RecommendationGroupDto } from "types/contracts/contracts";
import Tabs, { TabOptions } from "../OptionGroup/Tabs";
import RecommendationGroupOptions from "./RecommendationGroupOptions";
import RecommendationGroupSettings from "./RecommendationGroupSettings";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

type Props = {
  recommendationGroup: RecommendationGroupDto;
};

const RecommendationGroup = (props: Props) => {
  const { recommendationGroup } = props;
  const [tab, setTab] = useState<string | number>(TabOptions.Options);
  const { isPhone } = useScreenSize();

  const classNames = isPhone ? "w-full" : "w-1/2";

  return (
    <CardCompact className={classNames}>
      <CardHeader>
        <h4>{recommendationGroup.name}</h4>
        <Tabs selectedTab={tab} onTabPress={setTab} />
      </CardHeader>
      {tab === TabOptions.Options && <RecommendationGroupOptions recommendationGroup={recommendationGroup} />}
      {tab === TabOptions.Settings && (
        <RecommendationGroupSettings optionGroupId={recommendationGroup.optionGroupId} recommendationGroup={recommendationGroup} />
      )}
    </CardCompact>
  );
};

export default RecommendationGroup;
