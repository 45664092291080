import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { CloudinarySignatureDto } from "types/contracts/contracts";

export const useCloudinarySignature = () => {
  const { get } = useFetch();

  const url = `${config.menuBuilderUrl}/v1/cloudinary/signature?folder=product-images`;

  const cloudinarySignatureQuery = useQuery<CloudinarySignatureDto, Error>(["cloudinarySignature"], () => get(url));

  return cloudinarySignatureQuery;
};
