import Spinner from "components/Spinner";
import { useMenus } from "features/menus/api/useMenus";
import { useSelectActiveMenuForEpos } from "features/menus/api/useSelectActiveMenuForEpos";
import { useSelectActiveMenuForGuestApp } from "features/menus/api/useSelectActiveMenuForGuestApp";

const SelectMenu = () => {
  const guestAppMutation = useSelectActiveMenuForGuestApp();
  const eposMutation = useSelectActiveMenuForEpos();
  const menusQuery = useMenus();

  if (!menusQuery.isSuccess) {
    return <Spinner />;
  }

  const guestAppMenuId = menusQuery.data.find((menu) => menu.activeInGuestApp)?.menuId;
  const eposMenuId = menusQuery.data.find((menu) => menu.activeInEpos)?.menuId;

  const handleSelectGuestApp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    guestAppMutation.mutate(e.target.value);
  };
  const handleSelectEpos = (e: React.ChangeEvent<HTMLSelectElement>) => {
    eposMutation.mutate(e.target.value);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 mx-4 pb-2">
        Active menu for guest app:{" "}
        <select className="border border-grey-medium rounded py-3 px-3" onChange={(e) => handleSelectGuestApp(e)} defaultValue={guestAppMenuId}>
          <option value={""} disabled>
            No menu selected
          </option>
          {menusQuery.data.map((menu) => (
            <option key={menu.menuId} value={menu.menuId}>
              {menu.menuName}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-col gap-2 mx-4 pb-2">
        Active menu for ePOS:{" "}
        <select className="border border-grey-medium rounded py-3 px-3" onChange={(e) => handleSelectEpos(e)} defaultValue={eposMenuId}>
          <option value={""} disabled>
            No menu selected
          </option>
          {menusQuery.data.map((menu) => (
            <option key={menu.menuId} value={menu.menuId}>
              {menu.menuName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectMenu;
