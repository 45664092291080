import { OverlayProvider, Button, Dialog } from "@ordr-as/umami-react";
import { useSelectedProducts } from "features/bulkEditProducts/hooks/useSelectedProducts";
import { useState } from "react";
import { useBulkUpdatePrinters } from "../../api/useBulkUpdatePrinters";
import DisplaySelectedProducts from "./DisplaySelectedProducts";
import SelectPrinter from "./SelectPrinter";
import { useBulkUpdateSecondaryPrinters } from "features/bulkEditProducts/api/useBulkUpdateSecondaryPrinters";
import SelectSecondaryPrinter from "./SelectSecondaryPrinter";

const EditPrinters = () => {
  const { selectedProducts } = useSelectedProducts();

  const [isOpen, setOpen] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState<number | undefined>();
  const [selectedSecondaryPrinter, setSelectedSecondaryPrinter] = useState<number | undefined>();
  const bulkUpdatePrintersMutation = useBulkUpdatePrinters();
  const bulkUpdateSecondaryPrintersMutation = useBulkUpdateSecondaryPrinters();

  const handleSave = () => {
    bulkUpdatePrintersMutation.mutate(
      {
        printerId: selectedPrinter,
        productIds: selectedProducts.map((x) => x.productId),
      },
      {
        onSettled: () => {
          setSelectedPrinter(undefined);
          setOpen(false);
        },
      },
    );

    bulkUpdateSecondaryPrintersMutation.mutate(
      {
        secondaryPrinterId: selectedSecondaryPrinter,
        productIds: selectedProducts.map((x) => x.productId),
      },
      {
        onSettled: () => {
          setSelectedSecondaryPrinter(undefined);
          setOpen(false);
        },
      },
    );
  };

  const handleOnClose = () => {
    setSelectedPrinter(undefined);
    setSelectedSecondaryPrinter(undefined);
    setOpen(false);
  };

  return (
    <OverlayProvider className="px-1">
      <Button kind="secondary" className="rounded-3xl h-[36px] text-sm" onClick={() => setOpen(true)} isDisabled={!selectedProducts.length}>
        Select printer
      </Button>
      {isOpen && (
        <Dialog title="Select printer" actionLabel="Save" onAction={handleSave} onClose={handleOnClose}>
          <div className="flex gap-4 w-full pb-2">
            <SelectPrinter setSelectedPrinter={setSelectedPrinter} />
            <SelectSecondaryPrinter setSelectedSecondaryPrinter={setSelectedSecondaryPrinter} />
          </div>
          <DisplaySelectedProducts />
          <div className="pb-8" />
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default EditPrinters;
