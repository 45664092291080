import { TextInput } from "@ordr-as/umami-react";
import { useFormContext } from "react-hook-form";
import { TaxGroupsFormType } from "../../types/types";

type TakeawayAccountReferenceProps = { index: number };

const TakeawayAccountReference = (props: TakeawayAccountReferenceProps) => {
  const { index } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<TaxGroupsFormType>();

  return (
    <TextInput
      aria-label="Takeaway account reference"
      name={`taxGroups.${index}.takeawayAccountReference`}
      register={register}
      invalidHelpText={errors.taxGroups?.[index]?.takeawayAccountReference?.message}
      validationState={errors.taxGroups?.[index]?.takeawayAccountReference ? "invalid" : undefined}
    />
  );
};

export default TakeawayAccountReference;
