import ControlledSwitch from "components/ControlledSwitch";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { UseFormReturn } from "react-hook-form";
import MaximumSelectionLimit from "./MaximumSelectionLimit";
import MinimumSelectionLimit from "./MinimumSelectionLimit";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

type MultipleChoiceElementsProps = {
  methods: UseFormReturn<CustomizationGroupFormType, object>;
};

const MultipleChoiceElements = (props: MultipleChoiceElementsProps) => {
  const { methods } = props;
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = methods;

  const displayMinMax = watch("useSelectionLimit");
  const { isPhone } = useScreenSize();

  return (
    <div className="flex flex-col gap-4">
      <p className="text-xs text-grey-dark text-bold">ARE THERE ANY LIMITS IN HOW MANY OPTIONS IN TOTAL THE GUEST CAN CHOOSE?</p>
      <ControlledSwitch control={control} name={"useSelectionLimit"} />
      {displayMinMax && (
        <div className={`flex justify-between gap-2 ${isPhone && "flex-col"}`}>
          <MinimumSelectionLimit register={register} errors={errors} />
          <MaximumSelectionLimit register={register} errors={errors} />
        </div>
      )}
    </div>
  );
};

export default MultipleChoiceElements;
