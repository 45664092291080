import { useMultipleSelection, useSelect } from "downshift";
import { useEffect, useMemo } from "react";
import { useFormContext, useController } from "react-hook-form";
import { AllergenDto } from "types/contracts/contracts";
import { ProductFormType } from "../../../../../formValidation";
import { AddIcon, CloseIcon } from "@ordr-as/icons";

type Props = {
  allergens: AllergenDto[];
  disabled?: boolean;
};

const MultiSelectAllergens = (props: Props) => {
  const { allergens, disabled } = props;

  const { control } = useFormContext<ProductFormType>();

  const { field } = useController<ProductFormType>({ name: "allergenIds", control });

  const updateFieldValue = field.onChange;

  const initialItems = () => {
    const initialAllergenIds = (field.value || []) as string[];

    const initialAllergens = allergens.filter((allergen) => initialAllergenIds.includes(allergen.allergenId));
    return initialAllergens;
  };

  const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } = useMultipleSelection({
    initialSelectedItems: initialItems(),
  });

  const selectedAllergenIds = useMemo(() => {
    const selectedAllergens = selectedItems as AllergenDto[];

    const allergenIds = selectedAllergens.map((allergen) => allergen.allergenId);

    return allergenIds;
  }, [selectedItems]);

  const filteredItems = useMemo(() => allergens.filter((allergen) => selectedItems.indexOf(allergen) < 0), [allergens, selectedItems]);

  useEffect(() => {
    updateFieldValue(selectedAllergenIds);
  }, [selectedAllergenIds, updateFieldValue]);

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items: filteredItems,
    itemToString: (item) => (item ? item.name : ""),
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  const selectedItemStyles = "flex items-center gap-1 mr-1 mb-1 p-2 bg-grey-lightest rounded cursor-pointer text-sm text-brand-secondary";

  return (
    <div>
      {selectedItems.length > 0 ? (
        <div className="flex flex-wrap mb-2">
          {selectedItems.map((selectedItem, index) => (
            <span
              className={selectedItemStyles}
              key={`selected-item-${index}`}
              {...getSelectedItemProps({ selectedItem, index })}
              onClick={(e) => {
                e.stopPropagation();
                removeSelectedItem(selectedItem);
              }}
            >
              {(selectedItem as AllergenDto).name}
              <CloseIcon />
            </span>
          ))}
        </div>
      ) : (
        ""
      )}
      {!disabled && (
        <>
          <button
            type="button"
            {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}
            className="umami-button umami-medium-button umami-secondary-button gap-2"
          >
            <AddIcon />
            Add allergens
          </button>

          <ul {...getMenuProps()} className="bg-brand-white umami-options-list w-[160px]">
            {isOpen &&
              filteredItems.map((item, index) => (
                <li
                  style={highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}}
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
                >
                  {item.name}
                </li>
              ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default MultiSelectAllergens;
