import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteRecommendationGroup = () => {
  const { selectedLocation } = useSelectedLocation();
  const { destroy } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (optionGroupId: string) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/recommendation-groups/${optionGroupId}`;

      return destroy(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["optionGroups"]);
      },
    }
  );

  return mutation;
};
