import ControlledSwitch from "components/ControlledSwitch";
import { Control } from "react-hook-form";
import { SectionSettingsFormType } from "./formValidation";

type EposOnlyProps = {
  control: Control<SectionSettingsFormType>;
  disabled?: boolean;
};

const EposOnly = (props: EposOnlyProps) => {
  const { control, disabled } = props;

  return (
    <label className="flex justify-between items-center">
      <p>Only available on ePOS</p>
      <ControlledSwitch control={control} disabled={disabled} name={"eposOnly"} />
    </label>
  );
};

export default EposOnly;
