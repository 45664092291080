import { UseMultipleSelectionReturnValue, useSelect } from "downshift";
import { AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { useMemo } from "react";
import { UseFieldArrayAppend } from "react-hook-form";
import { ProductLibraryProductSizeDto } from "types/contracts/contracts";

type SelectSizesProps = {
  sizes: ProductLibraryProductSizeDto[];
  multipleSelect: UseMultipleSelectionReturnValue<ProductLibraryProductSizeDto>;
  append: UseFieldArrayAppend<AddProductLibraryProductFormType>;
};

const SelectSizes = (props: SelectSizesProps) => {
  const { sizes, multipleSelect, append } = props;
  const { selectedItems, addSelectedItem, getDropdownProps } = multipleSelect;

  const filteredItems = useMemo(() => sizes.filter((size) => selectedItems.indexOf(size) < 0), [sizes, selectedItems]);

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items: filteredItems,
    itemToString: (item) => (item ? item.name : ""),
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: false, // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            append({ name: selectedItem.name, price: 0 });
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <>
      <button
        type="button"
        {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}
        className="umami-button umami-medium-button umami-secondary-button w-1/2 mt-4"
      >
        Add sizes
      </button>

      <ul {...getMenuProps()} className="bg-brand-white umami-options-list w-1/2">
        {isOpen &&
          filteredItems.map((item, index) => (
            <li style={highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}} key={`${item}${index}`} {...getItemProps({ item, index })}>
              {item.name}
            </li>
          ))}
      </ul>
    </>
  );
};

export default SelectSizes;
