import { Link } from "react-router-dom";
import { useProductLibrarySearch } from "../api/useProductLibrarySearch";

type ProductLibrarySearchResultsProps = {
  searchTerm: string;
};

const ProductLibrarySearchResults = (props: ProductLibrarySearchResultsProps) => {
  const { searchTerm } = props;

  const { data: searchResults, isSuccess } = useProductLibrarySearch(searchTerm);

  if (!isSuccess) {
    return <></>;
  }

  return (
    <ul>
      {searchResults.map((product) => (
        <li className="mt-2" key={product.productLibraryId}>
          <Link to={`suppliers/${product.supplierId}/products/${product.productLibraryId}`} className="text-brand-primary underline">
            {product.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default ProductLibrarySearchResults;
