import { Switch } from "components/Switch";
import { useState } from "react";
import { LocationDto } from "types/contracts/contracts";
import { useRequireTaxGroup } from "../api/useRequireTaxGroup";
import SettingsCard from "./SettingsCard";

type Props = { location: LocationDto };

const RequireTaxGroup = (props: Props) => {
  const { location } = props;
  const [checked, setChecked] = useState(location.requireTaxGroup);
  const updateRequireTaxGroup = useRequireTaxGroup();

  const handleToggle = () => {
    updateRequireTaxGroup(!checked);
    setChecked((prevState) => !prevState);
  };

  return (
    <SettingsCard title="Require tax group for products (available for admin only)">
      <div className="flex flex-col gap-2">
        <p>Requiring tax groups on all products is useful for locations using accounting integration.</p>
        <Switch checked={checked} onChange={handleToggle} aria-label={"Toggle require tax group"} />
      </div>
    </SettingsCard>
  );
};

export default RequireTaxGroup;
