import { CardCompact, CardHeader } from "components/Card";
import { useEffect, useState } from "react";
import Tabs, { TabOptions } from "../../OptionGroup/Tabs";
import CustomizationGroupSettings from "./CustomizationGroupSettings";
import CustomizationGroupOptions from "./CustomizationGroupOptions";
import { CustomizationGroupDto } from "types/contracts/contracts";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

type Props = {
  customizationGroup: CustomizationGroupDto;
};

const CustomizationGroup = (props: Props) => {
  const { customizationGroup } = props;
  const [tab, setTab] = useState<string | number>(TabOptions.Options);
  const { isPhone } = useScreenSize();

  useEffect(() => {
    setTab(TabOptions.Options);
  }, [customizationGroup]);

  return (
    <CardCompact className={`${isPhone && "w-full"}`}>
      <CardHeader>
        <h4>{customizationGroup.name}</h4>
        <Tabs selectedTab={tab} onTabPress={setTab} />
      </CardHeader>
      {tab === TabOptions.Options && <CustomizationGroupOptions customizationGroup={customizationGroup} />}
      {tab === TabOptions.Settings && <CustomizationGroupSettings customizationGroup={customizationGroup} />}
    </CardCompact>
  );
};

export default CustomizationGroup;
