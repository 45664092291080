import Options from "features/options/components/Options";

const OptionsPage = () => {
  return (
    <>
      <Options />
    </>
  );
};

export default OptionsPage;
