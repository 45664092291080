import ControlledSwitch from "components/ControlledSwitch";
import { Control, UseFormRegister, useWatch } from "react-hook-form";
import { SectionSettingsFormType } from "../formValidation";

type OpeningHourProps = {
  register: UseFormRegister<SectionSettingsFormType>;
  control: Control<SectionSettingsFormType>;
  day: number;
  index: number;
  disabled?: boolean;
};

const daysMap: Record<number, string> = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

const OpeningHour = (props: OpeningHourProps) => {
  const { register, control, day, index, disabled } = props;

  const open = useWatch({ name: `openingHours.${index}.open`, control: control });

  return (
    <>
      <div>
        <span>{daysMap[day]}</span>
      </div>
      <div className="flex justify-around items-center">
        {open ? (
          <>
            <input
              size={5}
              placeholder="HH:MM"
              aria-label="Start time"
              className="border border-grey-medium rounded p-3"
              {...register(`openingHours.${index}.startTime`, { pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/ })}
              disabled={disabled}
            />
            <span>-</span>
            <input
              size={5}
              placeholder="HH:MM"
              aria-label="End time"
              className="border border-grey-medium rounded py-3 px-3"
              {...register(`openingHours.${index}.endTime`, { pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/ })}
              disabled={disabled}
            />
          </>
        ) : (
          <p className="text-orange-darkest font-bold p-3">Closed</p>
        )}
      </div>
      <div className="flex justify-center items-center">
        <ControlledSwitch control={control} disabled={disabled} name={`openingHours.${index}.open`} />
      </div>
    </>
  );
};

export default OpeningHour;
