import { Header } from "@ordr-as/umami-react";
import Hamburger from "features/header/components/Hamburger";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import CreateProductFromMenu from "features/products/components/Create/CreateProductFromMenu";

const CreateProductFromMenuPage = () => {
  const { selectedLocation } = useSelectedLocation();

  return (
    <>
      <Header>
        <p>{selectedLocation?.name}</p>
        <Hamburger />
      </Header>
      <div className="flex justify-center p-4">
        <CreateProductFromMenu />
      </div>
    </>
  );
};

export default CreateProductFromMenuPage;
