import { useState } from "react";
import { Switch } from "components/Switch";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useToggleProductActive } from "features/products/api/useToggleProductActive";
import Spinner from "components/Spinner";
import { Link } from "react-router-dom";
import { PartnerLabel } from "features/sections/components/SectionList/SectionLabel";
import { ProductOverviewDto } from "types/contracts/contracts";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";

type Props = {
  product: ProductOverviewDto;
};

const ProductListItem = (props: Props) => {
  const { product } = props;
  const [toggleProduct, setToggleProduct] = useState(product);
  const toggleProductActiveMutation = useToggleProductActive();

  const { selectedLocation, canToggleItems } = useSelectedLocation();
  const { selectedSection } = useSelectedSection();

  if (!selectedLocation) {
    return <Spinner />;
  }

  const handleToggleEnable = () => {
    if (!product.canEditActive) {
      return;
    }

    toggleProductActiveMutation.mutate({
      productId: product.productId,
      shouldBeActive: !toggleProduct.active,
    });

    if (toggleProduct.isOwner) {
      setToggleProduct((prevState) => ({
        ...prevState,
        active: !toggleProduct.active,
        activeOnOwnerLocation: !toggleProduct.active,
      }));

      return;
    }

    setToggleProduct((prevState) => ({
      ...prevState,
      active: !toggleProduct.active,
    }));
  };

  const umamiStyles = "flex justify-between items-center bg-brand-white";
  const borderStyle = "rounded-none border-b-[1px] border-b-grey-lightest";
  const disabledStyle = !product.active ? "local-section-disabled" : "";
  const listElementStyle = `${umamiStyles} ${borderStyle} ${disabledStyle}`;

  const prices = product.sizes.filter((x) => x.active).map((x) => x.price);
  const minPrice = Math.min(...prices);
  const activeSizeCount = product.sizes.filter((x) => x.active).length;
  const openPriceCount = product.sizes.filter((x) => x.allowOpenPrice).length;
  const sizeCount = product.sizes.length;

  const eposDisplayColor = product?.eposDisplayColor ? product.eposDisplayColor : selectedSection?.eposDisplayColor;

  return (
    <li className={listElementStyle}>
      <Link to={`products/${product.productId}`} className="flex flex-grow">
        <div className="left flex flex-col px-2 py-3 border-l-[12px] border-l-transparent" style={{ borderLeftColor: eposDisplayColor }}>
          <p>{product.name}</p>

          <p className="text-sm">
            {activeSizeCount > 1 && (
              <>
                From&nbsp;
                <span>{minPrice}</span>&nbsp;
                <span>{selectedLocation.currency}</span>
                {activeSizeCount !== sizeCount && (
                  <span>
                    .&nbsp;{activeSizeCount} of {sizeCount} sizes active.
                  </span>
                )}
                {openPriceCount > 0 && <> Open price allowed.</>}
              </>
            )}
            {activeSizeCount === 1 && sizeCount === 1 && (
              <>
                <span>{minPrice}</span>&nbsp;
                <span>{selectedLocation.currency}</span>
                {openPriceCount > 0 && <> Open price allowed.</>}
              </>
            )}
            {activeSizeCount === 1 && sizeCount > 1 && (
              <>
                <span>{minPrice}</span>&nbsp;
                <span>{selectedLocation.currency}</span>.&nbsp;
                <span>
                  {activeSizeCount} of {sizeCount} sizes active.
                </span>
                {openPriceCount > 0 && <> Open price allowed.</>}
              </>
            )}
            {activeSizeCount === 0 && (
              <>
                0 of {sizeCount} sizes active. {openPriceCount > 0 && <>Open price allowed.</>}
              </>
            )}
          </p>
        </div>
      </Link>

      <EposLabel isEposOnly={product.eposOnly} />

      <PartnerLabel isPartner={!product.isOwner} ownerLocationName={product.ownerLocationName} />
      <div className="right flex items-center pl-2">
        <Switch
          className="pl-2"
          checked={toggleProduct.active}
          onChange={handleToggleEnable}
          aria-label={"Toggle enabled"}
          disabled={!toggleProduct.canEditActive || !canToggleItems}
          secondaryActive={toggleProduct.activeOnOwnerLocation}
        />
      </div>
    </li>
  );
};

export default ProductListItem;

type EposLabelProps = { isEposOnly: boolean };

export const EposLabel = (props: EposLabelProps) => {
  const { isEposOnly } = props;
  const color = "bg-grey-medium";

  return <SectionLabel display={isEposOnly} text="EPOS" classNames={color} />;
};

type SectionLabelProps = {
  display: boolean;
  text?: string;
  classNames: string;
};

const SectionLabel = (props: SectionLabelProps) => {
  const { display, text, classNames } = props;

  if (!display || !text) {
    return null;
  }

  const labelStyles = `text-xs font-semibold flex items-center rounded px-2 h-5 uppercase text-brand-black`;
  const styles = `${labelStyles} ${classNames}`;

  return <p className={styles}>{text}</p>;
};
