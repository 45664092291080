import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import config from "../../../config";
import { useFetch } from "../../../api/useFetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BulkAddOptionGroupDto } from "types/contracts/contracts";

export const useBulkAddOptionGroup = () => {
  const { post } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (vars: { optionGroupId: string; productIds: string[] }) => {
      const { optionGroupId, productIds } = vars;
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/products/bulk-add-option-group`;

      const updateDto: BulkAddOptionGroupDto = {
        optionGroupId,
        productIds,
      };

      return post(url, updateDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
