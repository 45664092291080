import { useController, useFormContext } from "react-hook-form";
import { ProductFormType } from "../../../formValidation";
import { useLocations } from "features/locations/api/useLocations";
import { useMultipleSelection, useSelect } from "downshift";
import { useEffect, useMemo } from "react";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { LocationDto } from "types/contracts/contracts";

const CentrallyManagedChildProductLocations = () => {
  const { control } = useFormContext<ProductFormType>();
  const { data: locations } = useLocations();
  const { selectedLocation } = useSelectedLocation();

  const { field } = useController<ProductFormType>({
    name: "centrallyManagedChildProductLocations",
    control,
  });
  const updateFieldValue = field.onChange;

  const availableLocations = useMemo(
    () => locations?.filter((x) => x.locationId !== selectedLocation?.locationId) || [],
    [locations, selectedLocation],
  );

  const initialItems = () => {
    const initialLocationIds = (field.value || []) as number[];

    const initialLocations = availableLocations.filter((x) => initialLocationIds.includes(x.locationId));
    return initialLocations;
  };

  const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } = useMultipleSelection({
    initialSelectedItems: initialItems(),
  });

  const selectedLocationIds = useMemo(() => {
    const selectedLocations = selectedItems as LocationDto[];

    const locationIds = selectedLocations.map((location) => location.locationId);

    return locationIds;
  }, [selectedItems]);

  const filteredItems = useMemo(
    () => availableLocations.filter((location) => selectedItems.indexOf(location) < 0),
    [availableLocations, selectedItems],
  );

  useEffect(() => {
    updateFieldValue(selectedLocationIds);
  }, [selectedLocationIds, updateFieldValue]);

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    items: filteredItems || [],
    itemToString: (item) => (item ? item.name : ""),
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
  });

  const selectedItemStyles = "mr-1 p-1 bg-grey-lightest rounded cursor-pointer";

  return (
    <div className="border border-grey-medium rounded p-4 flex-col gap-1">
      <h4>Centrally managed product</h4>
      <p>Specify where this centrally managed product should be available.</p>

      {selectedItems.length > 0 && (
        <div className="mt-4">
          <h5>Locations:</h5>
          <div className="flex flex-wrap mb-4">
            {selectedItems.map((selectedItem, index) => (
              <span
                className={selectedItemStyles}
                key={`selected-item-${index}`}
                {...getSelectedItemProps({ selectedItem, index })}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedItem(selectedItem);
                }}
              >
                {(selectedItem as LocationDto).name}
              </span>
            ))}
          </div>
        </div>
      )}

      <button
        type="button"
        {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}
        className="umami-button umami-medium-button umami-secondary-button w-1/5 mt-4"
      >
        Add location
      </button>

      <ul {...getMenuProps()} className="bg-brand-white umami-options-list w-1/5 mt-2">
        {isOpen &&
          filteredItems?.map((item, index) => (
            <li style={highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}} key={`${item}${index}`} {...getItemProps({ item, index })}>
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default CentrallyManagedChildProductLocations;
