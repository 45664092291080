import { useSelectedSupplier } from "features/productLibrary/hooks/useSelectedSupplier";
import { Link, useParams } from "react-router-dom";
import ProductLibraryProducts from "features/productLibrary/components/ProductLibraryProducts";
import AppHeader from "features/header/components/AppHeader";
import { Button, OverlayProvider } from "@ordr-as/umami-react";
import { useProductLibraryProductById } from "features/productLibrary/hooks/useProductLibraryProductById";
import AddProductLibraryDialogProduct from "features/productLibrary/components/AddProductLibraryProduct/AddProductLibraryProductDialog";

const SupplierPage = () => {
  const { locationId, plProductId } = useParams<{
    locationId: string;
    supplierId: string;
    plProductId: string;
  }>();
  const { selectedSupplier } = useSelectedSupplier();
  const selectedProduct = useProductLibraryProductById(plProductId);

  return (
    <>
      <AppHeader />
      <Button kind="secondary" className="mb-4 mx-4">
        <Link to={`/locations/${locationId}/product-library`}>Back</Link>
      </Button>
      <h3 className="px-4">{selectedSupplier?.supplierName}</h3>
      <ProductLibraryProducts />
      {selectedProduct && (
        <OverlayProvider>
          <AddProductLibraryDialogProduct product={selectedProduct} />
        </OverlayProvider>
      )}
    </>
  );
};

export default SupplierPage;
