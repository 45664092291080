import Tabs from "features/sections/components/Section/Tabs";
import { useShareSectionWithPartners } from "features/micronetworks/api/useShareSectionWithPartners";
import { Dispatch, SetStateAction, useState } from "react";
import SharedWithList from "./SharedWithList";
import { usePotentialAndExistingPartners } from "../../hooks/usePotentialAndExistingPartners";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { CardBody } from "components/Card";
import PartnerShareSectionTab from "./PartnerShareSectionTab";
import ShareDialog from "./ShareDialog";
import PotentialPartnerList from "./PotentialPartnerList";
import Spinner from "components/Spinner";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";

type ShareSectionTabProps = {
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
};

const ShareSectionTab = (props: ShareSectionTabProps) => {
  const { tab, setTab } = props;
  const { selectedMenu } = useSelectedMenu();
  const { selectedSection } = useSelectedSection();
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([]);
  const shareSectionWithPartnersMutation = useShareSectionWithPartners();

  const { partnerLocations, locationsWithOutPartners } = usePotentialAndExistingPartners();

  if (!selectedSection || !partnerLocations) {
    return <Spinner />;
  }
  const handleShare = (useTakeawayVat: boolean, allowActiveInactiveOverrideOnSectionProducts: boolean) => {
    if (!selectedMenu) {
      return;
    }

    shareSectionWithPartnersMutation.mutate(
      {
        partnerLocationIds: selectedLocationIds,
        useTakeawayVat,
        allowActiveInactiveOverrideOnSectionProducts,
      },
      {
        onSuccess: () => {
          setSelectedLocationIds([]);
        },
      }
    );
  };

  if (!selectedSection.sectionCanBeShared) {
    if (selectedSection.isMicronetwork) {
      return <PartnerShareSectionTab selectedSection={selectedSection} tab={tab} setTab={setTab} />;
    }
    return <>This section cannot be shared</>;
  }

  return (
    <CardBody>
      <Tabs onTabPress={setTab} selectedTab={tab} />

      <SharedWithList partnerLocations={partnerLocations} />
      <ShareDialog section={selectedSection} selectedLocationIds={selectedLocationIds} handleShare={handleShare} />
      <PotentialPartnerList
        potentialPartners={locationsWithOutPartners}
        selectedLocationIds={selectedLocationIds}
        setSelectedLocationIds={setSelectedLocationIds}
      />
    </CardBody>
  );
};

export default ShareSectionTab;
