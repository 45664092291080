import { AdvancedImage, lazyload, responsive } from "@cloudinary/react";
import { CheckmarkIcon } from "@ordr-as/icons";
import { CardHeader, CardBody, Card } from "components/Card";
import { Link } from "react-router-dom";
import { ProductLibraryProductDto } from "types/contracts/contracts";
import { cloudinary } from "utils/cloudinary";

type Props = { product: ProductLibraryProductDto };

const ProductLibraryProduct = (props: Props) => {
  const { product } = props;

  return (
    <div className="w-1/4">
      <Link to={`products/${product.productLibraryId}`}>
        <Card className="cursor-pointer">
          <CardHeader>
            <div className="flex justify-between">
              <p>{product.name}</p>
              <div>{product.addedToProductList && <CheckmarkIcon />}</div>
            </div>
          </CardHeader>
          <CardBody className="flex justify-center">
            <AdvancedImage className="h-9" cldImg={cloudinary.image(product.mainImageSource)} plugins={[lazyload(), responsive()]} />
          </CardBody>
        </Card>
      </Link>
    </div>
  );
};

export default ProductLibraryProduct;
