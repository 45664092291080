import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useToggleSectionActive = () => {
  const { selectedMenu } = useSelectedMenu();
  const { post } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (args: { sectionId: string; shouldBeActive: boolean }) => {
      const { sectionId, shouldBeActive } = args;

      const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${sectionId}/toggle/${shouldBeActive}`;

      return post(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sections"]);
      },
    }
  );

  return mutation;
};
