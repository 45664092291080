import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { SectionMicronetworkPartnerDto } from "types/contracts/contracts";

type SharedWithListProps = {
  partnerLocations: SectionMicronetworkPartnerDto[];
};

const SharedWithList = (props: SharedWithListProps) => {
  const { partnerLocations } = props;
  const { selectedSection } = useSelectedSection();

  return (
    <>
      <p>{selectedSection?.name} is currently shared with these locations:</p>
      <ul>
        {partnerLocations.map((location) => (
          <SharedWithListElement key={location.partnerLocationId} partnerLocation={location} />
        ))}
      </ul>
    </>
  );
};

export default SharedWithList;

type SharedWithListElementProps = {
  partnerLocation: SectionMicronetworkPartnerDto;
};

const SharedWithListElement = (props: SharedWithListElementProps) => {
  const { partnerLocation } = props;

  return (
    <li className="flex py-1">
      <span>{partnerLocation.partnerLocationName}</span>
      <div className="px-2" />
      <VatLabel partnerLocation={partnerLocation} />
    </li>
  );
};

type VatLabelProps = {
  partnerLocation: SectionMicronetworkPartnerDto;
};

const VatLabel = (props: VatLabelProps) => {
  const { partnerLocation } = props;

  return (
    <>
      <span className="bg-brand-accent text-xs font-semibold max-w-max flex items-center rounded px-2 h-5 uppercase text-brand-black">
        {partnerLocation.useTakeawayVatForMicronetwork ? "Takeaway VAT" : "Eat-in VAT"}
      </span>
      {partnerLocation.allowActiveInactiveOverrideOnSectionProducts && (
        <span className="bg-brand-accent text-xs font-semibold max-w-max flex items-center rounded px-2 h-5 uppercase text-brand-black">
          Can toggle products on/off
        </span>
      )}
    </>
  );
};
