import { OverlayProvider, Button, Dialog } from "@ordr-as/umami-react";
import { useSelectedProducts } from "features/bulkEditProducts/hooks/useSelectedProducts";
import { useState } from "react";
import DisplaySelectedProducts from "./DisplaySelectedProducts";
import SelectCourse from "./SelectCourse";
import { useBulkUpdateCourse } from "features/bulkEditProducts/api/useBulkUpdateCourse";

const EditCourse = () => {
  const { selectedProducts } = useSelectedProducts();

  const [isOpen, setOpen] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState<string | undefined>();
  const updateCourseMutation = useBulkUpdateCourse();

  const handleSave = () => {
    updateCourseMutation.mutate(
      {
        courseId: selectedCourseId,
        productIds: selectedProducts.map((x) => x.productId),
      },
      {
        onSettled: () => {
          setSelectedCourseId(undefined);
          setOpen(false);
        },
      },
    );
  };

  const handleOnClose = () => {
    setSelectedCourseId(undefined);
    setOpen(false);
  };

  return (
    <OverlayProvider className="px-1">
      <Button kind="secondary" className="rounded-3xl h-[36px] text-sm" onClick={() => setOpen(true)} isDisabled={!selectedProducts.length}>
        Select course
      </Button>
      {isOpen && (
        <Dialog title="Select course" actionLabel="Save" onAction={handleSave} onClose={handleOnClose}>
          <SelectCourse setSelectedCourseId={setSelectedCourseId} />
          <DisplaySelectedProducts />
          <div className="pb-8" />
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default EditCourse;
