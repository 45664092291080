import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useToggleProductActive = () => {
  const { selectedMenu } = useSelectedMenu();
  const { selectedSection } = useSelectedSection();
  const { post } = useFetch();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (vars: { productId: string; shouldBeActive: boolean }) => {
      const { productId, shouldBeActive } = vars;

      const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${selectedSection?.sectionId}/products/${productId}/toggle/${shouldBeActive}`;
      return post(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["products"]);
      },
    }
  );

  return mutation;
};
