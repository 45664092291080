import { SSOContext } from "@ordr-as/authentication-library";
import { useVersionContext } from "features/version/context/VersionContext";
import { useContext } from "react";
import { QueryError } from "./postErrors";

const usePerformFetch = () => {
  const { addApiVersion, addLatestClientVersion, clientVersion } = useVersionContext();
  const authContext = useContext(SSOContext);

  if (!authContext) {
    throw new Error("useAuth must be used within SSOProvider");
  }

  const performFetch = async (url: string, httpMethod: "GET" | "PUT" | "POST" | "DELETE", body?: any) => {
    const token = await authContext?.getAccessToken();

    const response = await fetch(url, {
      method: httpMethod,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-client-version": clientVersion,
      },
    }).catch((reason) => {
      const err: QueryError = {
        error: new Error(`Fetcher threw error. URL: ${url}, Method: GET, Reason: ${reason}. This is usually network or CORS.`),
        token: token,
        url: url,
        verb: httpMethod,
      };

      throw err;
    });

    if (!response.ok) {
      const err: QueryError = {
        error: new Error(`Network response was not ok. Status: ${response.status} Reason: ${response.statusText}, Method: GET, URL: ${response.url}`),
        token: token,
        status: response.status,
        url: url,
        verb: httpMethod,
      };
      throw err;
    }

    addApiVersion(response.headers.get("x-api-version"));
    addLatestClientVersion(response.headers.get("x-latest-client-version"));

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return await response.json().catch(() => {});
  };

  return { performFetch };
};

export const useFetch = () => {
  const { performFetch } = usePerformFetch();
  const { downloadFile } = useDownloadFile();

  const get = (url: string) => performFetch(url, "GET");

  const put = (url: string, body = {}) => performFetch(url, "PUT", body);

  const post = (url: string, body = {}) => performFetch(url, "POST", body);

  const destroy = (url: string, body = {}) => performFetch(url, "DELETE", body);

  const download = (url: string) => downloadFile(url);

  return { get, put, post, destroy, download };
};

// Only used for downloading Excel files so far
const useDownloadFile = () => {
  const { addApiVersion, addLatestClientVersion, clientVersion } = useVersionContext();
  const authContext = useContext(SSOContext);

  authContext?.getAccessToken();
  const downloadFile = async (url: string) => {
    const token = await authContext?.getAccessToken();
    let filename = "";

    fetch(url, {
      headers: {
        method: "GET",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/octet-stream",
        "x-client-version": clientVersion,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        addApiVersion(response.headers.get("x-api-version"));
        addLatestClientVersion(response.headers.get("x-latest-client-version"));

        const contentDisposition = response.headers.get("content-disposition");

        if (!contentDisposition) {
          throw new Error("No content disposition header");
        }

        const parts = contentDisposition.split(";");

        filename = parts[1].split("=")[1].replaceAll('"', "");

        return response.blob();
      })
      .then((blob) => {
        if (blob != null) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");

          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      });
  };

  return { downloadFile };
};
