type ProductListLabelProps = { name: string; color: string };

export const ProductListLabel = (props: ProductListLabelProps) => {
  const { name, color } = props;

  const labelStyles = `text-xs font-semibold max-w-max flex items-center rounded p-1 pr-4 uppercase text-brand-black`;
  const styles = `${labelStyles} ${color}`;

  return <div className={styles}>{name}</div>;
};
