import AppHeader from "features/header/components/AppHeader";
import CopyLocation from "features/copyLocation/components/CopyLocation";

const CopyLocationPage = () => {
  document.title = "Favrit Menu";
  return (
    <div>
      <AppHeader />
      <div className="flex flex-row ">
        <CopyLocation />
      </div>
    </div>
  );
};

export default CopyLocationPage;
