import { OverlayProvider, Button } from "@ordr-as/umami-react";
import { useState } from "react";
import CreateCustomizationDialog from "./CreateCustomizationDialog";

const CreateCustomizationFromProduct = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <OverlayProvider>
      <Button kind="secondary" onClick={() => setOpen(true)}>
        Create customization from product
      </Button>
      {isOpen && <CreateCustomizationDialog setOpen={setOpen} fromProduct={true} />}
    </OverlayProvider>
  );
};

export default CreateCustomizationFromProduct;
