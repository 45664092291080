import { OverlayProvider, Button, Dialog } from "@ordr-as/umami-react";
import { useBulkShareProducts } from "features/bulkEditProducts/api/useBulkShareProducts";
import { useSelectedProducts } from "features/bulkEditProducts/hooks/useSelectedProducts";
import { useState } from "react";
import DisplaySelectedProducts from "./DisplaySelectedProducts";
import SelectLocation from "./SelectLocation";

const ShareProducts = () => {
  const { selectedProducts } = useSelectedProducts();

  const [isOpen, setOpen] = useState(false);
  const [selectedSlaveLocationId, setSelectedSlaveLocationId] = useState<number | undefined>();
  const bulkShareProductsMutation = useBulkShareProducts();

  const handleSave = () => {
    if (!selectedSlaveLocationId) {
      return;
    }

    bulkShareProductsMutation.mutate(
      {
        slaveLocationId: selectedSlaveLocationId,
        masterProductIds: selectedProducts.map((x) => x.productId),
      },
      {
        onSettled: () => {
          setSelectedSlaveLocationId(undefined);
          setOpen(false);
        },
      },
    );
  };

  const handleOnClose = () => {
    setSelectedSlaveLocationId(undefined);
    setOpen(false);
  };

  return (
    <OverlayProvider className="px-1">
      <Button kind="secondary" className="rounded-3xl h-[36px] text-sm" onClick={() => setOpen(true)} isDisabled={!selectedProducts.length}>
        Share products
      </Button>
      {isOpen && (
        <Dialog
          title="Share products"
          actionLabel="Save"
          onAction={handleSave}
          onClose={handleOnClose}
          isDisabledAction={selectedSlaveLocationId ? false : true}
        >
          <SelectLocation setSelectedSlaveLocationId={setSelectedSlaveLocationId} />
          <DisplaySelectedProducts />
          <div className="pb-8" />
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default ShareProducts;
