import { ProductFormType } from "features/productForm/formValidation";
import { useFormContext } from "react-hook-form";
import ControlledSwitch from "components/ControlledSwitch";

type Props = {
  editable: boolean;
};

const Active = (props: Props) => {
  const { editable } = props;
  const { control } = useFormContext<ProductFormType>();

  return (
    <label>
      <p className="mb-2">Active</p>

      <ControlledSwitch control={control} disabled={!editable} name={"active"} />

      <div className="mt-2 italic">
        Note: when this toggle is off, the product is unavailable on all sections, menus, recommendations and customizations.
      </div>
    </label>
  );
};

export default Active;
