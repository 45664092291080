import config from "config";
import { useFetch } from "api/useFetch";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { UpdateProductDto } from "types/contracts/contracts";

export const useUpdateProductFromMenu = () => {
  const { put } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const { selectedSection } = useSelectedSection();
  const { selectedMenu } = useSelectedMenu();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (vars: { productId: string; product: UpdateProductDto }) => {
      const { productId, product } = vars;
      const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${selectedSection?.sectionId}/products/${productId}/details`;

      return put(url, product);
    },
    {
      onSuccess: (_, vars) => {
        queryClient.refetchQueries(["product", selectedLocation?.locationId, vars.productId]);
        queryClient.invalidateQueries(["products"]);
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
