import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog } from "@ordr-as/umami-react";
import { useCreateCustomization } from "features/options/api/customizations/useCreateCustomization";
import { CreateCustomizationFormType, createCustomizationFormSchema } from "features/options/formValidation/createCustomization";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import Name from "./Name";
import Price from "./Price";
import SearchForProductSize from "./SearchForProductSize";

type CreateCustomizationDialogProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  fromProduct: boolean;
};

const CreateCustomizationFromProductDialog = (props: CreateCustomizationDialogProps) => {
  const { setOpen, fromProduct } = props;
  const createCustomizationMutation = useCreateCustomization();
  const [productToBeSelected, setProductToBeSelected] = useState(fromProduct);

  const methods = useForm<CreateCustomizationFormType>({
    resolver: zodResolver(createCustomizationFormSchema),
  });

  const onSubmit = (formData: CreateCustomizationFormType) => {
    createCustomizationMutation.mutate(formData);
    setOpen(false);
  };

  const productIdSet = methods.watch("productSizeId") !== undefined;

  return (
    <Dialog
      title="Create customization"
      actionLabel="Create"
      onAction={methods.handleSubmit(onSubmit)}
      onClose={() => setOpen(false)}
      alignTop
      isDisabledAction={!productIdSet && productToBeSelected}
    >
      <form className="flex flex-col gap-2">
        {productToBeSelected ? (
          <SearchForProductSize setProductToBeSelected={setProductToBeSelected} methods={methods} />
        ) : (
          <>
            <Name register={methods.register} errors={methods.formState.errors} disabled={productIdSet} />
            <Price register={methods.register} errors={methods.formState.errors} />
          </>
        )}
      </form>
    </Dialog>
  );
};

export default CreateCustomizationFromProductDialog;
