import { TextInput } from "@ordr-as/umami-react";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type DisplayTextProps = {
  register: UseFormRegister<CustomizationGroupFormType>;
  errors: FieldErrors<CustomizationGroupFormType>;
};

const DisplayText = (props: DisplayTextProps) => {
  const { register, errors } = props;

  return (
    <TextInput
      label={`Question in app, e.g. "Which sauce do you want?"`}
      register={register}
      name="displayTextInMenu"
      invalidHelpText={errors.displayTextInMenu?.message}
      validationState={errors.displayTextInMenu?.message ? "invalid" : undefined}
    />
  );
};

export default DisplayText;
