import Spinner from "components/Spinner";
import { useLookups } from "features/lookups/api/useLookups";
import ColorSelectorButton from "./ColorSelectorButton";
import { useController, Control } from "react-hook-form";
import { useState } from "react";
import ColorSelectorMenu from "./ColorSelectorMenu";
import { SectionSettingsFormType } from "../formValidation";

type Props = {
  control: Control<SectionSettingsFormType>;
  disabled: boolean;
};

const ColorSelector = (props: Props) => {
  const { control, disabled } = props;
  const { data: lookups, isSuccess } = useLookups();
  const [isOpen, setIsOpen] = useState(false);
  const { field: eposDisplayColor } = useController<SectionSettingsFormType>({ name: "eposDisplayColor", control });

  if (!isSuccess || !lookups) {
    return <Spinner />;
  }

  const initalColor = lookups.eposDisplayColors.find((item) => item.hexCode === eposDisplayColor.value);

  return (
    <div className="max-w-md flex flex-col relative gap-2">
      <p>Colour (ePOS only)</p>
      <p className="text-xs">
        The colour will make it faster to navigate your sales view. It will be applied to products in this section, unless the product have another
        colour setting.
      </p>
      <ColorSelectorButton selectedColor={initalColor} disabled={disabled} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <ColorSelectorMenu
          eposDisplayColors={lookups.eposDisplayColors.filter((x) => !x.requiresAdmin)}
          selectedColor={initalColor}
          setSelectedColor={(color) => {
            eposDisplayColor.onChange(color?.hexCode ?? "");
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ColorSelector;
