import { Header } from "@ordr-as/umami-react";
import WastageReport from "features/wastage/components/WastageReport";
import Hamburger from "../features/header/components/Hamburger";
import { useSelectedLocation } from "../features/locations/hooks/useSelectedLocation";
import config from "config";

const WastageReportPage = () => {
  const { selectedLocation } = useSelectedLocation();
  if (selectedLocation) {
    document.title = "Favrit Wastage Report: " + selectedLocation.name;
  }

  const openAdmin = () => {
    if (!selectedLocation) {
      window.location.href = config.favritAdminUrl;
      return;
    }

    window.location.href = `${config.favritAdminUrl}#/location/${selectedLocation.locationId}/`;
  };

  return (
    <>
      <Header backAction={openAdmin} backActionLabel="Back">
        <p>{selectedLocation?.name}</p>
        <Hamburger />
      </Header>
      <WastageReport />
    </>
  );
};

export default WastageReportPage;
