import { Button, Dialog, OverlayProvider } from "@ordr-as/umami-react";
import { useSelectedProducts } from "features/bulkEditProducts/hooks/useSelectedProducts";
import { useState } from "react";
import DisplaySelectedProducts from "./DisplaySelectedProducts";
import SelectColor from "./SelectColor";
import { useBulkUpdateEposDisplayColor } from "features/bulkEditProducts/api/useBulkUpdateEposDisplayColor";
import { useLookups } from "features/lookups/api/useLookups";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { canBulkEditColorFunc } from "features/productForm/utils/editableFieldsCalculator";

const EditColor = () => {
  const { selectedProducts } = useSelectedProducts();
  const [isOpen, setOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string | undefined>();
  const bulkUpdateEposDisplayColor = useBulkUpdateEposDisplayColor();

  const { canEditMenu } = useSelectedLocation();
  const { data: lookups } = useLookups();
  const canBulkEditColor = canBulkEditColorFunc(selectedProducts, canEditMenu, lookups);

  const handleSave = () => {
    bulkUpdateEposDisplayColor.mutate(
      {
        eposDisplayColor: selectedColor,
        productIds: selectedProducts.map((x) => x.productId),
      },
      {
        onSettled: () => {
          setSelectedColor(undefined);
          setOpen(false);
        },
      },
    );
  };

  const handleOnClose = () => {
    setSelectedColor(undefined);
    setOpen(false);
  };

  return (
    <OverlayProvider className="px-1">
      <Button kind="secondary" className="rounded-3xl h-[36px] text-sm" onClick={() => setOpen(true)} isDisabled={!selectedProducts.length}>
        Select colour
      </Button>
      {isOpen && (
        <Dialog title="Select colour" actionLabel="Save" isDisabledAction={!canBulkEditColor} onAction={handleSave} onClose={handleOnClose}>
          <SelectColor setSelectedColor={setSelectedColor} disabled={!canBulkEditColor} />
          {!canBulkEditColor && (
            <div className="p-2 text-orange-darkest">
              One or more of the selected products have the colour “Favrit campaign”. These can only be changed by a Favrit employee. To be able to
              change colour, you can only select products that does not have the “Favrit campaign” colour.”
            </div>
          )}
          <DisplaySelectedProducts />
          <div className="pb-8" />
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default EditColor;
