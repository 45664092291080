import { ChevronRightIcon, HandleIcon } from "@ordr-as/icons";
import { Switch } from "components/Switch";
import { PartnerLabel, EposLabel } from "./SectionLabel";
import "./section.css";
import { useState } from "react";
import { useToggleSectionActive } from "features/sections/api/useToggleSectionActive";
import { Link, useParams } from "react-router-dom";
import { MenuSectionDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Props = {
  section: MenuSectionDto;
};

const SectionListItem = (props: Props) => {
  const { section } = props;
  const { locationId, menuId, sectionId } = useParams<{
    locationId: string;
    menuId: string;
    sectionId: string;
  }>();
  const { canToggleItems, canEditMenu } = useSelectedLocation();
  const mutation = useToggleSectionActive();
  const [toggleSection, setToggleSection] = useState(section);

  const handleToggleSection = () => {
    mutation.mutate({ sectionId: section.sectionId, shouldBeActive: !toggleSection.active });
    setToggleSection((prevState) => ({
      ...prevState,
      active: !prevState.active,
    }));
  };

  const umamiStyles = "flex justify-between items-center bg-brand-white";
  const borderStyle = "rounded-none border-t-[0px] border-b-[1px] border-b-grey-lightest";
  const disabledStyle = !toggleSection.active ? "local-section-disabled" : "";
  const selectedStyle = section.sectionId === sectionId ? "bg-purple-lightest" : "";
  const listElementStyle = `${umamiStyles} ${borderStyle} ${disabledStyle} ${selectedStyle}`;

  return (
    <Link to={`/locations/${locationId}/menus/${menuId}/sections/${section.sectionId}`}>
      <li className={listElementStyle}>
        <div className="flex py-3 border-l-[12px] border-l-transparent" style={{ borderLeftColor: section.eposDisplayColor }}>
          <div className="flex items-center pr-2 pl-1">{canEditMenu && <HandleIcon />}</div>
          <div>
            <p className={`px-2 ${section.sectionId === sectionId && "bold"}`}>{section.name}</p>
            <p className="text-sm px-2">
              {section.productCount} {section.productCount === 1 ? "item" : "items"}
            </p>
          </div>
        </div>

        <div className="flex px-2 items-center">
          <PartnerLabel isPartner={section.isMicronetwork} ownerLocationName={section.sectionOwnerName} />
          <EposLabel isEposSection={section.eposOnly} />
          <Switch
            className="p-2 pr-3"
            checked={toggleSection.active}
            secondaryActive={toggleSection.isOwner ? toggleSection.active : toggleSection.activeOnOwnerLocation}
            onChange={handleToggleSection}
            ariaLabel={"Toggle Enabled"}
            disabled={!canToggleItems}
          />
          <ChevronRightIcon />
        </div>
      </li>
    </Link>
  );
};

export default SectionListItem;
