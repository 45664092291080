import { useNavigate, useParams } from "react-router-dom";

export const useNavigateToProductList = () => {
  const navigate = useNavigate();
  const { locationId } = useParams<{ locationId: string }>();

  const navigateToProductList = () => {
    navigate(`/locations/${locationId}/bulk`);
  };

  return navigateToProductList;
};
