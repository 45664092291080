import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { ProductLibrarySectionDto } from "types/contracts/contracts";

export const useProductLibrarySections = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/product-library-sections`;

  const sectionsQuery = useQuery<ProductLibrarySectionDto[], Error>(["productLibrarySections", selectedLocation?.locationId], () => get(url), {
    enabled: !!selectedLocation?.locationId,
  });

  return sectionsQuery;
};
