import { useMemo, useState } from "react";
import { TextInput } from "@ordr-as/umami-react";
import { Card, CardBody, CardHeader } from "../../../components/Card";
import { Link } from "react-router-dom";
import { useLocations } from "../api/useLocations";
import { LocationDto } from "types/contracts/contracts";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

const LocationList = () => {
  const { data: locations } = useLocations();
  const [searchTerm, setSearchTerm] = useState("");
  const { isPhone } = useScreenSize();

  const filteredLocations = useMemo(
    () =>
      locations?.filter((location) => {
        return (
          location.name &&
          location.locationGroupName &&
          (location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            location.locationId.toString() === searchTerm ||
            location.locationGroupName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            location.locationGroupId.toString() === searchTerm)
        );
      }) ?? [],
    [searchTerm, locations],
  );

  return (
    <Card className="w-full max-w-lg">
      <CardHeader className={`flex justify-between items-center px-4 ${isPhone && "flex-col gap-2"}`}>
        <h1>Locations</h1>
        <div className="flex flex-col gap-1">
          <TextInput placeholder="Search for location..." value={searchTerm} aria-label="Location search" onChange={setSearchTerm} />
        </div>
      </CardHeader>
      <CardBody className="p-0">
        <ul>
          {filteredLocations.map((location) => (
            <LocationListItem key={location.locationId} location={location} />
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default LocationList;

type Props = {
  location: LocationDto;
};

const LocationListItem = (props: Props) => {
  const { location } = props;

  const umamiStyles = "flex justify-between items-center bg-brand-white rounded-none border-b-1 border-b-purple-lightest";
  const hoverStyle = "local-section";
  const listElementStyle = `${umamiStyles} ${hoverStyle}`;

  return (
    <Link to={`locations/${location.locationId}`} className={listElementStyle}>
      <li className="p-4">
        <p>{location.name}</p>
      </li>
    </Link>
  );
};
