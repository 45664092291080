import { Button, TextInput } from "@ordr-as/umami-react";
import { useState } from "react";
import { useCreateCourse } from "./api/useCreateCourse";
import AddIcon from "@ordr-as/icons/lib/AddIcon";

const CreateCourseButton = () => {
  const [active, setActive] = useState(false);
  const [input, setInput] = useState("");
  const mutation = useCreateCourse();

  const handleCreateCourse = () => {
    const course = { name: input };

    mutation.mutate(course);
    setActive(false);
    setInput("");
  };

  if (!active) {
    return (
      <Button kind="secondary" className="flex gap-2" onClick={() => setActive(true)}>
        <AddIcon />
        Add course
      </Button>
    );
  }

  return (
    <div className="flex flex-col tablet:flex-row gap-2">
      <TextInput aria-label="Create new course" placeholder="Name of course..." value={input} onChange={setInput} />
      <Button kind="secondary" className="flex gap-2" onClick={() => handleCreateCourse()}>
        <AddIcon />
        Add new course
      </Button>
    </div>
  );
};

export default CreateCourseButton;
