import SectionComboBox from "./SectionCombobox";
import Spinner from "components/Spinner";
import { useExistingSections } from "features/sections/api/useExistingSections";

const AddSectionInput = () => {
  const { data: existingSections, isSuccess } = useExistingSections();

  if (!isSuccess) {
    return <Spinner />;
  }

  return <SectionComboBox sections={existingSections} />;
};

export default AddSectionInput;
