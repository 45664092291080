import { ChevronDownIcon, ChevronUpIcon } from "@ordr-as/icons";
import { EposDisplayColorDto } from "types/contracts/contracts";
import RainbowColoredBox from "./RainbowColoredBox";

type Props = {
  disabled: boolean;
  selectedColor: EposDisplayColorDto | undefined;
  isOpen: boolean;
  onClick: () => void;
};

const ColorSelectorButton = (props: Props) => {
  const { disabled, selectedColor, isOpen, onClick } = props;
  const buttonText = `${selectedColor ? selectedColor.name : "No colour"}`;
  const ColorBox = () => {
    if (selectedColor) {
      return <div className="h-4 w-4 rounded" style={{ backgroundColor: selectedColor.hexCode }} />;
    }

    return <RainbowColoredBox />;
  };

  return (
    <button id="sectionEposDisplayColor" type="button" className="umami-medium-button umami-secondary-button" disabled={disabled} onClick={onClick}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ColorBox />
          <span className="pl-2">{buttonText}</span>
        </div>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>
    </button>
  );
};

export default ColorSelectorButton;
