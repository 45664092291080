import { OverlayProvider, Button, Dialog } from "@ordr-as/umami-react";
import { useBulkAddOptionGroup } from "features/bulkEditProducts/api/useBulkAddOptionGroup";
import { useState } from "react";
import { useSelectedProductIds } from "../../hooks/useSelectedProductIds";
import DisplaySelectedProducts from "./DisplaySelectedProducts";
import SelectOptionGroup from "./SelectOptionGroup";

const AddOptionGroups = () => {
  const { selectedProductIds } = useSelectedProductIds();
  const bulkAddOptionGroupMutation = useBulkAddOptionGroup();

  const [isOpen, setOpen] = useState(false);
  const [selectedOptionGroupId, setSelectedOptionGroupId] = useState<string | undefined>();

  const handleSave = () => {
    if (!selectedOptionGroupId) {
      return;
    }

    bulkAddOptionGroupMutation.mutate(
      {
        optionGroupId: selectedOptionGroupId,
        productIds: selectedProductIds,
      },
      {
        onSettled: () => {
          setSelectedOptionGroupId(undefined);
          setOpen(false);
        },
      },
    );
  };

  return (
    <OverlayProvider className="px-1">
      <Button kind="secondary" className="rounded-3xl h-[36px] text-sm" onClick={() => setOpen(true)} isDisabled={!selectedProductIds.length}>
        Add option group
      </Button>
      {isOpen && (
        <Dialog
          title="Add option group"
          actionLabel="Save"
          onAction={handleSave}
          onClose={() => setOpen(false)}
          isDisabledAction={selectedOptionGroupId === undefined}
        >
          <SelectOptionGroup setSelectedOptionGroupId={setSelectedOptionGroupId} />
          <DisplaySelectedProducts />
          <div className="pb-8" />
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default AddOptionGroups;
