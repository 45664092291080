import TrashIcon from "@ordr-as/icons/lib/TrashIcon";
import { useDeleteCourseMutation } from "./api/useDeleteCourse";

type Props = {
  courseId: string;
};

const DeleteCourseButton = (props: Props) => {
  const { courseId } = props;
  const deleteCourseMutation = useDeleteCourseMutation();

  const deleteCourse = (id: string) => {
    deleteCourseMutation.mutate(id);
  };

  return (
    <div className="p-3 cursor-pointer" onClick={() => deleteCourse(courseId)}>
      <TrashIcon className="w-5 h-5 text-brand-secondary" />
    </div>
  );
};

export default DeleteCourseButton;
