import { TrashIcon } from "@ordr-as/icons";
import { Button } from "@ordr-as/umami-react";
import { useMemo } from "react";
import { CloudinarySignatureDto } from "types/contracts/contracts";
import { cloudinaryWidgetConfig } from "utils/cloudinary";
import { CloudinaryImageRequest } from "./types";
import { useCloudinarySignature } from "./useCloudinarySignature";

type Props = {
  setImage: (image: CloudinaryImageRequest) => void;
  removeImage: () => void;
  imageIsSet: boolean;
};

const CloudinaryUploadWidget = (props: Props) => {
  const { setImage, removeImage, imageIsSet } = props;
  const { data: cloudinarySignature } = useCloudinarySignature();

  const cloudinaryWidget = useMemo(() => {
    if (cloudinarySignature) {
      return createCloudinaryWidget(cloudinarySignature, setImage);
    }
  }, [cloudinarySignature, setImage]);

  const handleRemove = () => {
    removeImage();
  };

  return (
    <div className="flex gap-2 pt-2">
      <Button kind="secondary" type="button" id="imageUpload" onClick={cloudinaryWidget?.open} className="upload-product-image-button">
        Upload
      </Button>
      {imageIsSet && (
        <Button kind="secondary" onClick={handleRemove} className="text-orange-darkest border-orange-darkest">
          <TrashIcon />
        </Button>
      )}
    </div>
  );
};

const createCloudinaryWidget = (signature: CloudinarySignatureDto, setImage: (image: CloudinaryImageRequest) => void) =>
  // @ts-ignore
  cloudinary.createUploadWidget(
    {
      ...cloudinaryWidgetConfig,
      uploadSignature: signature.uploadSignature,
      publicId: signature.publicId,
      uploadSignatureTimestamp: signature.uploadSignatureTimestamp,
      folder: signature.folder,
    },
    // @ts-ignore
    (error, result) => {
      if (!error && result && result.event === "success") {
        setImage({
          public_id: result.info.public_id,
          format: result.info.format,
        });
      }
    },
  );

export default CloudinaryUploadWidget;
