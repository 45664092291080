import { zodResolver } from "@hookform/resolvers/zod";
import { OverlayProvider, Button, Dialog } from "@ordr-as/umami-react";
import { CustomizationGroupFormType, customizationGroupFormSchema } from "features/options/formValidation/customizationGroupForm";
import { defaultCustomizationGroupValues } from "features/options/utils/defaultCustomizationGroupValues";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CustomizationGroupDto } from "types/contracts/contracts";
import FormElements from "./FormElements/FormElements";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

type Props = {
  createOrUpdate: (formData: CustomizationGroupFormType, onSettled: () => void) => void;
  defaultValues?: CustomizationGroupDto;
};

const CustomizationGroupDialogForm = (props: Props) => {
  const { createOrUpdate, defaultValues } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { isPhone } = useScreenSize();

  const methods = useForm<CustomizationGroupFormType>({
    resolver: zodResolver(customizationGroupFormSchema),
    defaultValues: defaultCustomizationGroupValues(defaultValues),
  });

  const onSubmit = (formData: CustomizationGroupFormType) => {
    createOrUpdate(formData, () => setIsOpen(false));
  };

  return (
    <OverlayProvider className={`${isPhone ? "w-full" : "w-1/2"}`}>
      <Button kind="secondary" fullWidth onClick={() => setIsOpen(true)}>
        Add new customization group
      </Button>
      {isOpen && (
        <Dialog
          title="Add new customization group"
          alignTop
          onClose={() => setIsOpen(false)}
          onAction={methods.handleSubmit(onSubmit)}
          actionLabel="Create"
        >
          <form className="flex flex-col">
            <FormElements methods={methods} />
          </form>
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default CustomizationGroupDialogForm;
