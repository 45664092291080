import { Button, Dialog, OverlayProvider } from "@ordr-as/umami-react";
import { TrashIcon } from "@ordr-as/icons";
import { useState } from "react";

type ConfirmDeleteDialogProps = { deleteAction: () => void; children: React.ReactNode };

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
  const { deleteAction, children } = props;
  const [isOpen, setOpen] = useState(false);

  const handleDelete = () => {
    deleteAction();
    setOpen(false);
  };

  return (
    <OverlayProvider>
      <Button onClick={() => setOpen(true)}>
        <TrashIcon />
      </Button>
      {isOpen && (
        <Dialog
          title="Remove product"
          actionLabel="Remove"
          onAction={handleDelete}
          secondaryActionLabel="Cancel"
          onSecondaryAction={() => setOpen(false)}
          onClose={() => setOpen(false)}
          className="w-2/4"
        >
          {children}
        </Dialog>
      )}
    </OverlayProvider>
  );
};

export default ConfirmDeleteDialog;
