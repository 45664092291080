import { useQuery } from "@tanstack/react-query";
import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { LocationConfigDto } from "types/contracts/ordrservice-contracts";

export const useOrdrServiceConfig = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const url = `${config.ordrServiceUrl}/v1/locations/${selectedLocation?.locationId}/config`;

  const query = useQuery<LocationConfigDto, Error>(["ordrserviceconfig", selectedLocation?.locationId], () => get(url), {
    enabled: !!selectedLocation?.locationId,
  });

  return query;
};
