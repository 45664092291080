import { OverlayProvider } from "@ordr-as/umami-react";
import AppHeader from "features/header/components/AppHeader";
import { useProduct } from "features/products/api/useProduct";
import { useParams } from "react-router-dom";
import BulkEdit from "../features/bulkEditProducts/components/BulkEdit";
import EditProductFromProductList from "../features/products/components/Edit/EditProductFromProductList";

const BulkEditPage = () => {
  const { locationId, productId } = useParams<{ locationId: string; productId: string }>();

  const { data: selectedProduct } = useProduct(Number(locationId), productId);

  return (
    <>
      <AppHeader />
      <BulkEdit />
      {selectedProduct && (
        <OverlayProvider>
          <EditProductFromProductList product={selectedProduct} />
        </OverlayProvider>
      )}
    </>
  );
};

export default BulkEditPage;
