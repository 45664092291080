import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { ProductLibraryProductSearchResultDto } from "types/contracts/contracts";

export const useProductLibrarySearch = (searchTerm: string) => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/supplier-products?productName=${searchTerm}`;

  const searchProductLibraryQuery = useQuery<ProductLibraryProductSearchResultDto[], Error>(
    ["searchProductLibrary", selectedLocation?.locationId, searchTerm],
    () => get(url),
    {
      enabled: !!selectedLocation?.locationId && !!searchTerm,
    },
  );

  return searchProductLibraryQuery;
};
