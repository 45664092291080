import { useParams } from "react-router-dom";
import { useMenus } from "../api/useMenus";

export const useSelectedMenu = () => {
  const { menuId } = useParams<{ menuId: string }>();
  const { data: menus } = useMenus();

  const selectedMenu = menus?.find((menu) => menu.menuId === menuId);

  return { selectedMenu };
};
