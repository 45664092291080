import { useFetch } from "api/useFetch";
import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { CourseDto } from "types/contracts/contracts";
import { reorder, updateSequenceOnItems } from "utils/utils";

export const useUpdateCourseSequence = () => {
  const { put } = useFetch();
  const queryClient = useQueryClient();
  const { selectedLocation } = useSelectedLocation();

  const mutation = useMutation(
    (variables: { courseId: string; sourceIndex: number; destinationIndex: number }) => {
      const { courseId, destinationIndex } = variables;

      const updateSectionSequenceUrl = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/courses/${courseId}/update-sequence/${destinationIndex}`;

      return put(updateSectionSequenceUrl);
    },
    {
      onMutate: async ({ sourceIndex, destinationIndex }) => {
        await queryClient.cancelQueries(["courses", selectedLocation?.locationId]);

        const courses = queryClient.getQueryData<CourseDto[]>(["courses", selectedLocation?.locationId]);

        const reorderedCourses = reorder(courses || [], sourceIndex, destinationIndex);
        const coursesWithUpdatedSequence = updateSequenceOnItems(reorderedCourses);

        const coursesWithNewCustomizationGroups: CourseDto[] = coursesWithUpdatedSequence;

        queryClient.setQueryData<CourseDto[]>(["courses", selectedLocation?.locationId], () => coursesWithNewCustomizationGroups);

        return { courses };
      },
      onError: (err, variables, context) => {
        if (context?.courses) {
          queryClient.setQueryData<CourseDto[]>(["courses", selectedLocation?.locationId], context.courses);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["courses"]);
      },
    },
  );

  return mutation;
};
