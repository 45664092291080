import { TextInput, Button } from "@ordr-as/umami-react";
import { CardBody, CardFooter } from "components/Card";
import { useUpdateRecommendationGroup } from "features/options/api/recommendations/useUpdateRecommendationGroup";
import { useState } from "react";
import { RecommendationGroupDto } from "types/contracts/contracts";
import DeleteRecommendationGroupButton from "./DeleteRecommendationGroupButton";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";

type RecommendationGroupSettingsProps = {
  optionGroupId: string;
  recommendationGroup: RecommendationGroupDto;
};

const RecommendationGroupSettings = (props: RecommendationGroupSettingsProps) => {
  const { optionGroupId, recommendationGroup } = props;
  const updateRecommendationGroupMutation = useUpdateRecommendationGroup();
  const { isPhone } = useScreenSize();

  const [input, setInput] = useState<string>(recommendationGroup.name);

  const handleSave = () => {
    updateRecommendationGroupMutation.mutate({ name: input, displayTextInMenu: input });
  };

  return (
    <>
      <CardBody>
        <TextInput value={input} onChange={setInput} label="Name of recommendation group" />
      </CardBody>
      <CardFooter className={`flex justify-between gap-2 ${isPhone && "flex-col"}`}>
        <DeleteRecommendationGroupButton optionGroupId={optionGroupId} />
        <Button kind="primary" onClick={handleSave}>
          Save changes
        </Button>
      </CardFooter>
    </>
  );
};

export default RecommendationGroupSettings;
