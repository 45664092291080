import React, { createContext, ReactNode, useContext, useState, Dispatch } from "react";

type BulkProductsContextType = {
  selectedProductIds: string[];
  setSelectedProductIds: Dispatch<React.SetStateAction<string[]>>;
};

const BulkProductsContext = createContext<BulkProductsContextType | undefined>(undefined);

const BulkProductsProvider = ({ children }: { children: ReactNode }) => {
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

  return <BulkProductsContext.Provider value={{ selectedProductIds, setSelectedProductIds }}>{children}</BulkProductsContext.Provider>;
};

export default BulkProductsProvider;

export const useBulkProductsContext = () => {
  const context = useContext(BulkProductsContext)!;

  if (context === undefined) {
    throw Error("useBulkProductsContext must be used within BulkProductsProvider");
  }

  return context;
};
