import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { MicronetworkLocationDto } from "types/contracts/contracts";

export const useAvailableMicronetworkSections = () => {
  const { get } = useFetch();
  const { selectedMenu } = useSelectedMenu();

  const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/micronetwork-sections`;

  const availableMicronetworkSectionsQuery = useQuery<MicronetworkLocationDto[], Error>(
    ["availableMicronetworkSections", selectedMenu?.menuId],
    () => get(url),
    { enabled: !!selectedMenu?.menuId },
  );

  return availableMicronetworkSectionsQuery;
};
