import { asOptionalField } from "utils/utils";
import { z } from "zod";

export type SectionSettingsFormType = z.infer<typeof sectionSettingsFormSchema>;

const openingHoursSchema = {
  openingHoursId: z.string().uuid(),
  day: z.number().min(1).max(7),
  open: z.boolean().default(true),
  startTime: z.string().regex(new RegExp("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"), "Wrong format"),
  endTime: z.string().regex(new RegExp("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"), "Wrong format"),
};

const micronetworkOpeningHoursSchema = {
  menuId: z.string().uuid(),
  eposOnly: z.boolean(),
  hideFromMenuWhenClosed: z.boolean(),
  useSectionSpecificOpeningHours: z.boolean(),
  openingHours: z.array(z.object(openingHoursSchema)).length(7),
  ignoreProducerOpeningHours: z.boolean(),
};

export const sectionSettingsFormSchema = z.object({
  name: z.string(),
  eposOnly: z.boolean(),
  useSectionSpecificOpeningHours: z.boolean(),
  openingHours: z.array(z.object(openingHoursSchema)).length(7),
  hideFromMenuWhenClosed: z.boolean(),
  micronetworkOpeningHours: z.array(z.object(micronetworkOpeningHoursSchema)),
  eposDisplayColor: asOptionalField(z.string()),
  ignoreProducerOpeningHours: asOptionalField(z.boolean()),
});
