import { Control, UseFormRegister } from "react-hook-form";
import { OpeningHoursDto } from "types/contracts/contracts";
import { SectionSettingsFormType } from "../formValidation";
import OpeningHour from "./OpeningHour";
import "./openingHours.css";

type Props = {
  menuId: string;
  openingHours: OpeningHoursDto[];
  register: UseFormRegister<SectionSettingsFormType>;
  control: Control<SectionSettingsFormType>;
  disabled?: boolean;
};

const OpeningHours = (props: Props) => {
  const { openingHours, register, control, disabled } = props;

  return (
    <div className="border border-grey-medium rounded py-3 px-3 opening-hours-table">
      <div className="font-bold mb-2">Day</div>
      <div className="flex justify-around">
        <span className="font-bold mb-2">Opens</span>
        <span className="font-bold mb-2">-</span>
        <span className="font-bold mb-2">Closes</span>
      </div>
      <div className="font-bold flex justify-center items-center mb-2">Open</div>
      {openingHours.map(({ day }, index) => (
        <OpeningHour register={register} control={control} day={day} index={index} key={day} disabled={disabled} />
      ))}
    </div>
  );
};

export default OpeningHours;
