import { Header } from "@ordr-as/umami-react";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import BigTabs from "./BigTabs";
import Hamburger from "./Hamburger";
import NavigationButtons from "./NavigationButtons";
import config from "../../../config";

const AppHeader = () => {
  const { selectedLocation, canEditMenu } = useSelectedLocation();

  const openAdmin = () => {
    if (!selectedLocation) {
      window.location.href = config.favritAdminUrl;
      return;
    }

    window.location.href = `${config.favritAdminUrl}#/location/${selectedLocation.locationId}/order-logs`;
  };

  return (
    <>
      <Header backAction={openAdmin} backActionLabel="Back">
        <p>{selectedLocation?.name}</p>
        <Hamburger />
      </Header>
      <div className="m-2">
        <NavigationButtons />
        {selectedLocation && (
          <div className="flex items-center">
            <BigTabs selectedLocation={selectedLocation} canEditMenu={canEditMenu} />
          </div>
        )}
      </div>
    </>
  );
};

export default AppHeader;
