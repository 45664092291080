import { Card } from "components/Card";
import { useSelectedOptionGroup } from "../../hooks/useSelectedOptionGroup";
import { EntreeIcon } from "@ordr-as/icons";
import RecommendationGroup from "../Recommendations/RecommendationGroup";
import CustomizationGroup from "../Customizations/CustomizationGroup/CustomizationGroup";
import { CustomizationGroupDto, RecommendationGroupDto } from "types/contracts/contracts";

const OptionGroup = () => {
  const { selectedOptionGroup } = useSelectedOptionGroup();

  if (!selectedOptionGroup) {
    return <NoOptionGroupSelected />;
  }

  if (selectedOptionGroup.optionGroupType === "RECOMMENDATION") {
    return <RecommendationGroup recommendationGroup={selectedOptionGroup as RecommendationGroupDto} />;
  }

  if (selectedOptionGroup.optionGroupType === "CUSTOM") {
    return <CustomizationGroup customizationGroup={selectedOptionGroup as CustomizationGroupDto} />;
  }

  return <></>;
};

export default OptionGroup;

const NoOptionGroupSelected = () => {
  return (
    <Card className="w-1/2">
      <div className="justify-center items-center py-8">
        <EntreeIcon className="mx-auto" height={30} width={30} />
        <div className="p-4" />
        <p className="text-center">Select the option group you want to edit</p>
      </div>
    </Card>
  );
};
