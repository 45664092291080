import { BulkProductDto, LookupsDto, ProductDetailsDto } from "types/contracts/contracts";

export const canEditBasicDataFunc = (product: ProductDetailsDto | undefined, canEditMenu: boolean) => {
  if (!canEditMenu) {
    return false;
  }

  if (!product) {
    return true;
  }

  if (product.isCentrallyManagedProduct) {
    return false;
  }
  if (product.isCentrallyManagedProduct) {
    return false;
  }
  return true;
};

export const canEditColorFunc = (product: ProductDetailsDto | undefined, canEditBasicData: boolean, lookups: LookupsDto | undefined) => {
  if (!canEditBasicData) {
    return false;
  }

  if (!product) {
    return true;
  }

  if (product.isCentrallyManagedProduct) {
    return false;
  }

  if (product.isMicronetworkedProduct) {
    return false;
  }

  if (
    product.productEposDisplayColor &&
    lookups?.eposDisplayColors.find((x) => x.hexCode == product.productEposDisplayColor)?.requiresAdmin &&
    !lookups.userIsAdmin
  ) {
    return false;
  }

  return true;
};

export const canBulkEditColorFunc = (products: BulkProductDto[], canEditMenu: boolean, lookups: LookupsDto | undefined) => {
  if (!canEditMenu) {
    return false;
  }

  if (lookups?.userIsAdmin) {
    return true;
  }

  const adminColors = lookups?.eposDisplayColors.filter((color) => color.requiresAdmin);
  const containsProductWithAdminColor = products.some(
    (product) => adminColors?.some((adminColor) => adminColor.hexCode === product.eposDisplayColor),
  );

  if (containsProductWithAdminColor) {
    return false;
  }

  return true;
};

export const availableColorsFunc = (product: ProductDetailsDto | undefined, lookups: LookupsDto | undefined) => {
  if (lookups?.userIsAdmin) {
    return lookups.eposDisplayColors;
  }

  if (
    product &&
    product.productEposDisplayColor &&
    lookups?.eposDisplayColors.find((x) => x.hexCode == product.productEposDisplayColor)?.requiresAdmin
  ) {
    return lookups.eposDisplayColors;
  }

  return lookups?.eposDisplayColors.filter((x) => !x.requiresAdmin) || [];
};

export const canEditProductLibraryIdFunc = (product: ProductDetailsDto | undefined, lookups: LookupsDto | undefined) => {
  if (!lookups?.userIsAdmin) {
    return false;
  }

  if (!product) {
    return true;
  }

  if (product.isGiftCard) {
    return false;
  }

  if (product.isCentrallyManagedProduct) {
    return false;
  }

  if (product.isMicronetworkedProduct) {
    return false;
  }

  return true;
};

const isMicronetworkedProductFunc = (product: ProductDetailsDto | undefined) => {
  return product && product.isMicronetworkedProduct;
};

export const canEditTaxGroupAndPrinterFunc = (product: ProductDetailsDto | undefined, canEditMenu: boolean) => {
  return canEditMenu && (product == null || !isMicronetworkedProductFunc(product));
};

export const canToggleProductAndInventoryFunc = (product: ProductDetailsDto | undefined, canToggleItems: boolean) => {
  return canToggleItems && (product == null || !isMicronetworkedProductFunc(product));
};

export const isCentrallyManagedProductFunc = (product: ProductDetailsDto | undefined) => {
  return product != null && product.isCentrallyManagedProduct;
};
