import { useState } from "react";
import { Button, Checkbox, TextInput } from "@ordr-as/umami-react";
import { Card } from "../../../components/Card";
import { useFetch } from "api/useFetch";
import config from "config";

interface CopyLocationDataDto {
  linkToFromLocationIdAsParent: boolean;
  fromLocationId: number;
  toLocationIds: number[];
}

const CopyLocation = () => {
  const [copyFrom, setCopyFrom] = useState("");
  const [copyTo, setCopyTo] = useState("");
  const [withPim, setWithPim] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { post } = useFetch();

  const handleClick = () => {
    const copyLocationDto: CopyLocationDataDto = {
      fromLocationId: Number(copyFrom),
      toLocationIds: copyTo.split(/[ ,]+/).map((x) => Number(x)),
      linkToFromLocationIdAsParent: withPim,
    };

    setDisableButton(true);

    post(`${config.menuBuilderUrl}/copy-menu`, copyLocationDto)
      .then((result) => alert(result.status))
      .finally(() => setDisableButton(false));
  };

  return (
    <Card className="w-1/2">
      <h4>Copy location</h4>
      <div>
        Note: In order to copy data to a location, the target location needs to be completely empty. Empty means exactly one menu, no products in the
        product list, no sections, and no tax groups.
      </div>
      <br />
      <br />
      <div className="flex flex-col gap-1">
        <label>
          LocationId to copy from.
          <br />
          <TextInput placeholder="LocationId to copy from" value={copyFrom} aria-label="LocationId to copy from" onChange={setCopyFrom} />
        </label>
        <br />
        <label>
          LocationId(s) to copy to. Comma separated list.
          <br />
          <TextInput placeholder="LocationId to copy to" value={copyTo} aria-label="LocationId to copy to" onChange={setCopyTo} />
        </label>
        <br />
        <label>
          Use pim?
          <Checkbox aria-label="Select all" isSelected={withPim} onChange={setWithPim} />
        </label>
        <br />
        <Button kind="primary" isDisabled={disableButton} onClick={handleClick}>
          Copy dat menu 🔥
        </Button>
      </div>
    </Card>
  );
};

export default CopyLocation;
