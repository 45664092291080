interface Config {
  qa: AppConfig;
  prod: AppConfig;
  local: AppConfig;
}

export type ENV = keyof Config;

interface AppConfig {
  env: ENV;
  menuBuilderUrl: string;
  ordrServiceUrl: string;
  cloudinaryApiKey: string;
  favritAdminUrl: string;
  guestAppUrl: string;
  eposUrl: string;
}

const getConfig: () => AppConfig = () => {
  const env = (import.meta.env.VITE_ENV as ENV) || "local";

  const c: Config = {
    local: {
      env,
      menuBuilderUrl: "https://localhost:5003/api",
      ordrServiceUrl: "https://qa.favrit.com/ws/ordr-service",
      cloudinaryApiKey: "824345894193677",
      favritAdminUrl: "https://admin.qa.favrit.com/",
      guestAppUrl: "https://qa.favrit.com/en-gb/",
      eposUrl: "https://epos.qa.favrit.com/",
    },
    qa: {
      env,
      menuBuilderUrl: `https://qa.favrit.com/ws/menu-builder/api`,
      ordrServiceUrl: "https://qa.favrit.com/ws/ordr-service",
      cloudinaryApiKey: "824345894193677",
      favritAdminUrl: "https://admin.qa.favrit.com/",
      guestAppUrl: "https://qa.favrit.com/en-gb/",
      eposUrl: "https://epos.qa.favrit.com/",
    },
    prod: {
      env,
      menuBuilderUrl: `https://favrit.com/ws/menu-builder/api`,
      ordrServiceUrl: "https://favrit.com/ws/ordr-service",
      cloudinaryApiKey: "824345894193677",
      favritAdminUrl: "https://admin.favrit.com/",
      guestAppUrl: "https://favrit.com/en-gb/",
      eposUrl: "https://epos.favrit.com/",
    },
  };
  return c[env];
};

const config = getConfig();

export default config;
