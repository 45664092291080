import NumberInput from "components/NumberInput";
import { AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type SizePriceProps = {
  register: UseFormRegister<AddProductLibraryProductFormType>;
  errors: FieldErrors<AddProductLibraryProductFormType>;
  index: number;
};

const SizePrice = (props: SizePriceProps) => {
  const { register, errors, index } = props;

  let err = undefined;

  if (errors.sizes) {
    err = errors.sizes[index]?.price;
  }

  return <NumberInput labelText="Price" registerReturn={register(`sizes.${index}.price`, { valueAsNumber: true })} fieldError={err} />;
};

export default SizePrice;
