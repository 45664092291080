import { useFetch } from "api/useFetch";
import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelectedLocation } from "../../../locations/hooks/useSelectedLocation";
import { useSelectedOptionGroup } from "../../hooks/useSelectedOptionGroup";

export const useToggleRecommendationActive = () => {
  const { selectedLocation } = useSelectedLocation();
  const { selectedOptionGroup } = useSelectedOptionGroup();

  const { put } = useFetch();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (vars: { recommendationId: string; shouldBeActive: boolean }) => {
      const { recommendationId, shouldBeActive } = vars;

      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/recommendation-groups/${selectedOptionGroup?.optionGroupId}/recommendations/${recommendationId}/toggle/${shouldBeActive}`;
      return put(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["optionGroups"]);
      },
    }
  );

  return mutation;
};
