import { SSOContext } from "@ordr-as/authentication-library";
import { HamburgerMenu, MenuAction, MenuLink } from "@ordr-as/umami-react";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useIosAppVersion } from "hooks/useIosAppVersion";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { LocationDto } from "types/contracts/contracts";

const Hamburger = () => {
  const { selectedLocation } = useSelectedLocation();
  const authContext = useContext(SSOContext);
  const { data: iosAppVersion } = useIosAppVersion();

  if (!selectedLocation) {
    return (
      <HamburgerMenu>
        {!iosAppVersion && (
          <Link className="umami-context-menu-item" to="/">
            My locations
          </Link>
        )}
        <MenuAction className="umami-context-menu-item" onClick={() => authContext?.logout()}>
          Log out
        </MenuAction>
      </HamburgerMenu>
    );
  }

  const { guestAppUrl, eposUrl } = otherAppLinks(selectedLocation);

  return (
    <HamburgerMenu>
      {!iosAppVersion && (
        <Link className="umami-context-menu-item" to="/">
          My locations
        </Link>
      )}
      <MenuLink href={guestAppUrl} target="_blank" rel="noreferrer">
        Open in guest app
      </MenuLink>
      {!iosAppVersion && (
        <MenuLink href={eposUrl} target="_blank" rel="noreferrer">
          Open in ePOS
        </MenuLink>
      )}
      <MenuAction className="umami-context-menu-item" onClick={() => authContext?.logout()}>
        Log out
      </MenuAction>
    </HamburgerMenu>
  );
};

export default Hamburger;

const otherAppLinks = (selectedLocation: LocationDto) => {
  const guestAppUrl = `${config.guestAppUrl}menu/location/${selectedLocation.encodedLocationId}`;
  const eposUrl = `${config.eposUrl}${selectedLocation.encodedLocationId}`;

  return { guestAppUrl, eposUrl };
};
