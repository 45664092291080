import { Button, TextInput } from "@ordr-as/umami-react";
import { useCreateRecommendationGroup } from "features/options/api/recommendations/useCreateRecommendationGroup";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";
import { useState } from "react";

const NewRecommendationGroupButton = () => {
  const [input, setInput] = useState("");
  const createRecommendationGroupMutation = useCreateRecommendationGroup();
  const [active, setActive] = useState(false);
  const { isPhone } = useScreenSize();

  const handleCreateRecommendationGroup = () => {
    const recommendationGroup = { name: input, displayTextInMenu: input };

    createRecommendationGroupMutation.mutate(recommendationGroup);
    setActive(false);
    setInput("");
  };

  const width = isPhone ? "w-full" : "";

  if (!active) {
    return (
      <Button kind="secondary" className={`${width}`} onClick={() => setActive(true)}>
        Add new recommendation group
      </Button>
    );
  }

  return (
    <div className={`flex gap-2 ${width}`}>
      <TextInput
        aria-label="Add new recommendation group"
        placeholder="Name of recommendation group..."
        value={input}
        onChange={setInput}
        fullWidth
      />
      <Button kind="secondary" onClick={() => handleCreateRecommendationGroup()}>
        Add
      </Button>
    </div>
  );
};

export default NewRecommendationGroupButton;
