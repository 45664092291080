import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProductOverviewDto } from "types/contracts/contracts";
import { reorder, updateSequenceOnItems } from "utils/utils";

export const useUpdateProductSequence = () => {
  const { selectedSection } = useSelectedSection();
  const { selectedMenu } = useSelectedMenu();
  const { put } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (variables: { productId: string; sourceIndex: number; destinationIndex: number }) => {
      const { productId, destinationIndex } = variables;
      const updateProductSequenceUrl = `${config.menuBuilderUrl}/v1/menus/${selectedSection?.menuId}/sections/${selectedSection?.sectionId}/products/${productId}/update-sequence/${destinationIndex}`;

      return put(updateProductSequenceUrl);
    },
    {
      onMutate: async ({ sourceIndex, destinationIndex }) => {
        await queryClient.cancelQueries(["products", selectedMenu?.menuId, selectedSection?.sectionId]);

        const previousProducts = queryClient.getQueryData<ProductOverviewDto[]>(["products", selectedMenu?.menuId, selectedSection?.sectionId]);

        const reorderedProducts = reorder(previousProducts || [], sourceIndex, destinationIndex);
        const productsWithUpdatedSequence = updateSequenceOnItems(reorderedProducts);

        queryClient.setQueryData<ProductOverviewDto[]>(
          ["products", selectedMenu?.menuId, selectedSection?.sectionId],
          () => productsWithUpdatedSequence,
        );

        return { previousProducts };
      },
      onError: (_err, _variables, context) => {
        if (context?.previousProducts) {
          queryClient.setQueryData<ProductOverviewDto[]>(["products", selectedMenu?.menuId, selectedSection?.sectionId], context.previousProducts);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["products"]);
      },
    },
  );

  return mutation;
};
