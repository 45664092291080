import { Button } from "@ordr-as/umami-react";
import Spinner from "components/Spinner";
import { useAddSectionFromMicroNetwork } from "features/micronetworks/api/useAddSectionFromMicronetwork";
import { useAvailableMicronetworkSections } from "features/micronetworks/api/useAvailableMicronetworkSections";
import { useDeleteSectionFromMicroNetwork } from "features/micronetworks/api/useDeleteSectionFromMicronetwork";
import { MicronetworkLocationDto } from "types/contracts/contracts";

type Props = {
  closeDialog: () => void;
};

const AvailableSections = (props: Props) => {
  const { closeDialog } = props;
  const { data: availableSections, isSuccess } = useAvailableMicronetworkSections();
  const mutation = useAddSectionFromMicroNetwork();
  const deleteMutation = useDeleteSectionFromMicroNetwork();

  const handlePress = (availableSection: MicronetworkLocationDto) => {
    mutation.mutate(availableSection.sectionId, {
      onSettled: () => {
        closeDialog();
      },
    });
  };

  const handleDeletePress = (availableSection: MicronetworkLocationDto) => {
    deleteMutation.mutate(availableSection.sectionId, {
      onSettled: () => {
        closeDialog();
      },
    });
  };

  if (!isSuccess) {
    return <Spinner />;
  }

  if (!availableSections.length) {
    return <p>There are no micronetwork sections available.</p>;
  }

  return (
    <>
      {availableSections.map((availableSection) => (
        <div key={availableSection.sectionId} className="flex gap-2 mb-2 items-center">
          <Button
            kind="secondary"
            key={`${availableSection.sectionOwnerId}-${availableSection.sectionId}`}
            onClick={() => handlePress(availableSection)}
          >
            Add {availableSection.sectionOwnerName} - {availableSection.sectionName}
          </Button>
          <Button
            kind="secondary"
            key={`${availableSection.sectionOwnerId}-${availableSection.sectionId}-remove`}
            onClick={() => handleDeletePress(availableSection)}
          >
            Remove section sharing
          </Button>
        </div>
      ))}
    </>
  );
};

export default AvailableSections;
