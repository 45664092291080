import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateCourseDto } from "types/contracts/contracts";

export const useCreateCourse = () => {
  const { selectedLocation } = useSelectedLocation();
  const { post } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (course: CreateCourseDto) => {
      const createUrl = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/courses`;

      return post(createUrl, course);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["courses"]);
        queryClient.invalidateQueries(["locations"]);
      },
    },
  );

  return mutation;
};
