import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@ordr-as/umami-react";
import { CardBody, CardFooter } from "components/Card";
import { CustomizationGroupFormType, customizationGroupFormSchema } from "features/options/formValidation/customizationGroupForm";
import { defaultCustomizationGroupValues } from "features/options/utils/defaultCustomizationGroupValues";
import { useForm } from "react-hook-form";
import { CustomizationGroupDto } from "types/contracts/contracts";
import DeleteCustomizationGroupButton from "../DeleteCustomizationGroupButton";
import FormElements from "./FormElements/FormElements";

type Props = {
  createOrUpdate: (formData: CustomizationGroupFormType) => void;
  defaultValues: CustomizationGroupDto;
};

const CustomizationGroupCardForm = (props: Props) => {
  const { createOrUpdate, defaultValues } = props;

  const methods = useForm<CustomizationGroupFormType>({
    resolver: zodResolver(customizationGroupFormSchema),
    defaultValues: defaultCustomizationGroupValues(defaultValues),
  });

  const onSubmit = (formData: CustomizationGroupFormType) => {
    createOrUpdate(formData);
  };

  return (
    <form className="flex flex-col" onSubmit={methods.handleSubmit(onSubmit)}>
      <CardBody>
        <FormElements methods={methods} />
      </CardBody>
      <CardFooter className="flex justify-between">
        <DeleteCustomizationGroupButton optionGroupId={defaultValues.optionGroupId} />
        <Button kind="primary" type="submit">
          Save
        </Button>
      </CardFooter>
    </form>
  );
};

export default CustomizationGroupCardForm;
