import { AdvancedImage, lazyload, responsive } from "@cloudinary/react";
import { Card, CardHeader, CardBody } from "components/Card";
import { Link } from "react-router-dom";
import { ProductLibrarySupplierDto } from "types/contracts/contracts";
import { cloudinary } from "utils/cloudinary";

type SupplierProps = { supplier: ProductLibrarySupplierDto };

const Supplier = (props: SupplierProps) => {
  const { supplier } = props;

  return (
    <Card>
      <Link to={`suppliers/${supplier.supplierId}`} key={supplier.supplierId}>
        <CardHeader>{supplier.supplierName}</CardHeader>
        <CardBody>
          <div style={{ width: "200px" }}>
            <AdvancedImage cldImg={cloudinary.image(supplier.imageUrl)} plugins={[lazyload(), responsive()]} />
          </div>
        </CardBody>
      </Link>
    </Card>
  );
};

export default Supplier;
