import { Button, TextInput } from "@ordr-as/umami-react";
import { TrashIcon } from "@ordr-as/icons";
import { useFieldArray, UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { ProductFormType } from "../../../formValidation";
import NumberInput from "components/NumberInput";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Props = {
  editable: boolean;
};

const Addons = (props: Props) => {
  const { editable } = props;
  const { fields, append, remove } = useFieldArray<ProductFormType, "addons">({
    name: "addons",
  });
  const { selectedLocation } = useSelectedLocation();

  return (
    <div className="border border-grey-medium rounded p-4 flex-col gap-4">
      <h4>Addons</h4>
      <p>Make it easy to add extra ingredients to the product e.g. extra meat, avocado or cheese. List the items and price.</p>

      <div className="py-4">
        {fields.length > 0 && (
          <table>
            <thead>
              <tr>
                <th className="px-2">Name</th>
                <th className="px-2">Price in {selectedLocation?.currency}</th>
                {editable && <th className="px-2">Delete addon</th>}
              </tr>
            </thead>
            <tbody>
              {fields.map((_, index) => (
                <Addon index={index} key={index} remove={remove} disabled={!editable} />
              ))}
            </tbody>
          </table>
        )}
        {fields.length === 0 && !editable && <>There are no addons.</>}
      </div>

      {editable && (
        <Button
          kind="secondary"
          onClick={() => {
            append({ price: 0, name: "" });
          }}
        >
          Add new addon
        </Button>
      )}
    </div>
  );
};

export default Addons;

type AddonProps = {
  index: number;
  remove: UseFieldArrayRemove;
  disabled?: boolean;
};

const Addon = (props: AddonProps) => {
  const { index, remove, disabled } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductFormType>();

  return (
    <tr>
      <td className="px-2">
        <TextInput
          aria-label="Name of addon"
          name={`addons.${index}.name`}
          register={register}
          invalidHelpText={errors.addons?.[index]?.name?.message}
          validationState={errors.addons?.[index]?.name ? "invalid" : undefined}
          isDisabled={disabled}
        />
      </td>
      <td className="px-2">
        <NumberInput
          labelText={""}
          registerReturn={register(`addons.${index}.price`, { valueAsNumber: true })}
          fieldError={errors.addons?.[index]?.price}
          disabled={disabled}
          min={0}
        />
      </td>

      <td className="px-2">
        {!disabled && (
          <TrashIcon
            className="cursor-pointer h-5 w-5 mt-3"
            onClick={(e) => {
              e.stopPropagation();
              remove(index);
            }}
          />
        )}
      </td>
    </tr>
  );
};
