import NumberInput from "components/NumberInput";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type MaximumSelectionLimitProps = {
  register: UseFormRegister<CustomizationGroupFormType>;
  errors: FieldErrors<CustomizationGroupFormType>;
};

const MaximumSelectionLimit = (props: MaximumSelectionLimitProps) => {
  const { register, errors } = props;

  return (
    <NumberInput
      labelText="Maximum"
      registerReturn={register("maximumSelectionLimit", {
        setValueAs: (value: string) => Number(value) || undefined,
      })}
      fieldError={errors.maximumSelectionLimit}
    />
  );
};

export default MaximumSelectionLimit;
