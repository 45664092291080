import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useLookups } from "features/lookups/api/useLookups";

const useLocalVats = () => {
  const { selectedLocation } = useSelectedLocation();
  const { data: lookups } = useLookups();

  const localVats = lookups?.vats.filter((vat) => vat.countryCode === selectedLocation?.countryCode);

  return localVats;
};

export default useLocalVats;
