import Tabs from "features/sections/components/Section/Tabs";
import { Dispatch, SetStateAction } from "react";

import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { CardBody } from "components/Card";
import Spinner from "components/Spinner";

type Props = {
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
};

const SectionUsageTab = (props: Props) => {
  const { tab, setTab } = props;
  const { selectedSection } = useSelectedSection();

  if (!selectedSection) {
    return <Spinner />;
  }

  return (
    <CardBody>
      <Tabs onTabPress={setTab} selectedTab={tab} />

      <table className="my-2">
        <thead>
          <td className="border border-grey-lightest p-4">
            <span className="text-bold">Location name</span>
          </td>
          <td className="border border-grey-lightest p-4">
            <span className="text-bold">Menu name</span>
          </td>
          <td className="border border-grey-lightest p-4">
            <span className="text-bold">Section type</span>
          </td>
          <td className="border border-grey-lightest p-4">
            <span className="text-bold">ePOS only?</span>
          </td>
          <td className="border border-grey-lightest p-4">
            <span className="text-bold">Active?</span>
          </td>
          <td className="border border-grey-lightest p-4">
            <span className="text-bold">Ignore producer opening hours?</span>
          </td>
          <td className="border border-grey-lightest p-4">
            <span className="text-bold">Use takeaway VAT for micronetwork?</span>
          </td>
        </thead>
        {selectedSection.availableOnMenus.map((x) => (
          <tr key={x.menuId}>
            <td className="border border-grey-lightest p-4">{x.locationName}</td>
            <td className="border border-grey-lightest p-4">{x.menuName}</td>
            <td className="border border-grey-lightest p-4">{x.menuSectionType === "OWNER" ? "Owner" : "Micronetwork"}</td>
            <td className="border border-grey-lightest p-4">{x.eposOnly ? "Yes" : "No"}</td>
            <td className="border border-grey-lightest p-4">{x.active ? "Active" : "Inactive"}</td>
            <td className="border border-grey-lightest p-4">{x.ignoreProducerOpeningHours ? "Yes" : "No"}</td>
            <td className="border border-grey-lightest p-4">
              {x.menuSectionType === "OWNER" ? "N/A" : x.useTakeawayVatForMicronetwork ? "Yes" : "No"}
            </td>
          </tr>
        ))}
      </table>
    </CardBody>
  );
};

export default SectionUsageTab;
