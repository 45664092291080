import { TextInput } from "@ordr-as/umami-react";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type NameProps = {
  register: UseFormRegister<CustomizationGroupFormType>;
  errors: FieldErrors<CustomizationGroupFormType>;
};

const Name = (props: NameProps) => {
  const { register, errors } = props;

  return (
    <TextInput
      label="Group name (only visible in admin)"
      name="name"
      register={register}
      invalidHelpText={errors.name?.message}
      validationState={errors.name?.message ? "invalid" : undefined}
    />
  );
};

export default Name;
