import { Dispatch, SetStateAction } from "react";
import { CardBody } from "components/Card";
import Tabs from "features/sections/components/Section/Tabs";
import PartnerProductListItems from "./PartnerProductListItems";
import { useProducts } from "features/products/api/useProducts";
import Spinner from "components/Spinner";

type PartnerProductListProps = {
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
};

const PartnerProductList = (props: PartnerProductListProps) => {
  const { tab, setTab } = props;
  const { data: products, isSuccess } = useProducts();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <CardBody>
      <Tabs onTabPress={setTab} selectedTab={tab} />
      <PartnerProductListItems products={products} />
    </CardBody>
  );
};

export default PartnerProductList;
