import config from "config";
import { useFetch } from "api/useFetch";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useQuery } from "@tanstack/react-query";
import { MenuDto } from "types/contracts/contracts";

export const useMenus = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const menusUrl = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/menus`;

  const menusQuery = useQuery<MenuDto[], Error>(["menus", selectedLocation?.locationId], () => get(menusUrl), {
    enabled: !!selectedLocation?.locationId,
  });

  return menusQuery;
};
