import config from "../../../config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useFetch } from "../../../api/useFetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useBulkDestroyProducts = () => {
  const { destroy } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (productIds: string[]) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/products`;

      return destroy(url, productIds);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    }
  );

  return mutation;
};
