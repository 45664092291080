import { LocationDto, UpdateLocationDto } from "types/contracts/contracts";

export const updateLocationDto = (fields: Partial<UpdateLocationDto>, location: LocationDto): UpdateLocationDto => {
  return {
    disallowCreateProduct: location.disallowCreateProduct,
    displaySizesAsSeparateProductsInEpos: location.displaySizesAsSeparateProductsInEpos,
    requireTaxGroup: location.requireTaxGroup,
    ...fields,
  };
};
