import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import Section from "features/sections/components/Section/Section";
import SectionList from "features/sections/components/SectionList/SectionList";
import { ChevronLeftIcon, EntreeIcon } from "@ordr-as/icons";
import { CardCompact } from "components/Card";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useMenus } from "../api/useMenus";
import Spinner from "components/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { useSelectedMenu } from "../hooks/useSelectedMenu";
import { useLastActiveMenuContext } from "features/locations/context/LastActiveMenuContext";
import { useScreenSize } from "features/screenSize/ScreenSizeContext";
import MenuSelector from "./MenuSelector";
import AppHeader from "features/header/components/AppHeader";

const Menu = () => {
  const { data: menus, isSuccess } = useMenus();
  const { selectedSection } = useSelectedSection();
  const { selectedLocation } = useSelectedLocation();
  const { selectedMenu } = useSelectedMenu();
  const navigate = useNavigate();
  const { setLastActiveMenuId } = useLastActiveMenuContext();
  const { isPhone } = useScreenSize();

  const handleSelectEdit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLastActiveMenuId(e.target.value);
    navigate(`/locations/${selectedLocation?.locationId}/menus/${e.target.value}`);
  };

  if (!isSuccess || !selectedMenu) {
    return <Spinner />;
  }

  if (isPhone) {
    const showSection = selectedSection?.sectionId !== undefined;

    return (
      <>
        {showSection ? (
          <>
            <Link
              to={`/locations/${selectedLocation?.locationId}/menus/${selectedMenu.menuId}`}
              className="flex h-7 px-4 items-center gap-2 text-grey-dark"
            >
              <ChevronLeftIcon />
              Back
            </Link>
            <Section />
          </>
        ) : (
          <>
            <AppHeader />
            {selectedLocation && (
              <MenuSelector selectedLocation={selectedLocation} selectedMenu={selectedMenu} menus={menus} handleSelectEdit={handleSelectEdit} />
            )}
            <SectionList />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <AppHeader />
      {selectedLocation && (
        <MenuSelector selectedLocation={selectedLocation} selectedMenu={selectedMenu} menus={menus} handleSelectEdit={handleSelectEdit} />
      )}

      <div className="flex">
        <SectionList />
        {selectedSection?.sectionId ? <Section /> : <NoSectionSelected />}
      </div>
    </>
  );
};

export default Menu;

const NoSectionSelected = () => {
  return (
    <CardCompact className="w-1/2">
      <div className="justify-center items-center py-8">
        <EntreeIcon className="mx-auto" height={30} width={30} />
        <div className="p-4" />
        <p className="text-center">Select the section you want to edit</p>
      </div>
    </CardCompact>
  );
};
