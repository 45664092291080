import { SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { BulkProductDto } from "types/contracts/contracts";
import { useProductListColumns } from "./ProductListColumns";
import React from "react";

type Props = {
  filteredProducts: BulkProductDto[];
  allProducts: BulkProductDto[];
};

const ProductList = (props: Props) => {
  const { filteredProducts, allProducts } = props;

  const columns = useProductListColumns();

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable<BulkProductDto>({
    data: filteredProducts,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  if (allProducts.length === 0) {
    return <p>{"This location does not have any products yet. Products from micronetworks don't show up here."}</p>;
  }
  return (
    <>
      {filteredProducts.length === 0 && <p>No matches found.</p>}

      {filteredProducts.length > 0 && (
        <div className="border rounded m-4 px-4 py-2">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      {...{
                        className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted() as string] ?? null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="border border-grey-lightest p-2">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ProductList;
