import { ProductDetailsDto } from "types/contracts/contracts";
import { ProductFormType } from "../formValidation";

export const mapToProductFormType = (product: ProductDetailsDto): ProductFormType => {
  const valuesFromProduct: ProductFormType = {
    ...product,
    allergenIds: product.allergens,
    productCategoryId: product.productCategory,
    centrallyManagedChildProductLocations: product.centrallyManagedChildProducts.map((x) => x.locationId),
  };

  return valuesFromProduct;
};
