import { AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import SizePrice from "./SizePrice";

type NoPLSizesProps = {
  register: UseFormRegister<AddProductLibraryProductFormType>;
  errors: FieldErrors<AddProductLibraryProductFormType>;
};

const NoPLSizes = (props: NoPLSizesProps) => {
  const { register, errors } = props;

  const selectedItemStyles = "flex flex-row items-center gap-4";

  return (
    <span className={selectedItemStyles}>
      <SizePrice register={register} errors={errors} index={0} />
    </span>
  );
};

export default NoPLSizes;
