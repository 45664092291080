import { Button } from "@ordr-as/umami-react";
import Spinner from "components/Spinner";
import { useDeleteMenu } from "features/menus/api/useDeleteMenu";
import { useMenus } from "features/menus/api/useMenus";
import { useState } from "react";

const DeleteMenu = () => {
  const mutation = useDeleteMenu();
  const menusQuery = useMenus();
  const [input, setInput] = useState("");

  if (!menusQuery.isSuccess) {
    return <Spinner />;
  }

  const handleDeleteMenu = () => {
    mutation.mutate(input);
    setInput("");
  };

  const handleSelectMenuToDelete = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInput(e.target.value);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 mx-4">
        <select className="border border-grey-medium rounded py-3 px-3" onChange={(e) => handleSelectMenuToDelete(e)}>
          <option value={""}>No menu selected</option>
          {menusQuery.data
            .filter((x) => !x.activeInEpos && !x.activeInGuestApp)
            .map((menu) => (
              <option key={menu.menuId} value={menu.menuId}>
                {menu.menuName}
              </option>
            ))}
        </select>
      </div>
      <div className="flex flex-col gap-2 mx-4 pb-6">
        <Button kind="secondary" onClick={() => handleDeleteMenu()} isDisabled={!input}>
          Delete menu
        </Button>
      </div>
    </div>
  );
};

export default DeleteMenu;
