import Spinner from "components/Spinner";
import { useAvailableProductsForCustomizationGroup } from "features/options/api/customizations/useAvailableProductsForCustomizationGroup";
import { CreateCustomizationFormType } from "features/options/formValidation/createCustomization";
import { Dispatch, SetStateAction } from "react";
import { UseFormReturn } from "react-hook-form";
import { ExistingProductSizeDto } from "types/contracts/contracts";
import ProductComboBox from "./ProductComboBox";

type SearchForProductSizeProps = {
  methods: UseFormReturn<CreateCustomizationFormType>;
  setProductToBeSelected: Dispatch<SetStateAction<boolean>>;
};

const SearchForProductSize = (props: SearchForProductSizeProps) => {
  const { methods, setProductToBeSelected } = props;
  const { data: existingProducts, isSuccess } = useAvailableProductsForCustomizationGroup();

  const onSelectProduct = (product: ExistingProductSizeDto) => {
    methods.setValue("name", product.name);
    methods.setValue("price", product.price);
    methods.setValue("productSizeId", product.productSizeId);
    setProductToBeSelected(false);
  };

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <>
      <ProductComboBox products={existingProducts} onSelectProduct={onSelectProduct} />
    </>
  );
};

export default SearchForProductSize;
