import { useState } from "react";
import { useCombobox } from "downshift";
import { useAddSectionFromExisting } from "features/sections/api/useAddSectionFromExisting";
import { ExistingSectionDto } from "types/contracts/contracts";

type Props = {
  sections: ExistingSectionDto[];
};

const SectionComboBox = (props: Props) => {
  const { sections } = props;
  const [filteredSections, setFilteredSections] = useState(sections);
  const mutation = useAddSectionFromExisting();

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items: filteredSections,
    onSelectedItemChange: (changes) => {
      if (!changes.selectedItem) {
        return;
      }
      mutation.mutate(changes.selectedItem.sectionId);
    },
    onInputValueChange: (changes) => {
      const { inputValue } = changes;

      if (!inputValue) {
        return;
      }

      setFilteredSections(sections.filter((section) => section.name.toLowerCase().includes(inputValue.toLowerCase())));
    },
    itemToString: (item) => (item ? item.name : ""),
  });

  const noResultFromSearch = () => {
    if (filteredSections.length === 0) {
      return <li>Couldn&apos;t find section</li>;
    }
  };

  const searchResults = () => {
    return filteredSections.map((section, index) => (
      <li
        className={highlightedIndex === index ? "bg-brand-accent" : ""}
        key={`${section.name}-${index}`}
        {...getItemProps({ item: section, index })}
      >
        {section.name}
      </li>
    ));
  };

  return (
    <div>
      <div>
        <input
          {...getInputProps({ role: "combobox" })}
          aria-label="Add existing section"
          className="border border-grey-medium rounded py-3 px-3"
          placeholder="Add existing section..."
        />
      </div>
      <ul {...getMenuProps()}>
        {isOpen && searchResults()}
        {isOpen && noResultFromSearch()}
      </ul>
    </div>
  );
};

export default SectionComboBox;
