import { UseFormReturn } from "react-hook-form";
import "../productForm.css";
import DisplayedOnSections from "features/productForm/components/fieldgroups/displayedOnSections/DisplayedOnSections";
import { useParams } from "react-router-dom";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useLookups } from "features/lookups/api/useLookups";
import { ProductDetailsDto } from "types/contracts/contracts";
import { ProductFormType } from "features/productForm/formValidation";
import Addons from "./fieldgroups/addons/Addons";
import Advanced from "./fieldgroups/advanced/Advanced";
import BasicInformation from "./fieldgroups/basicInformation/BasicInformation";
import CentrallyManagedChildProductLocations from "./fieldgroups/centrallyManagedProduct/CentrallyManagedChildProductLocations";
import DisplayActiveDetails from "./fieldgroups/displayActiveDetails/DisplayActiveDetails";
import ProductLibraryName from "./fieldgroups/infomessages/ProductLibraryName";
import ProductRelationships from "./fieldgroups/infomessages/ProductRelationships";
import Removables from "./fieldgroups/removables/Removables";
import Sizes from "./fieldgroups/sizes/Sizes";
import {
  availableColorsFunc,
  canEditColorFunc,
  canEditBasicDataFunc,
  canEditTaxGroupAndPrinterFunc,
  canToggleProductAndInventoryFunc,
  isCentrallyManagedProductFunc,
  canEditProductLibraryIdFunc,
} from "features/productForm/utils/editableFieldsCalculator";
import Dangerzone from "./fieldgroups/dangerzone/Dangerzone";
import GiftCardInformation from "./fieldgroups/infomessages/GiftCardInformation";

type Props = {
  methods: UseFormReturn<ProductFormType, object>;
  onSubmit: (formData: ProductFormType) => void;
  product?: ProductDetailsDto;
};

const Form = (props: Props) => {
  const { methods, onSubmit, product } = props;

  const { menuId, sectionId, productId } = useParams<{ menuId: string; sectionId: string; productId: string }>();
  const { selectedLocation, canEditMenu, canToggleItems } = useSelectedLocation();
  const { data: lookups } = useLookups();

  const isGiftCard = product?.isGiftCard || false;
  const canEditBasicData = canEditBasicDataFunc(product, canEditMenu);
  const canEditColor = canEditColorFunc(product, canEditBasicData, lookups);
  const availableColors = availableColorsFunc(product, lookups);
  const canEditTaxGroupAndPrinter = canEditTaxGroupAndPrinterFunc(product, canEditMenu);
  const canToggleProductAndInventory = canToggleProductAndInventoryFunc(product, canToggleItems);
  const isCentrallyManaged = isCentrallyManagedProductFunc(product);
  const canEditProductLibraryId = canEditProductLibraryIdFunc(product, lookups);

  const displaySecondaryPrinter = (selectedLocation && selectedLocation.courses.length > 0) || false;

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <ProductRelationships product={product} />
        <ProductLibraryName product={product} />
        <GiftCardInformation product={product} />

        <BasicInformation
          canEditBasicData={canEditBasicData}
          isGiftCard={isGiftCard}
          canToggleProductAndInventory={canToggleProductAndInventory}
          canEditColor={canEditColor}
          availableColors={availableColors}
        />

        <Sizes
          canEditBasicData={canEditBasicData}
          isGiftCard={isGiftCard}
          canEditInventoryAndActiveToggleOnSizes={canToggleProductAndInventory}
          methods={methods}
          isCentrallyManaged={isCentrallyManaged}
          product={product}
        />

        <Advanced
          canEditBasicData={canEditBasicData}
          isGiftCard={isGiftCard}
          canEditTaxGroupAndPrinter={canEditTaxGroupAndPrinter}
          displaySecondaryPrinter={displaySecondaryPrinter}
        />

        <Addons editable={canEditBasicData && !isGiftCard} />
        <Removables editable={canEditBasicData && !isGiftCard} />

        <DisplayedOnSections sections={product?.sectionsContainingProduct || []} />
        {canEditBasicData && !isGiftCard && <CentrallyManagedChildProductLocations />}
        {lookups?.userIsAdmin && <Dangerzone canEditProductLibraryId={canEditProductLibraryId} />}
      </form>

      {lookups?.userIsAdmin && selectedLocation?.locationId && menuId && sectionId && productId && (
        <div className="border border-grey-medium rounded p-4 flex-col gap-4">
          <DisplayActiveDetails locationId={selectedLocation.locationId} menuId={menuId} sectionId={sectionId} productId={productId} />
          <div className="pt-4 italic">This is only available to Favrit admins now.</div>
        </div>
      )}
    </>
  );
};

export default Form;
