import { useParams } from "react-router-dom";
import { useOptionGroups } from "../api/useOptionGroups";

export const useSelectedOptionGroup = () => {
  const { data, isSuccess } = useOptionGroups();
  const { optionGroupId } = useParams<{ optionGroupId: string }>();

  if (!isSuccess || !optionGroupId) {
    return { selectedOptionGroup: undefined };
  }

  const allOptionGroups = [...data.recommendationGroups, ...data.customizationGroups];

  const selectedOptionGroup = allOptionGroups.find((optionGroup) => optionGroup.optionGroupId === optionGroupId);

  return { selectedOptionGroup };
};
