import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { OptionGroupsDto } from "types/contracts/contracts";

export const useOptionGroups = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const getOptionGroupsUrl = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/option-groups`;

  const optionGroupsQuery = useQuery<OptionGroupsDto, Error>(["optionGroups", selectedLocation?.locationId], () => get(getOptionGroupsUrl), {
    enabled: !!selectedLocation?.locationId,
  });

  return optionGroupsQuery;
};
