import { CardBody } from "components/Card";
import Tabs from "features/sections/components/Section/Tabs";
import { Dispatch, SetStateAction } from "react";
import { MenuSectionDto } from "types/contracts/contracts";

type Props = {
  tab: string | number;
  setTab: Dispatch<SetStateAction<string | number>>;
  selectedSection: MenuSectionDto;
};

const PartnerShareSectionTab = (props: Props) => {
  const { tab, setTab, selectedSection } = props;

  return (
    <CardBody>
      <Tabs onTabPress={setTab} selectedTab={tab} />
      <p className="py-2">
        This section is owned by {selectedSection.sectionOwnerName} ({selectedSection.sectionOwnerLocationId}) and can only be shared from there.
      </p>
      <p className="py-2">VAT type used: {selectedSection.useTakeawayVatForMicronetwork ? "Takeaway" : "Eat in"}</p>
      <p className="py-2">
        Allow active/inactive override on products: {selectedSection.allowActiveInactiveOverrideOnSectionProducts ? "Yes" : "No"}
      </p>
    </CardBody>
  );
};

export default PartnerShareSectionTab;
