import Active from "./fields/Active";
import Allergens from "./fields/AllergenSelect/Allergens";
import Description from "./fields/Description";
import ImageUploader from "./fields/ImageUploader";
import Name from "./fields/Name";
import ColorSelector from "./fields/ColorSelector/ColorSelector";
import { EposDisplayColorDto } from "types/contracts/contracts";
import Courses from "./fields/Courses";
import EposOnlyProduct from "./fields/EposOnlyProduct";

type Props = {
  canToggleProductAndInventory: boolean;
  isGiftCard: boolean;
  canEditBasicData: boolean;
  canEditColor: boolean;
  availableColors: EposDisplayColorDto[];
};

const BasicInformation = (props: Props) => {
  const { canEditBasicData, isGiftCard, canToggleProductAndInventory, canEditColor, availableColors } = props;

  return (
    <div className="border border-grey-medium rounded p-4 flex flex-col gap-4">
      <h4>Basic information</h4>
      <Active editable={canToggleProductAndInventory} />
      <EposOnlyProduct editable={canEditBasicData && !isGiftCard} />
      <Name editable={canEditBasicData && !isGiftCard} />
      <Description editable={canEditBasicData && !isGiftCard} />
      <ImageUploader editable={canEditBasicData && !isGiftCard} />
      <Allergens editable={canEditBasicData && !isGiftCard} />
      <ColorSelector editable={canEditColor} availableColors={availableColors} />
      <Courses editable={canEditBasicData && !isGiftCard} />
    </div>
  );
};

export default BasicInformation;
