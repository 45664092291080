import Menu from "../features/menus/components/Menu";

const MenuPage = () => {
  return (
    <>
      <Menu />
    </>
  );
};

export default MenuPage;
