import Spinner from "components/Spinner";
import { useUpdateRecommendationSequence } from "features/options/api/recommendations/useUpdateRecommendationSequence";
import { useSelectedOptionGroup } from "features/options/hooks/useSelectedOptionGroup";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import RecommendedProductListItem from "./RecommendedProductListItem";
import { RecommendedProductSizeDto } from "types/contracts/contracts";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Type = {
  products: RecommendedProductSizeDto[];
};

const RecommendedProductList = (props: Type) => {
  const { products } = props;
  const updateRecommendationSequenceMutation = useUpdateRecommendationSequence();
  const { selectedOptionGroup } = useSelectedOptionGroup();
  const { canEditMenu } = useSelectedLocation();

  if (!selectedOptionGroup) {
    return <Spinner />;
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    updateRecommendationSequenceMutation.mutate({
      optionGroupProductId: products[result.source.index].optionGroupProductId,
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });
  };

  if (!canEditMenu) {
    return (
      <ul>
        {products.map((product) => (
          <div key={product.productId} className="mb-2">
            <RecommendedProductListItem key={product.productId} productSize={product} />
          </div>
        ))}
      </ul>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`option-group-product-droppable`}>
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {products.map((product, index) => (
              <Draggable key={product.optionGroupProductId} draggableId={product.optionGroupProductId} index={index}>
                {(provided) => (
                  <div className="mb-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <RecommendedProductListItem key={product.productId} productSize={product} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default RecommendedProductList;
