import { availableColorsFunc } from "features/productForm/utils/editableFieldsCalculator";
import Spinner from "../../../../components/Spinner";
import { useLookups } from "features/lookups/api/useLookups";
import { Item, Select } from "@ordr-as/umami-react/lib/form";

type SelectPrinterProps = {
  setSelectedColor: React.Dispatch<React.SetStateAction<string | undefined>>;
  disabled: boolean;
};

const SelectColor = (props: SelectPrinterProps) => {
  const { setSelectedColor, disabled } = props;
  const { data: lookups, isSuccess } = useLookups();
  const availableColors = availableColorsFunc(undefined, lookups);

  if (!isSuccess) {
    return <Spinner />;
  }

  const handleOnChange = (key: React.Key) => {
    if (key === "none") {
      setSelectedColor(undefined);
      return;
    }
    setSelectedColor(key as string);
  };

  const colorsWithDefault = [{ hexCode: "none", name: "Colour from section" }, ...availableColors];

  return (
    <div className="flex max-w-[400px] w-full pb-2">
      <Select fullWidth label="Colour (ePOS only)" placeholder="Select..." isDisabled={disabled} onSelectionChange={handleOnChange}>
        {colorsWithDefault.map((color) => (
          <Item key={color.hexCode}>{color.name}</Item>
        ))}
      </Select>
    </div>
  );
};

export default SelectColor;
