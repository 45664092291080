import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { ExistingSectionDto } from "types/contracts/contracts";

export const useExistingSections = () => {
  const { selectedMenu } = useSelectedMenu();
  const { get } = useFetch();

  const getExistingSectionsUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/existing-sections`;

  const existingSectionsQuery = useQuery<ExistingSectionDto[]>(["existingSections", selectedMenu?.menuId], () => get(getExistingSectionsUrl));

  return existingSectionsQuery;
};
