import { useCallback } from "react";
import { useBulkProductsContext } from "../context/BulkProductsContext";

export const useToggleSelectedProductId = () => {
  const { setSelectedProductIds } = useBulkProductsContext();

  const toggleSelectedProductId = useCallback(
    (productIdToBeToggled: string) => {
      setSelectedProductIds((prevState) => {
        if (!prevState.includes(productIdToBeToggled)) {
          const productIdAdded = [...prevState, productIdToBeToggled];
          return productIdAdded;
        }

        const productIdRemoved = [...prevState.filter((product) => product !== productIdToBeToggled)];
        return productIdRemoved;
      });
    },
    [setSelectedProductIds]
  );

  return { toggleSelectedProductId };
};
