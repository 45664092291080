import { useFetch } from "api/useFetch";
import config from "config";
import { useQuery } from "@tanstack/react-query";
import { ProductDetailsDto } from "types/contracts/contracts";

export const useProduct = (locationId?: number, productId?: string) => {
  const getProductUrl = `${config.menuBuilderUrl}/v1/locations/${locationId}/products/${productId}/details`;

  const { get } = useFetch();

  const productQuery = useQuery<ProductDetailsDto>(["product", locationId, productId], () => get(getProductUrl), {
    enabled: !!productId && !!locationId,
  });

  return productQuery;
};
