import Spinner from "components/Spinner";
import { useProductLibraryProducts } from "../api/useProductLibraryProducts";
import ProductLibraryProduct from "./ProductLibraryProduct";

const ProductLibraryProducts = () => {
  const { data: productLibraryProducts, isSuccess } = useProductLibraryProducts();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-row flex-wrap">
      {productLibraryProducts.map((product) => (
        <ProductLibraryProduct key={product.productLibraryId} product={product} />
      ))}
    </div>
  );
};

export default ProductLibraryProducts;
