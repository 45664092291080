import { getLocalTimeZone, today, DateValue } from "@internationalized/date";
import { RangeCalendar } from "@ordr-as/umami-react";
import { useState } from "react";
import { useWastages } from "../api/useWastages";
import WastageOverviewItem from "./WastageReportItem";
import DownloadButton from "./DownloadButton";

interface Dates {
  start: DateValue;
  end: DateValue;
}

const WastageOverview = () => {
  const [dates, setDates] = useState<Dates>({
    start: today(getLocalTimeZone()).subtract({ days: 7 }),
    end: today(getLocalTimeZone()),
  });

  const { data: wastage } = useWastages(dates?.start?.toString(), dates?.end?.toString());

  const compareDates = (dateA: Date, dateB: Date) => {
    // Will result in latest first
    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <div className="m-4 max-w-md border border-grey-medium rounded">
        <h3 className="p-4 border-grey-medium border-b">Date range</h3>
        <div className="p-4 flex flex-col justify-center place-content-center">
          <RangeCalendar aria-label="Date range" value={dates} onChange={setDates} />
          <DownloadButton fromDate={dates?.start?.toString()} toDate={dates?.end?.toString()} />
        </div>
      </div>
      <div className="flex flex-col m-4 border border-grey-medium rounded">
        <span className="text-lg bold p-4">Wastage reports</span>
        {wastage
          ?.sort((first, second) => compareDates(first.wastageTimestampInLocationTimeZone, second.wastageTimestampInLocationTimeZone))
          ?.map((wastage) => <WastageOverviewItem key={wastage.wastageId} wastage={wastage} />)}
      </div>
    </>
  );
};

export default WastageOverview;
