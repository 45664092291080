import { useMemo } from "react";
import { BulkProductOptionGroupDto } from "types/contracts/contracts";
import { findUnique } from "utils/utils";

export const useUniqueOptionGroups = (optionGroups: BulkProductOptionGroupDto[]) => {
  const optionGroupIds = useMemo(() => optionGroups.map((optionGroup) => optionGroup.optionGroupId), [optionGroups]);

  const uniqueOptionGroupIds = useMemo(() => findUnique(optionGroupIds), [optionGroupIds]);

  const uniqueOptionGroups = useMemo(
    () => uniqueOptionGroupIds.map((uniqueOptionGroupId) => optionGroups.find((optionGroup) => uniqueOptionGroupId === optionGroup.optionGroupId)!),
    [optionGroups, uniqueOptionGroupIds],
  );

  return { uniqueOptionGroups };
};
