import { Link, useLocation } from "react-router-dom";
import { LocationDto } from "types/contracts/contracts";

type Props = { selectedLocation: LocationDto; canEditMenu: boolean };

const BigTabs = (props: Props) => {
  const { selectedLocation, canEditMenu } = props;

  const menuLink = `/locations/${selectedLocation.locationId}`;
  const productListLink = `/locations/${selectedLocation.locationId}/bulk`;
  const optionsLink = `/locations/${selectedLocation.locationId}/options`;
  const taxGroupLink = `/locations/${selectedLocation.locationId}/tax-groups`;
  const productLibraryLink = `/locations/${selectedLocation.locationId}/product-library`;
  const settingsLink = `/locations/${selectedLocation.locationId}/settings`;

  const { pathname } = useLocation();

  const currentArea = pathname.split("/")[3];

  const bigTabStyle = (area?: string) => {
    return `p-2 text-base text-brand-secondary ${currentArea === area && "underline text-bold"}`;
  };

  return (
    <div className="flex pb-2 overflow-x-auto overflow-y-hidden whitespace-nowrap">
      <Link to={menuLink}>
        <span className={bigTabStyle("menus")}>Your menus</span>
      </Link>
      <Link to={productListLink}>
        <span className={bigTabStyle("bulk")}>Product list</span>
      </Link>
      <Link to={optionsLink}>
        <span className={bigTabStyle("options")}>Customizations</span>
      </Link>
      <Link to={taxGroupLink}>
        <span className={bigTabStyle("tax-groups")}>Tax groups</span>
      </Link>
      {!selectedLocation.disallowCreateProduct && canEditMenu && (
        <Link to={productLibraryLink}>
          <span className={bigTabStyle("product-library")}>Product library</span>
        </Link>
      )}
      {canEditMenu && (
        <Link to={settingsLink}>
          <span className={bigTabStyle("settings")}>Settings</span>
        </Link>
      )}
    </div>
  );
};

export default BigTabs;
