import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { useSelectedSection } from "features/sections/hooks/useSelectedSection";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateProductDto } from "types/contracts/contracts";

export const useCreateNewProductFromMenu = () => {
  const { selectedMenu } = useSelectedMenu();
  const { selectedSection } = useSelectedSection();
  const { post } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (product: CreateProductDto) => {
      const createProductUrl = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections/${selectedSection?.sectionId}/products`;

      return post(createProductUrl, product);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["products"]);
        queryClient.invalidateQueries(["sections"]);
        queryClient.invalidateQueries(["bulkProducts"]);
      },
    },
  );

  return mutation;
};
