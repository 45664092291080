import { Button } from "@ordr-as/umami-react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import EatInAccountReference from "./TaxGroupFormElements/EatInAccountReference";
import EatInVatId from "./TaxGroupFormElements/EatInVatId";
import Name from "./TaxGroupFormElements/Name";
import TakeawayAccountReference from "./TaxGroupFormElements/TakeawayAccountReference";
import TakeawayVatId from "./TaxGroupFormElements/TakeawayVatId";
import { TaxGroupsFormType } from "../types/types";
import { useUpdateTaxGroups } from "features/taxGroups/api/useUpdateTaxGroups";
import { zodResolver } from "@hookform/resolvers/zod";
import { taxGroupsFormSchema } from "../formValidation";
import { useCreateDefaultTaxGroups } from "../api/useCreateDefaultTaxGroups";
import { TrashIcon } from "@ordr-as/icons";
import { useDeleteTaxGroupMutation } from "../api/useDeleteTaxGroupMutation";
import { TaxGroupDto } from "types/contracts/contracts";
import { useCreateTaxGroup } from "../api/useCreateTaxGroup";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";

type Props = { taxGroups: TaxGroupDto[] };

const TaxGroupsForm = (props: Props) => {
  const { taxGroups } = props;
  const { selectedLocation, canEditMenu } = useSelectedLocation();
  const saveTaxGroupMutation = useUpdateTaxGroups();
  const createTaxGroupMutation = useCreateTaxGroup();
  const createDefaultTaxGroupsMutation = useCreateDefaultTaxGroups();
  const deleteTaxGroupMutation = useDeleteTaxGroupMutation();
  const navigate = useNavigate();

  const methods = useForm<TaxGroupsFormType>({
    resolver: zodResolver(taxGroupsFormSchema),
    defaultValues: { taxGroups },
  });
  const { fields } = useFieldArray({ control: methods.control, name: "taxGroups" });

  const updateTaxGroups = (formData: TaxGroupsFormType) => {
    saveTaxGroupMutation.mutate(formData.taxGroups, { onSuccess: () => navigate(0) });
  };

  const createTaxGroup = () => {
    createTaxGroupMutation.mutate(selectedLocation?.locationId, { onSuccess: () => navigate(0) });
  };

  const createDefaults = () => {
    createDefaultTaxGroupsMutation.mutate(selectedLocation?.locationId, { onSuccess: () => navigate(0) });
  };

  const deleteTaxGroups = (taxGroupId: string) => {
    deleteTaxGroupMutation.mutate(taxGroupId, { onSuccess: () => navigate(0) });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(updateTaxGroups)}>
        {fields.length === 0 && <p>This location does not have any tax groups yet.</p>}
        {fields.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Eat-in account</th>
                <th>Eat-in VAT</th>
                <th>Takeaway account</th>
                <th>Takeaway VAT</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => (
                <tr key={field.id + index}>
                  <td>
                    <Name index={index} disabled={field.isGiftCardTaxGroup} />
                  </td>
                  <td>
                    <EatInAccountReference index={index} />
                  </td>
                  <td>
                    <EatInVatId index={index} disabled={field.isGiftCardTaxGroup} />
                  </td>
                  <td>
                    <TakeawayAccountReference index={index} />
                  </td>
                  <td>
                    <TakeawayVatId index={index} disabled={field.isGiftCardTaxGroup} />
                  </td>
                  <td>
                    {canEditMenu && (!taxGroups[index] || taxGroups[index].usageCount < 1) && (
                      <Button onClick={() => deleteTaxGroups(field.taxGroupId)}>
                        <TrashIcon />
                      </Button>
                    )}
                  </td>
                  <td>{(!taxGroups[index] || taxGroups[index].usageCount < 1) && <UnusedLabel />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {canEditMenu && (
          <>
            <div className="flex pt-6">
              <div className="px-2">
                <Button kind="secondary" type="button" onClick={createTaxGroup}>
                  Add new tax group
                </Button>
              </div>
              <div className="px-2">
                <Button kind="secondary" type="button" onClick={() => createDefaults()}>
                  Add default tax groups
                </Button>
              </div>
              <div className="px-2">
                <Button kind="primary" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default TaxGroupsForm;

const UnusedLabel = () => {
  const labelStyles = `text-xs font-semibold flex items-center rounded px-2 h-5 uppercase text-brand-black bg-grey-medium`;

  return <p className={labelStyles}>Not used</p>;
};
