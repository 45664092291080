import { Button } from "@ordr-as/umami-react";
import { TrashIcon } from "@ordr-as/icons";
import { useDeleteCustomizationGroup } from "features/options/api/customizations/useDeleteCustomizationGroup";

type DeleteCustomizationGroupButtonProps = { optionGroupId: string };

const DeleteCustomizationGroupButton = (props: DeleteCustomizationGroupButtonProps) => {
  const { optionGroupId } = props;
  const deleteCustomizationGroupMutation = useDeleteCustomizationGroup();

  const handleRemove = () => {
    deleteCustomizationGroupMutation.mutate(optionGroupId);
  };

  return (
    <Button kind="secondary" onClick={handleRemove} className="text-orange-darkest border-orange-darkest">
      <TrashIcon />
      <div className="p-2" />
      Delete
    </Button>
  );
};

export default DeleteCustomizationGroupButton;
