import { UseFormRegister } from "react-hook-form";
import { SectionSettingsFormType } from "./formValidation";

type NameProps = {
  register: UseFormRegister<SectionSettingsFormType>;
  disabled?: boolean;
};

const Name = (props: NameProps) => {
  const { register, disabled } = props;

  return (
    <label>
      <p className="pb-1">Section name</p>
      <input aria-label="Section name" className="border border-grey-medium rounded py-3 px-3" {...register("name")} disabled={disabled} />
    </label>
  );
};

export default Name;
