import { useParams } from "react-router-dom";
import { useSuppliers } from "../api/useSuppliers";

export const useSelectedSupplier = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const { data: suppliers } = useSuppliers();

  const selectedSupplier = suppliers?.find((supplier) => supplier.supplierId === Number(supplierId));

  return { selectedSupplier };
};
