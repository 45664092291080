import { Switch } from "components/Switch";
import { Control, FieldValues, Path, useController } from "react-hook-form";

type ControlledSwitchProps<FormType extends FieldValues> = {
  disabled?: boolean;
  control: Control<FormType>;
  name: Path<FormType>;
  label?: string;
};

const ControlledSwitch = <FormType extends FieldValues>(props: ControlledSwitchProps<FormType>) => {
  const { disabled, control, name, label } = props;

  const { field } = useController<FormType>({ name, control });

  return <Switch checked={field.value as boolean} onChange={field.onChange} disabled={disabled} label={label} />;
};

export default ControlledSwitch;
