import { usePrinters } from "features/printers/api/usePrinters";
import Spinner from "../../../../components/Spinner";
import { Item, Select } from "@ordr-as/umami-react/lib/form";

type SelectPrinterProps = {
  setSelectedPrinter: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const SelectPrinter = (props: SelectPrinterProps) => {
  const { setSelectedPrinter } = props;
  const { data: printers, isSuccess } = usePrinters();

  if (!isSuccess) {
    return <Spinner />;
  }

  const handleOnChange = (key: React.Key) => {
    if (key === "-1") {
      setSelectedPrinter(undefined);
      return;
    }
    setSelectedPrinter(Number(key));
  };

  const printersWithDefault = [{ printerId: -1, name: "Default" }, ...printers];

  return (
    <div className="flex max-w-[400px] w-full">
      <Select fullWidth label="Printer" placeholder="Select..." onSelectionChange={handleOnChange}>
        {printersWithDefault.map((printer) => (
          <Item key={printer.printerId}>{printer.name}</Item>
        ))}
      </Select>
    </div>
  );
};

export default SelectPrinter;
