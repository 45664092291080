import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { WastageDto } from "types/contracts/contracts";

export const useWastages = (fromDate?: string, toDate?: string) => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/wastages?fromDate=${fromDate}&toDate=${toDate}`;

  const sectionsQuery = useQuery<WastageDto[], Error>(["wastages", selectedLocation?.locationId, fromDate, toDate], () => get(url), {
    enabled: !!selectedLocation?.locationId && !!fromDate && !!toDate,
  });

  return sectionsQuery;
};
