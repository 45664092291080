import ProductLibraryId from "./ProductLibraryId";

type Props = {
  canEditProductLibraryId: boolean;
};

const Dangerzone = (props: Props) => {
  const { canEditProductLibraryId } = props;

  return (
    <div className="border border-grey-medium rounded p-4 flex flex-col gap-4">
      <h4>Danger zone</h4>
      <p>Fields in this area are only visible to Favrit employees. Changing them can have consequences.</p>
      <ProductLibraryId editable={canEditProductLibraryId} />
    </div>
  );
};

export default Dangerzone;
