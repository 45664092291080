import { useMemo } from "react";
import { useBulkProductsContext } from "../context/BulkProductsContext";

export const useProductIsSelected = (productId: string) => {
  const { selectedProductIds } = useBulkProductsContext();

  const isSelected = useMemo(() => selectedProductIds.filter((selected) => selected === productId).length > 0, [productId, selectedProductIds]);

  return isSelected;
};
