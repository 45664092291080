import ControlledSwitch from "components/ControlledSwitch";
import { Control } from "react-hook-form";
import { SectionSettingsFormType } from "./formValidation";

type EnableOpeningHoursProps = {
  control: Control<SectionSettingsFormType>;
  disabled?: boolean;
};

const EnableOpeningHours = (props: EnableOpeningHoursProps) => {
  const { control, disabled } = props;

  return (
    <label className="flex justify-between items-center">
      <p>Custom opening hours</p>
      <ControlledSwitch control={control} disabled={disabled} name={"useSectionSpecificOpeningHours"} />
    </label>
  );
};

export default EnableOpeningHours;
