const RainbowColoredBox = () => {
  const rainbowColors = ["bg-orange", "bg-orange-light", "bg-yellow-light", "bg-green", "bg-purple-light", "bg-pink-light"];

  return (
    <div className="h-4 w-4 overflow-hidden rounded">
      <div className="-rotate-45 -translate-y-[1px] -translate-x-[1px]">
        <>
          {rainbowColors.map((color) => (
            <div key={color} className={`w-[30px] h-[3.61px] ${color}`} />
          ))}
        </>
      </div>
    </div>
  );
};

export default RainbowColoredBox;
