import { useProductLibraryProducts } from "../api/useProductLibraryProducts";

export const useProductLibraryProductById = (productLibraryId?: string) => {
  const { data: productLibraryProducts, isSuccess } = useProductLibraryProducts();

  if (!isSuccess || !productLibraryId || !Number(productLibraryId)) {
    return;
  }

  return productLibraryProducts.find((product) => product.productLibraryId === Number(productLibraryId));
};
