import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedMenu } from "features/menus/hooks/useSelectedMenu";
import { MenuSectionDto } from "types/contracts/contracts";

export const useSections = () => {
  const { get } = useFetch();
  const { selectedMenu } = useSelectedMenu();

  const url = `${config.menuBuilderUrl}/v1/menus/${selectedMenu?.menuId}/sections`;

  const sectionsQuery = useQuery<MenuSectionDto[], Error>(["sections", selectedMenu?.menuId], () => get(url), {
    enabled: !!selectedMenu?.menuId,
  });

  return sectionsQuery;
};
