import config from "config";
import { useFetch } from "api/useFetch";
import { useQuery } from "@tanstack/react-query";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { CourseDto } from "types/contracts/contracts";

export const useCourses = () => {
  const { get } = useFetch();
  const { selectedLocation } = useSelectedLocation();

  const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/courses`;

  const coursesQuery = useQuery<CourseDto[], Error>(["courses", selectedLocation?.locationId], () => get(url), {
    enabled: !!selectedLocation?.locationId,
  });

  return coursesQuery;
};
