import { Button } from "@ordr-as/umami-react";
import { useState } from "react";
import AddSectionInput from "./AddSectionInput";

const AddExistingSection = () => {
  const [active, setActive] = useState(false);

  if (!active) {
    return (
      <Button kind="secondary" onClick={() => setActive(true)}>
        Add existing section
      </Button>
    );
  }

  return <AddSectionInput />;
};

export default AddExistingSection;
