import { ProductFormType } from "../../../formValidation";
import { useFormContext } from "react-hook-form";
import { FC } from "react";
import { useLookups } from "../../../../lookups/api/useLookups";
import { PriceTypeDto } from "../../../../../types/contracts/contracts";
import { useSelectedLocation } from "../../../../locations/hooks/useSelectedLocation";


type PriceTypeProps = {
  index: number;
  isDisabled: boolean;
};

export const locationsEnabledForTakeawayPricing = [
  368485,     // QA: Puzzles
  388595,     // QA: Wizarding world
  191127618,  // Prod: BIT Bussterminalen
  212485270,  // Prod: BIT Oslo Lufthavn
  203386383,  // Prod: Cyclo spisebar
  27340020,   // Prod: Eirik's demo location
  27271168,   // Prod: Miriam's demo location
  90503094,   // Prod: Even's demo location
  236906548,  // Prod: Design Donut
  228569819,   // Prod: Fisketorget restaurant
  180247081,   // Prod: Fisketorget uteservering

    // Jul i Vinterland locations
  152287480,  // Spekestua (pølser)
  152186222,  // Bekkereinan
  152187266,  // Birk Glas
  152300049,  // NORRD
  241296404,  // Eplemottaket
  152189154,  // Waffle Factory
  152188964,  // Grini Hjemmebakeri
  152189311,  // Canelo Decor
  152189959,  // Amuletten
  154303943,  // Candy Apple LT
  152189461,  // Spenn
  152288659,  // Spekestua (laks)
  152189706,  // Natur Alpakka
  154304056,  // Lille Grill LT
  240152511,  // Gløgghuset
  152190417,  // Ost & Sånt
  152197769,  // Nielgaard Glaskunst
  152197068,  // Crepe De La Crepe
  152197965,  // Viggos kjempegode smultringer
  152190241,  // Wooden corner
  152201828,  // Mylamb
  154303410,  // Mandler LT
  152290116,  // Spekestua (ost)
  152198634,  // Fries Brothers
  152289321,  // Spekestua (pølser)
  153051972,  // Waffle Factory
  152285539,  // Spekestua (ost)
  152290782,  // Spekestua (pølser)
  153441692,  // Bekkereinan
  152202951,  // Opa! Souvlaki and Greece
  152280489,  // Asante Crafts
  152293886,  // Raclette
  152184290,  // Drit Forbanna
  153133998,  // Koselige og varme gaver
  152185632,  // Dutch Poffertjes
  152187500,  // Eplemottaket
  240150681,  // Alwero
  152282903,  // Himalaya Eksport & Import
  240148768,  // Bestefars Vaffler
  153213880,  // Tons of Rock
  241296179,  // Spekestua (parmesan)
  238509631,  // Ullkongen
  238522695,  // Pangolin Forlag
  238522991,  // Lines of Norway
  238523285,  // Goolami
  240129638,  // Teaologi
  240142752,  // Mahar Gemstone & Jewellery
  240144441,  // Magic Twist
  240147434,  // Fresh New Korean Corndogs
  240152511,  // Julenissens Gløgghus
  243210759,  // Rudolfs bar
];

const PriceType: FC<PriceTypeProps> = ({ index, isDisabled }) => {
  const { register, setValue, getValues, formState: { errors } } = useFormContext<ProductFormType>();
  const { data: lookups } = useLookups();
  const { selectedLocation } = useSelectedLocation();

  let priceTypes;
  if (lookups !== undefined) {
    if (selectedLocation !== undefined && locationsEnabledForTakeawayPricing.includes(selectedLocation.locationId)) {
      // Render all choices
      priceTypes = lookups.priceTypes;
    } else {
      // Just render PER_ITEM and OPEN_PRICE
      priceTypes = (lookups.priceTypes.filter((item) => item.priceTypeCategoryId == "GENERAL"))
    }
    if (!getValues(`sizes.${index}.priceType`)) {
      setValue(`sizes.${index}.priceType`, priceTypes[0].priceTypeId);
    }
  }
  const fieldError= errors.sizes?.[index]?.priceType

  return <div className={"flex"}>
      <label>
        <div className="pb-2 umami-form-label">{"Price type"}</div>
        <select
          {...register(`sizes.${index}.priceType`)}
          className={`text-sm border rounded py-[14px] px-[12px] ${fieldError ? "border-orange-darkest" : "border-grey-medium"}`}
          disabled={isDisabled}
        >
          {priceTypes?.map((priceType: PriceTypeDto) => (
            <option key={priceType.priceTypeId} value={priceType.priceTypeId}>{priceType.priceTypeName}</option>
          ))}
        </select>
      </label>
      {fieldError && <p className="text-sm text-orange-darkest">{fieldError.message}</p>}
  </div>;
};

export default PriceType;
