import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { OptionGroupsDto } from "types/contracts/contracts";
import { reorder, updateSequenceOnItems } from "utils/utils";

export const useUpdateCustomizationGroupSequence = () => {
  const { put } = useFetch();
  const { selectedLocation } = useSelectedLocation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (variables: { customizationGroupId: string; sourceIndex: number; destinationIndex: number }) => {
      const { customizationGroupId, destinationIndex } = variables;

      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/customization-groups/${customizationGroupId}/update-sequence/${destinationIndex}`;

      return put(url);
    },
    {
      onMutate: async ({ sourceIndex, destinationIndex }) => {
        await queryClient.cancelQueries(["optionGroups", selectedLocation?.locationId]);

        const previousOptionGroups = queryClient.getQueryData<OptionGroupsDto>(["optionGroups", selectedLocation?.locationId]);

        const reorderedCustomizationGroups = reorder(previousOptionGroups?.customizationGroups || [], sourceIndex, destinationIndex);
        const customizationGroupsWithUpdatedSequence = updateSequenceOnItems(reorderedCustomizationGroups);

        const optionGroupsWithNewRecommendationGroups: OptionGroupsDto = {
          recommendationGroups: previousOptionGroups?.recommendationGroups || [],
          customizationGroups: customizationGroupsWithUpdatedSequence,
        };

        queryClient.setQueryData<OptionGroupsDto>(["optionGroups", selectedLocation?.locationId], () => optionGroupsWithNewRecommendationGroups);

        return { previousOptionGroups };
      },
      onError: (err, variables, context) => {
        if (context?.previousOptionGroups) {
          queryClient.setQueryData<OptionGroupsDto>(["optionGroups", selectedLocation?.locationId], context.previousOptionGroups);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["optionGroups", selectedLocation?.locationId]);
      },
    },
  );

  return mutation;
};
