import { TextInput } from "@ordr-as/umami-react";
import useDebounce from "hooks/useDebounce";
import { useState } from "react";
import ProductLibrarySearchResults from "./ProductLibrarySearchResults";

const ProductLibrarySearch = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  return (
    <div className="flex flex-col w-1/4">
      <TextInput value={searchTerm} onChange={setSearchTerm} label="Search all products" />
      <ProductLibrarySearchResults searchTerm={debouncedSearchTerm} />
    </div>
  );
};

export default ProductLibrarySearch;
