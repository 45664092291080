import { useSelectedProducts } from "../../hooks/useSelectedProducts";
import { BulkProductDto } from "types/contracts/contracts";

const DisplaySelectedProducts = () => {
  const { selectedProducts } = useSelectedProducts();

  return (
    <div>
      <div className="flex text-xs font-semibold py-2">
        <span className="w-1/2">Product</span>
        <span className="w-1/2">Course name</span>
      </div>

      {selectedProducts.map((product) => (
        <DisplaySelectedProductsElement key={product.productId} product={product} />
      ))}
    </div>
  );
};

export default DisplaySelectedProducts;

type Props = { product: BulkProductDto };

const DisplaySelectedProductsElement = (props: Props) => {
  const { product } = props;

  return (
    <div className="flex text-sm border-t border-t-grey-lightest py-2">
      <div className="w-1/2">{product.name}</div>
      <div className="w-1/2">{product.courseName}</div>
    </div>
  );
};
