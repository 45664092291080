import { zodResolver } from "@hookform/resolvers/zod";
import { Dialog } from "@ordr-as/umami-react";
import { useForm } from "react-hook-form";
import { addProductLibraryProductFormSchema, AddProductLibraryProductFormType } from "features/productLibrary/formValidation";
import { useCreateNewProductFromProductLibrary } from "features/productLibrary/api/useCreateNewProductFromProductLibrary";
import { mapToCreateDto } from "features/productLibrary/utils";
import AlreadyAddedToProductList from "./AlreadyAddedToProductList";
import Sizes from "./sizes/Sizes";
import NoPLSizes from "./sizes/NoPLSizes";
import { useNavigateToSupplier } from "features/productLibrary/hooks/useNavigateToSupplier";
import SelectSections from "./SelectSections";
import { ProductLibraryProductDto } from "types/contracts/contracts";

type Props = {
  product: ProductLibraryProductDto;
};

const AddProductLibraryDialogProduct = (props: Props) => {
  const { product } = props;
  const createNewProductFromProductLibraryMutation = useCreateNewProductFromProductLibrary();
  const navigateToSupplier = useNavigateToSupplier();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<AddProductLibraryProductFormType>({
    resolver: zodResolver(addProductLibraryProductFormSchema),
    defaultValues: {
      sizes: [],
      sectionIds: [],
    },
  });

  const onSubmit = (formData: AddProductLibraryProductFormType) => {
    const dto = { product: mapToCreateDto(product, formData), sectionIds: formData.sectionIds };

    createNewProductFromProductLibraryMutation.mutate(dto, {
      onSettled: () => {
        navigateToSupplier();
      },
    });
  };

  return (
    <Dialog
      title={`Add ${product.name} to the menu`}
      actionLabel="Add to section"
      onAction={handleSubmit(onSubmit)}
      onClose={() => navigateToSupplier()}
      isDisabledAction={product.addedToProductList}
    >
      {product.addedToProductList ? (
        <AlreadyAddedToProductList product={product} key={product.productLibraryId} />
      ) : (
        <form className="flex flex-col">
          <SelectSections control={control} errors={errors} />
          {product.sizes.length > 0 ? (
            <Sizes sizes={product.sizes} control={control} register={register} errors={errors} />
          ) : (
            <NoPLSizes register={register} errors={errors} />
          )}
        </form>
      )}
    </Dialog>
  );
};

export default AddProductLibraryDialogProduct;
