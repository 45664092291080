import { Button, TextInput } from "@ordr-as/umami-react";
import { useCreateMenu } from "features/menus/api/useCreateMenu";
import { useState } from "react";

const NewMenuButton = () => {
  const [active, setActive] = useState(false);
  const [input, setInput] = useState("");
  const mutation = useCreateMenu();

  const handleCreateMenu = () => {
    const menu = { name: input };

    mutation.mutate(menu);
    setActive(false);
    setInput("");
  };

  if (!active) {
    return (
      <Button kind="secondary" onClick={() => setActive(true)}>
        Create new menu
      </Button>
    );
  }

  return (
    <div className="flex">
      <TextInput aria-label="Create new menu" placeholder="Name of menu..." value={input} onChange={setInput} className="px-2" />
      <Button kind="secondary" onClick={() => handleCreateMenu()}>
        Create
      </Button>
    </div>
  );
};

export default NewMenuButton;
