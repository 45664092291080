import { CreateSSOProvider } from "@ordr-as/authentication-library";
import config from "./config";
import Routing from "pages/routing/Routing";
import { BrowserRouter } from "react-router-dom";
import BulkProductsProvider from "./features/bulkEditProducts/context/BulkProductsContext";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

import VersionProvider from "features/version/context/VersionContext";
import LastActiveMenuProvider from "features/locations/context/LastActiveMenuContext";
import { reactQueryOnError } from "api/postErrors";
import ScrollToTop from "components/ScrollToTop";
import { ScreenSizeProvider } from "features/screenSize/ScreenSizeContext";

const { provider: SSOProvider } = CreateSSOProvider({
  callback_url: window.location.origin,
  app_name: "menu-builder",
  environment: config.env === "prod" ? config.env : "qa",
  version: config.env === "prod" ? "v2" : "v3",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => reactQueryOnError(error),
  }),
});

const App = () => {
  return (
    <div className="App">
      <ScreenSizeProvider>
        <VersionProvider>
          <SSOProvider>
            <QueryClientProvider client={queryClient}>
              <LastActiveMenuProvider>
                <BulkProductsProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <Routing />
                  </BrowserRouter>
                </BulkProductsProvider>
              </LastActiveMenuProvider>
            </QueryClientProvider>
          </SSOProvider>
        </VersionProvider>
      </ScreenSizeProvider>
    </div>
  );
};

export default App;
