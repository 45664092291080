import NumberInput from "components/NumberInput";
import { ProductFormType } from "features/productForm/formValidation";
import { useFormContext } from "react-hook-form";

type Props = {
  editable: boolean;
};

const ProductLibraryId = (props: Props) => {
  const { editable } = props;
  const { register } = useFormContext<ProductFormType>();

  return (
    <NumberInput
      labelText="Product library ID"
      registerReturn={register("productLibraryId", {
        setValueAs: (value: string) => {
          if (Number(value) === 0) return undefined;
          return Number(value) || undefined;
        },
      })}
      disabled={!editable}
    />
  );
};

export default ProductLibraryId;
