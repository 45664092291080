import { Button, TextInput } from "@ordr-as/umami-react";
import { TrashIcon } from "@ordr-as/icons";
import { useFieldArray, UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { ProductFormType } from "../../../formValidation";

type Props = {
  editable: boolean;
};

const Removables = (props: Props) => {
  const { editable } = props;
  const { fields, append, remove } = useFieldArray<ProductFormType, "removables">({
    name: "removables",
  });

  return (
    <div className="border border-grey-medium rounded p-4 flex-col gap-1">
      <h4>Removable ingredients</h4>
      <p>Are your customers able to remove any ingredients from this product?</p>

      <div className="py-4">
        {fields.length > 0 && (
          <table>
            <thead>
              <tr>
                <th className="px-2">Name</th>
                {editable && <th className="px-2">Delete removable</th>}
              </tr>
            </thead>
            <tbody>
              {fields.map((_, index) => (
                <Removable index={index} key={index} remove={remove} disabled={!editable} />
              ))}
            </tbody>
          </table>
        )}
        {fields.length === 0 && !editable && <>There are no removables.</>}
      </div>

      {editable && (
        <Button
          kind="secondary"
          onClick={() => {
            append({ price: 0, name: "" });
          }}
          isDisabled={!editable}
        >
          Add new removable
        </Button>
      )}
    </div>
  );
};

export default Removables;

type RemovableProps = {
  index: number;
  remove: UseFieldArrayRemove;
  disabled?: boolean;
};

const Removable = (props: RemovableProps) => {
  const { index, remove, disabled } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<ProductFormType>();

  return (
    <tr>
      <td className="px-2">
        <TextInput
          aria-label="Name of removable"
          name={`removables.${index}.name`}
          register={register}
          invalidHelpText={errors.removables?.[index]?.name?.message}
          validationState={errors.removables?.[index]?.name ? "invalid" : undefined}
          isDisabled={disabled}
        />
      </td>
      <td className="px-2 flex">
        {!disabled && (
          <TrashIcon
            className="cursor-pointer h-5 w-5 mt-3"
            onClick={() => {
              remove(index);
            }}
          />
        )}
      </td>
    </tr>
  );
};
