import Select from "components/Select";
import { CustomizationGroupFormType } from "features/options/formValidation/customizationGroupForm";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type SelectionTypeProps = {
  register: UseFormRegister<CustomizationGroupFormType>;
  errors: FieldErrors<CustomizationGroupFormType>;
};

const SelectionType = (props: SelectionTypeProps) => {
  const { register, errors } = props;

  return (
    <Select
      labelText="How many options can the guest select?"
      registerReturn={register("selectionType")}
      fieldError={errors.selectionType}
      widthClassName="w-1/2"
    >
      <option value={"SINGLE_CHOICE"}>Only one option</option>
      <option value={"MULTIPLE_CHOICE"}>Multiple options</option>
      <option value={"QUANTITY"}>Multiple options multiple times</option>
    </Select>
  );
};

export default SelectionType;
