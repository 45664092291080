import { TextInput } from "@ordr-as/umami-react";
import { CreateCustomizationFormType } from "features/options/formValidation/createCustomization";
import { FieldErrors, UseFormRegister } from "react-hook-form";

type NameProps = {
  register: UseFormRegister<CreateCustomizationFormType>;
  errors: FieldErrors<CreateCustomizationFormType>;
  disabled?: boolean;
};

const Name = (props: NameProps) => {
  const { register, errors, disabled } = props;

  return (
    <TextInput
      label="Name"
      name="name"
      register={register}
      invalidHelpText={errors.name?.message}
      validationState={errors.name ? "invalid" : undefined}
      isDisabled={disabled}
    />
  );
};

export default Name;
