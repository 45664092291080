import { useFetch } from "api/useFetch";
import config from "config";
import { useSelectedLocation } from "features/locations/hooks/useSelectedLocation";
import { useSelectedOptionGroup } from "features/options/hooks/useSelectedOptionGroup";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { UpdateCustomizationGroupDto } from "types/contracts/contracts";

export const useUpdateCustomizationGroup = () => {
  const { selectedLocation } = useSelectedLocation();
  const { selectedOptionGroup } = useSelectedOptionGroup();
  const { put } = useFetch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (updatedCustomizationGroup: UpdateCustomizationGroupDto) => {
      const url = `${config.menuBuilderUrl}/v1/locations/${selectedLocation?.locationId}/customization-groups/${selectedOptionGroup?.optionGroupId}`;

      return put(url, updatedCustomizationGroup);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["optionGroups"]);
      },
    },
  );

  return mutation;
};
