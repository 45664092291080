import { useSelectedProducts } from "features/bulkEditProducts/hooks/useSelectedProducts";
import { useUniqueOptionGroups } from "features/bulkEditProducts/hooks/useUniqueOptionGroups";
import { Dispatch, SetStateAction, useMemo } from "react";
import Spinner from "../../../../components/Spinner";
import { Item, Select } from "@ordr-as/umami-react/lib/form";

type SelectRecommendationGroupProps = {
  setSelectedOptionGroupId: Dispatch<SetStateAction<string | undefined>>;
};

const SelectRecommendationGroup = (props: SelectRecommendationGroupProps) => {
  const { setSelectedOptionGroupId } = props;
  const { selectedProducts } = useSelectedProducts();
  const optionGroupsFromSelectedProducts = useMemo(() => selectedProducts.flatMap((product) => product.optionGroups), [selectedProducts]);
  const { uniqueOptionGroups } = useUniqueOptionGroups(optionGroupsFromSelectedProducts);

  if (!optionGroupsFromSelectedProducts) {
    return <Spinner />;
  }

  return (
    <div className="flex gap-4 w-full pb-2">
      <div className="flex max-w-[400px] w-full">
        <Select fullWidth label="Select Group" placeholder="Select..." onSelectionChange={(key) => setSelectedOptionGroupId(String(key))}>
          {uniqueOptionGroups.map((group) => (
            <Item key={group.optionGroupId}>
              {group.optionGroupType} - {group.name}
            </Item>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SelectRecommendationGroup;
