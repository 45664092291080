import { useMemo } from "react";
import { usePrinters } from "../api/usePrinters";
import { printerName } from "../utils/printerName";

export const usePrinterName = (printerId: number | undefined) => {
  const { data: printers } = usePrinters();

  const name = useMemo(() => {
    return printerName(printerId, printers);
  }, [printers, printerId]);

  return name;
};
