import { FieldError, UseFormRegisterReturn } from "react-hook-form";

type NumberInputProps = {
  labelText: string;
  registerReturn: UseFormRegisterReturn;
  disabled?: boolean;
  fieldError?: FieldError;
  widthClassName?: string;
  min?: number;
  noDefault?: boolean;
};

const NumberInput = (props: NumberInputProps) => {
  const { labelText, registerReturn, disabled, fieldError, widthClassName, min, noDefault } = props;

  return (
    <div className={`umami-text-input ${fieldError && "umami-invalid"}`}>
      <label className={widthClassName}>
        <div className="umami-form-label">{labelText}</div>
        <div className="p-1" />
        <input
          aria-label={labelText}
          className={"umami-text-input-field"}
          type="number"
          disabled={disabled}
          {...registerReturn}
          aria-invalid={fieldError ? true : false}
          defaultValue={noDefault ? "" : 0}
          onWheel={(event) => event.currentTarget.blur()}
          onFocus={(event) => {
            event.currentTarget.select();
          }}
          min={min}
        />
      </label>
      {fieldError && <p className="text-sm text-orange-darkest umami-text-input-helper">{fieldError.message}</p>}
    </div>
  );
};

export default NumberInput;
